import React, { useCallback, useState } from "react";
import "./Segmented.css"; // Import the CSS file for styling

interface SegmentedProps {
  options: { key: string; label: string }[];
  defaultKey?: string;
  onChange: ({ key, label }: { key: string; label: string }) => void;
}

const Segmented: React.FC<SegmentedProps> = ({ options, defaultKey, onChange }) => {
  const [value, setValue] = useState(options.find((v) => v.key === defaultKey)?.key || options[0].key);

  const handleChange = useCallback(
    (option: { key: string; label: string }) => {
      setValue(option.key);
      onChange(option);
    },
    [onChange]
  );

  return (
    <div className="segmented-controls">
      {options.map((option, index) => (
        <React.Fragment key={index}>
          <input
            id={`${option.key.toLowerCase()}-${index}`}
            name={option.key.toLowerCase()}
            type="radio"
            checked={index === options.findIndex((v) => v.key === value)}
            onChange={() => handleChange(option)}
          />
          <label htmlFor={`${option.key.toLowerCase()}-${index}`}>{option.label}</label>
        </React.Fragment>
      ))}
      <style>
        {`
          .segmented-controls label:last-of-type::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -2;
            background-color: var(--white);
            transition: inherit;
            
          }
          .segmented-controls input:checked ~ label:last-of-type::after {
            transform: translateX(${-100 * (options.length - 1 - options.findIndex((v) => v.key === value))}%);
          }
        `}
      </style>
    </div>
  );
};

export default Segmented;
