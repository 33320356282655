import { FC, ReactNode } from "react";
import "../page.css";

interface ILayoutPropsContent {
  children: ReactNode;
  cssClass?: string;
}

const Layout: FC<ILayoutPropsContent> = ({ children, cssClass }) => {
  return <div className={`ui_layout ${cssClass ?? ""}`}>{children}</div>;
};

export default Layout;
