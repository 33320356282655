import { FC } from "react";
import { IconCustom } from "./types";

const CheckMark: FC<IconCustom> = ({ color = "green", size = "1rem" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      width={typeof size === "number" ? `${size}px` : size}
      height={typeof size === "number" ? `${size}px` : size}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41L9 16.17z" />
    </svg>
  );
};

export default CheckMark;
