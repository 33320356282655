import React, { memo, useCallback } from "react";
import { ErrorIcon, SuccessIcon } from "../../../components/UI/Icons";
import ReplaceIcon from "../../../components/UI/Icons/ReplaceIcon";
import "./CartComparisonLegend.css";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";

const CartComparisonLegend = () => {
  const {
    user: { language },
  } = useCustomContext();

  const drawLegendIcon = useCallback(() => {
    return Object.values(comparisonIndicators).map((i, idx) => {
      return (
        <div key={idx} className="comparison-item-legend_icon-text">
          <span>{i.icon}</span>
          <span>{i[language]}</span>
        </div>
      );
    });
  }, [language]);

  return <div className="comparison-item-legend_wrapper">{drawLegendIcon()}</div>;
};

export default memo(CartComparisonLegend);

export const comparisonIndicators = {
  perfect: {
    icon: <SuccessIcon size="20px" />,
    english: "Same product",
    russian: "Тот же продукт",
    arabic: "نفس المنتج",
    hebrew: "מוצר זהה לחלוטין",
  },
  similar: {
    icon: <SuccessIcon size="20px" color="blue" />,
    english: "Similar product",
    russian: "Похожий продукт",
    arabic: "منتج مشابه",
    hebrew: "מוצר דומה",
  },
  custom: {
    icon: <ReplaceIcon size="20px" color="orange" />,
    english: "Custom matching",
    russian: "Индивидуальный подбор",
    arabic: "مطابقة مخصصة",
    hebrew: "מוצר שהתאמתי",
  },
  notFound: {
    icon: <ErrorIcon size="20px" />,
    english: "We couldn't match it",
    russian: "Мы не смогли подобрать",
    arabic: "لم نستطع مطابقته",
    hebrew: "לא מצאנו התאמה - נסו אתם",
  },
};
