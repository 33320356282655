// Dropdown.tsx
import React, { ReactNode } from "react";
import "./Dropdown.css";

interface DropdownProps {
  items: string[] | ReactNode[];
  title: string | ReactNode;
  variant?: "primary" | "secondary";
  border?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({ items, title, variant = "primary", border = false }) => {
  return (
    <div className={`dropdown`}>
      <div className={`dropdown-toggle ${variant} ${border ? " ui-border" : ""}`}>{title}</div>

      {items.length !== 0 && (
        <ul className={`dropdown-menu ${variant}`}>
          {items.map((item, index) => {
            if (!item) return null;
            return (
              <li key={index} className={`dropdown-item ${variant}`}>
                {item}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
