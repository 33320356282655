import "./HowItsWork.css";
import { Typography } from "../../../components/UI";
import { howItsWorkData, howItWorkDict } from "./data";
import waveImage from "../../../assets/images/line_wave.png";
import AnimatedComponent from "../../../components/AnimatedComponent/AnimatedComponent";

const language = "hebrew";

const HowItsWork = () => {
  const drawCard = () => {
    return howItsWorkData.map((i, idx) => {
      const Icon = i.icon;
      return (
        <AnimatedComponent
          key={idx}
          animationClass={`hiw-card-wrapper animation-fade-in animation-fade-in-translateY staggered-animation staggered-animation-${
            idx + 1
          }`}
        >
          <div className="hiw-icon-wrapper">
            <Icon size="100px" hovered color="#5edf07" />
          </div>
          <p>{i.text[language]}</p>
        </AnimatedComponent>
      );
    });
  };
  return (
    <div id="hiw-container" className="hp-card">
      <Typography.Title useAnimationOnScroll="animation-fade-in-translateY animation-fade-in">
        {howItWorkDict.title[language]}
      </Typography.Title>
      <div id="hiw-cards-wrapper">
        <img src={waveImage} alt="wave-absolute" className="hiw-wave-img responsive-hidden" />
        {drawCard()}
      </div>
    </div>
  );
};

export default HowItsWork;
