import { CSSProperties, ReactNode, FC } from "react";
import "./Button.css";

import SpinnerA from "../Loaders/spinnerA/SpinnerA";
import { variantType } from "../Modal/Modal";

type ButtonProps = {
  children: ReactNode;
  onClick?: () => void;
  size?: "small" | "medium" | "large";
  color?: string;
  backgroundColor?: string;
  cssClass?: string;
  disabled?: boolean;
  width?: string | number;
  height?: string | number;
  style?: CSSProperties;
  variant?: variantType;
  type?: "primary" | "default" | "dashed" | "contrast";
  htmlType?: "button" | "reset" | "submit";
  loading?: boolean;
};

const Button: FC<ButtonProps> = ({
  children,
  onClick,
  variant = "primary",
  size = "medium",
  color,
  backgroundColor,
  cssClass,
  disabled = false,
  width,
  height,
  style,
  type = "primary",
  htmlType,
  loading = false,
}) => {
  const style2: CSSProperties = {
    backgroundColor: backgroundColor || undefined,
    color: color || undefined,
    width: width || undefined,
    height: height || undefined,
  };

  return (
    <button
      className={`${cssClass} button btn-${variant} btn-${type} ${size}`}
      onClick={onClick}
      style={{ ...style2, ...style }}
      disabled={disabled || loading}
      type={htmlType}
    >
      {loading ? <SpinnerA size={25} /> : children}
    </button>
  );
};

export default Button;
