import { FC, ReactNode, CSSProperties } from "react";
import "../page.css";

interface IContentPropsContent {
  children: ReactNode;
  style?: CSSProperties;
}

const Content: FC<IContentPropsContent> = ({ children, style }) => {
  return (
    <main className={"ui_main"} style={style}>
      {children}
    </main>
  );
};

export default Content;
