import { Dispatch, FC, SetStateAction } from "react";
import { FrontTable, StoreEn } from "../../../types/types";
import "./SelectedItemImages.css";
import { Carousel, ImageComponent } from "../../UI";

type Props = {
  front: { [store in StoreEn]: FrontTable };
  stores: StoreEn[];
  siteVersion?: 1 | 2;
  setCurrentStore: Dispatch<SetStateAction<StoreEn>>;
  currentStore: StoreEn;
};

const SelectedItemImages: FC<Props> = ({ front, stores, setCurrentStore, siteVersion, currentStore }) => {
  const drawImages = () => {
    if (siteVersion === 2) {
      const src = front[currentStore]?.img_url || require("../../../assets/images/Image_not_available.png");
      return <ImageComponent width={"100%"} src={src} alt={front[stores[0]]?.item_name_front} />;
    }

    return (
      <Carousel
        onSliding={(slide) => {
          setCurrentStore(stores[slide]);
        }}
      >
        {stores.map((s) => {
          return <ImageComponent key={s} src={front[s]?.img_url} alt={front[s]?.item_name_front} />;
        })}
      </Carousel>
    );
  };

  return <div className="selected-zoom-img">{drawImages()}</div>;
};

export default SelectedItemImages;
