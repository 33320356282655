import { FC, useMemo } from "react";
import "./MobileDepartmentsNav.css";
import CategoriesMap from "../../assets/Data/categories";
import { IconCustom } from "../../components/UI/Icons/types";
import DepartmentsIcons from "../../components/UI/Icons/DepartmentsIcons/DepartmentsIcons";
import { Link } from "react-router-dom";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import { Accordion, AccordionItem } from "../../components/UI";

const MobileDepartmentsNav = () => {
  const {
    user: { language },
  } = useCustomContext();

  const generateItems = useMemo(() => {
    const categoriesKeys = Object.keys(CategoriesMap);
    const items: AccordionItem[] = [];

    categoriesKeys.forEach((cat) => {
      const categoryObj = CategoriesMap[cat];
      const title = categoryObj.category[language];
      const Icon: FC<IconCustom> = DepartmentsIcons[cat as keyof typeof DepartmentsIcons];

      const label = (
        <div style={{ display: "flex", gap: "25px" }}>
          <span>
            <Icon hovered />
          </span>
          <span>{title}</span>
        </div>
      );

      const children = categoryObj.subCategories.map((sub) => {
        return (
          <Link
            style={{
              width: "100%",
              padding: "5px",
            }}
            to={`/products/${sub.key}`}
          >
            {sub[language]}
          </Link>
        );
      });

      items.push({ key: cat, label, children });
    });

    return items;
  }, [language]);

  return (
    <div className="mobile-departments-container">
      <Accordion items={generateItems} />
    </div>
  );
};

export default MobileDepartmentsNav;
