import { FC } from "react";
import { Cart, OnlineStoresType, User } from "../../../types/types";
import CheckoutStoreCart from "./checkoutStoreCart/CheckoutStoreCart";
import "./CheckoutStoresData.css";

type Props = {
  user: User;
  cart: Cart;
  onlineStores: OnlineStoresType;
};
const CheckoutStoresData: FC<Props> = ({ cart, user, onlineStores }) => {
  // if (1 < 2) return <div>waiting</div>;
  return (
    // <div className="scrollable checkoutStoresData-container">
    <div className="checkoutStoresData-container">
      {[onlineStores.favoriteStore, ...onlineStores.otherStores].map((s) => (
        <CheckoutStoreCart
          key={s}
          cart={cart}
          // store={s}
          store={"victory"}
          language={user.language}
          stores={[onlineStores.favoriteStore, ...onlineStores.otherStores]}
          hasClubCard={onlineStores.hasClubCard}
        />
      ))}
    </div>
  );
};

export default CheckoutStoresData;
