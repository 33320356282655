const dict = {
  otherStores: {
    hebrew: "שאר החנויות",
    english: "The other stores",
    arabic: "المتاجر الأخرى",
    russian: "Остальные магазины",
  },
  info: {
    hebrew: "אנא ודאו כי הפריטים שבחרתם תואמים את הפריטים בשאר החנויות, כשתסיימו תוכלו לעבור לשלב סיכום העגלות",
    english:
      "Please make sure that the items you selected match the items in the other stores. Once you finish, you can proceed to the cart summary stage.",
    arabic:
      "يرجى التأكد من أن العناصر التي اخترتها تطابق العناصر في المتاجر الأخرى. بمجرد الانتهاء، يمكنك الانتقال إلى مرحلة ملخص العربة.",
    russian:
      "Пожалуйста, убедитесь, что выбранные вами товары соответствуют товарам в других магазинах. После завершения вы можете перейти к этапу суммирования корзины.",
  },
};

export default dict;
