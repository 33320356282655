import { FC, Dispatch, SetStateAction } from "react";
import GlobousIcon from "../../../../UI/Icons/GlobousIcon";
import { User } from "../../../../../types/types";
import Dropdown from "../../../../UI/Dropdown/Dropdown";
import { SiteNavOptionsTitle } from "../SiteNavOptions";

const langMap = {
  english: "English",
  hebrew: "עברית",
  russian: "Русский",
  arabic: "العربية",
};

type Props = {
  setUser: Dispatch<SetStateAction<User>>;
  language: keyof typeof langMap;
};

const LanguageSelect: FC<Props> = ({ language, setUser }) => {
  const items = [
    <div onClick={() => setUser((u) => ({ ...u, language: "hebrew" }))}>{langMap.hebrew}</div>,
    <div onClick={() => setUser((u) => ({ ...u, language: "english" }))}>{langMap.english}</div>,
    <div onClick={() => setUser((u) => ({ ...u, language: "arabic" }))}>{langMap.arabic}</div>,
    <div onClick={() => setUser((u) => ({ ...u, language: "russian" }))}>{langMap.russian}</div>,
  ];
  return <Dropdown items={items} title={<SiteNavOptionsTitle Icon={GlobousIcon} label={langMap[language]} />} />;
};

export default LanguageSelect;
