import { useEffect, useMemo, useState } from "react";
import "./SearchProduct.css";
import { ImageComponent, SelectSearch } from "../../../UI";
import serverAPI from "../../../../api/server";
import { ProductDataType, StoreEn } from "../../../../types/types";
import Promotion from "../../../ProductCard/Promotions/Promotion";
import SelectedItem from "../../../SelectedItem/SelectedItem";
import { PromoIcon } from "../../../UI/Icons";
import { sortStr } from "../../../../utils/utils";
import FilterCategorySearch from "./FilterCategorySearch/FilterCategorySearch";
import { useLocation } from "react-router-dom";
import useCustomContext from "../../../../hooks/useCustomContext/useCustomContext";
import OnlineStoresMapper from "../../../../hooks/useOnlineStores";
import PriceCalculator from "../../../../models/ItemPriceCalculator/PriceCalculator";

//#region dictionary
const dictionary = {
  searchPlaceHolder: {
    hebrew: "חיפוש מוצר בכל הקטגוריות",
    english: "Search Product In All Categories",
    arabic: "البحث عن المنتج في جميع الفئات",
    russian: "Поиск товара во всех категориях",
  },
  searchPlaceHolderWithFilter: {
    hebrew: "חיפוש מוצר בקטגוריית -",
    english: "Search Product In ",
    arabic: "البحث عن المنتج في",
    russian: "Поиск товара в",
  },
};
//#endregion dictionary

const SearchProduct = () => {
  const [resetSearch, setResetSearch] = useState(false);
  const [isOpenSelected, setIsOpenSelected] = useState(false);
  const [selectedData, setSelectedData] = useState<ProductDataType | null>(null);
  const {
    user: { language },
    onlineStores: { favoriteStore, otherStores },
    siteVersion,
  } = useCustomContext();
  const location = useLocation();
  const [category, setCategory] = useState<{ key: string; name: string } | null>(null);
  const [stores, setStores] = useState<StoreEn[]>([]);

  useEffect(() => {
    setStores(siteVersion === 1 ? [favoriteStore, ...otherStores] : [favoriteStore]);
  }, [siteVersion, favoriteStore, otherStores]);

  const handleSelected = (o: ProductDataType) => {
    setSelectedData(o);

    setIsOpenSelected(true);
  };

  // Version 1
  const getDebounceData_v1 = useMemo(() => {
    if (siteVersion === 2 && location.pathname !== "/cart/compare") return;
    const body = {
      favoriteStore: OnlineStoresMapper.convertOnlineStoreToObj(favoriteStore),
      otherStores: OnlineStoresMapper.convertOnlineStoresToArrOfObj(otherStores),
      ilikeValue: "",
      // similarityValue: "",
      category: category?.key || null,
      isWeighted: false,
      joinByItemCode: true,
      similarityScore: 0.3,
      joinBySimilarity: true,
    };

    return {
      body: body,
      searchKey: "ilikeValue",
      getOptionsFromApi: async () => {
        const res: { data: ProductDataType[] } = await serverAPI("products/common/get", body);
        return res.data
          .sort((a, b) => sortStr(a[favoriteStore].category, b[favoriteStore].category))
          .map((i: any) => {
            return { value: i[favoriteStore].item_code, label: i[favoriteStore].item_name, data: i };
          });
      },
    };
  }, [favoriteStore, otherStores, category?.key, siteVersion, location.pathname]);

  // version 2 default
  const getDebounceData_v2 = useMemo(() => {
    if (siteVersion === 1) return;
    const body = {
      store: OnlineStoresMapper.convertOnlineStoreToObj(favoriteStore),
      category: category?.key || null,
      mCode: 2, //1 mean regex , 2 similarity
      searchValues: "",
    };

    return {
      body: body,
      searchKey: "searchValues",
      getOptionsFromApi: async () => {
        const res: { data: ProductDataType[] } = await serverAPI("products/common/v2/get", body);
        return res.data
          .sort((a, b) => sortStr(a[favoriteStore].category, b[favoriteStore].category))
          .map((i: any) => {
            return { value: i[favoriteStore].item_code, label: i[favoriteStore].item_name, data: i };
          });
      },
    };
  }, [favoriteStore, category?.key, siteVersion]);

  const drawSelectSearchLabel = (option: { value: string; label: string; data: ProductDataType }) => {
    const imgUrl = option.data[favoriteStore].img_url;

    const price =
      siteVersion === 1
        ? PriceCalculator.getNormalPriceRange(stores, option.data)
        : option.data[favoriteStore].item_price;
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(190,200,210,0.5)",
          width: "100%",
        }}
      >
        <span>
          <ImageComponent src={imgUrl} alt={option.value} width={40} height={40} />
        </span>
        <span style={{ flex: 1 }}>&nbsp; {option.label}</span>
        <span style={{ flex: 1 }}>
          <Promotion
            language={language}
            productData={option.data}
            allStores={stores}
            type="search"
            title={<PromoIcon color="var(--primary-contrast-color)" />}
            promoPrice={false}
            endDate={false}
            alwaysShowData
            promoIconColor="var(--primary-contrast-color)"
          />
        </span>

        <span className="main-search-price-range2">
          &nbsp; {price} <span>&#8362;</span>
        </span>
      </div>
    );
  };

  const handleOnBlur = () => {
    setResetSearch(true);
  };

  const handleFocus = (e: any) => {
    e.preventDefault();
    setResetSearch(false);
  };

  const filters = {
    component: <FilterCategorySearch setCategory={setCategory} category={category} />,
    handleClose: () => setCategory(null),
  };

  return (
    <div
      className="search_product_wrapper"
      tabIndex={0}
      onBlur={handleOnBlur}
      onFocus={handleFocus}
      style={{ position: "relative" }}
    >
      <SelectSearch
        handleSelected={handleSelected}
        placeholder={
          category
            ? dictionary.searchPlaceHolderWithFilter[language] + category?.name
            : dictionary.searchPlaceHolder[language]
        }
        voice={language}
        getDebounceData={
          siteVersion === 1 || location.pathname === "/cart/compare" ? getDebounceData_v1 : getDebounceData_v2
        }
        resetProps={[resetSearch, siteVersion]}
        Label={drawSelectSearchLabel}
        filters={filters}
      />

      {selectedData && (
        <SelectedItem
          isOpen={isOpenSelected}
          setIsOpen={setIsOpenSelected}
          selectedProductProp={selectedData}
          stores={stores}
          language={language}
          selectedStore={favoriteStore}
        />
      )}
    </div>
  );
};

export default SearchProduct;
