// update store location script in script files
// microservice : /Desktop/SuperimProject/senstiveData/microservices/updateLocations
const storesLocation = [
  {
    location: "אבו ג'ווייעד (שבט) ",
    stores: [],
  },
  {
    location: "אבו גוש ",
    stores: [],
  },
  {
    location: "אבו סנאן ",
    stores: [],
  },
  {
    location: "אבו סריחאן (שבט) ",
    stores: [],
  },
  {
    location: "אבו עבדון (שבט) ",
    stores: [],
  },
  {
    location: "אבו עמאר (שבט) ",
    stores: [],
  },
  {
    location: "אבו עמרה (שבט) ",
    stores: [],
  },
  {
    location: "אבו קורינאת (שבט) ",
    stores: [],
  },
  {
    location: "אבו קרינאת (יישוב) ",
    stores: [],
  },
  {
    location: "אבו רובייעה (שבט) ",
    stores: [],
  },
  {
    location: "אבו רוקייק (שבט) ",
    stores: [],
  },
  {
    location: "אבו תלול ",
    stores: [],
  },
  {
    location: "אבטין ",
    stores: [],
  },
  {
    location: "אבטליון ",
    stores: [],
  },
  {
    location: "אביאל ",
    stores: ["mahsane"],
  },
  {
    location: "אביבים ",
    stores: [],
  },
  {
    location: "אביגדור ",
    stores: [],
  },
  {
    location: "אביחיל ",
    stores: [],
  },
  {
    location: "אביטל ",
    stores: ["victory"],
  },
  {
    location: "אביעזר ",
    stores: [],
  },
  {
    location: "אבירים ",
    stores: [],
  },
  {
    location: "אבן יהודה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "אבן מנחם ",
    stores: [],
  },
  {
    location: "אבן ספיר ",
    stores: ["bitan"],
  },
  {
    location: "אבן שמואל ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "אבני איתן ",
    stores: [],
  },
  {
    location: "אבני חפץ ",
    stores: [],
  },
  {
    location: "אבנת ",
    stores: [],
  },
  {
    location: "אבשלום ",
    stores: [],
  },
  {
    location: "אדורה ",
    stores: [],
  },
  {
    location: "אדירים ",
    stores: ["victory"],
  },
  {
    location: "אדמית ",
    stores: [],
  },
  {
    location: "אדרת ",
    stores: [],
  },
  {
    location: "אודים ",
    stores: ["bitan", "mega"],
  },
  {
    location: "אודם ",
    stores: [],
  },
  {
    location: "אוהד ",
    stores: ["victory"],
  },
  {
    location: "אום אל-פחם ",
    stores: [],
  },
  {
    location: "אום אל-קוטוף ",
    stores: [],
  },
  {
    location: "אום בטין ",
    stores: [],
  },
  {
    location: "אומן ",
    stores: ["victory"],
  },
  {
    location: "אומץ ",
    stores: [],
  },
  {
    location: "אופקים ",
    stores: ["victory", "mahsane", "shufersal"],
  },
  {
    location: "אור הגנוז ",
    stores: [],
  },
  {
    location: "אור הנר ",
    stores: ["bitan"],
  },
  {
    location: "אור יהודה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "אור עקיבא ",
    stores: ["victory", "bitan", "shufersal"],
  },
  {
    location: "אורה ",
    stores: ["bitan"],
  },
  {
    location: "אורות ",
    stores: ["victory"],
  },
  {
    location: "אורטל ",
    stores: [],
  },
  {
    location: "אורים ",
    stores: ["victory"],
  },
  {
    location: "אורנים ",
    stores: ["victory", "bitan"],
  },
  {
    location: "אורנית ",
    stores: ["victory"],
  },
  {
    location: "אושה ",
    stores: ["mahsane"],
  },
  {
    location: "אזור ",
    stores: ["victory", "bitan"],
  },
  {
    location: "אחווה ",
    stores: [],
  },
  {
    location: "אחוזם ",
    stores: ["mahsane"],
  },
  {
    location: "אחוזת ברק ",
    stores: ["victory"],
  },
  {
    location: "אחיהוד ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "אחיטוב ",
    stores: [],
  },
  {
    location: "אחיסמך ",
    stores: ["bitan", "mega"],
  },
  {
    location: "אחיעזר ",
    stores: ["victory"],
  },
  {
    location: "אטרש (שבט) ",
    stores: [],
  },
  {
    location: "איבים ",
    stores: ["victory"],
  },
  {
    location: "אייל ",
    stores: [],
  },
  {
    location: "איילת השחר ",
    stores: [],
  },
  {
    location: "אילון ",
    stores: ["bitan"],
  },
  {
    location: "אילות ",
    stores: ["bitan"],
  },
  {
    location: "אילניה ",
    stores: [],
  },
  {
    location: "אילת ",
    stores: ["bitan", "mahsane", "shufersal"],
  },
  {
    location: "אירוס ",
    stores: [],
  },
  {
    location: "איתמר ",
    stores: [],
  },
  {
    location: "איתן ",
    stores: ["mahsane"],
  },
  {
    location: "איתנים ",
    stores: [],
  },
  {
    location: "אכסאל ",
    stores: [],
  },
  {
    location: "אל-עזי ",
    stores: [],
  },
  {
    location: "אל-עריאן ",
    stores: [],
  },
  {
    location: "אל-רום ",
    stores: [],
  },
  {
    location: "אל סייד ",
    stores: [],
  },
  {
    location: "אלומה ",
    stores: ["mahsane"],
  },
  {
    location: "אלומות ",
    stores: [],
  },
  {
    location: "אלון הגליל ",
    stores: [],
  },
  {
    location: "אלון מורה ",
    stores: [],
  },
  {
    location: "אלון שבות ",
    stores: ["mahsane"],
  },
  {
    location: "אלוני אבא ",
    stores: [],
  },
  {
    location: "אלוני הבשן ",
    stores: [],
  },
  {
    location: "אלוני יצחק ",
    stores: [],
  },
  {
    location: "אלונים ",
    stores: ["victory"],
  },
  {
    location: "אלי-עד ",
    stores: [],
  },
  {
    location: "אליאב ",
    stores: ["mahsane"],
  },
  {
    location: "אליכין ",
    stores: [],
  },
  {
    location: "אליפז ",
    stores: [],
  },
  {
    location: "אליפלט ",
    stores: [],
  },
  {
    location: "אליקים ",
    stores: [],
  },
  {
    location: "אלישיב ",
    stores: [],
  },
  {
    location: "אלישמע ",
    stores: ["bitan"],
  },
  {
    location: "אלמגור ",
    stores: [],
  },
  {
    location: "אלמוג ",
    stores: [],
  },
  {
    location: "אלעד ",
    stores: ["shufersal"],
  },
  {
    location: "אלעזר ",
    stores: ["mahsane"],
  },
  {
    location: "אלפי מנשה ",
    stores: ["bitan", "shufersal"],
  },
  {
    location: "אלקוש ",
    stores: [],
  },
  {
    location: "אלקנה ",
    stores: ["victory"],
  },
  {
    location: "אמונים ",
    stores: ["victory"],
  },
  {
    location: "אמירים ",
    stores: [],
  },
  {
    location: "אמנון ",
    stores: [],
  },
  {
    location: "אמציה ",
    stores: ["mahsane"],
  },
  {
    location: "אניעם ",
    stores: ["bitan"],
  },
  {
    location: "אסד (שבט) ",
    stores: [],
  },
  {
    location: "אספר ",
    stores: [],
  },
  {
    location: "אעבלין ",
    stores: [],
  },
  {
    location: "אעצם (שבט) ",
    stores: [],
  },
  {
    location: "אפיניש (שבט) ",
    stores: [],
  },
  {
    location: "אפיק ",
    stores: [],
  },
  {
    location: "אפיקים ",
    stores: [],
  },
  {
    location: "אפק ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "אפרת ",
    stores: ["mahsane"],
  },
  {
    location: "ארבל ",
    stores: [],
  },
  {
    location: "ארגמן ",
    stores: [],
  },
  {
    location: "ארז ",
    stores: [],
  },
  {
    location: "אריאל ",
    stores: ["mega", "shufersal"],
  },
  {
    location: "ארסוף ",
    stores: [],
  },
  {
    location: "אשבול ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "אשבל ",
    stores: [],
  },
  {
    location: "אשדוד ",
    stores: ["victory", "bitan", "mahsane", "shufersal"],
  },
  {
    location: "אשדות יעקב (איחוד) ",
    stores: [],
  },
  {
    location: "אשדות יעקב (מאוחד) ",
    stores: [],
  },
  {
    location: "אשחר ",
    stores: ["mahsane"],
  },
  {
    location: "אשכולות ",
    stores: ["mahsane"],
  },
  {
    location: "אשל הנשיא ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "אשלים ",
    stores: [],
  },
  {
    location: "אשקלון ",
    stores: ["victory", "bitan", "mega", "shufersal", "rami"],
  },
  {
    location: "אשרת ",
    stores: [],
  },
  {
    location: "אשתאול ",
    stores: [],
  },
  {
    location: "אתגר ",
    stores: [],
  },
  {
    location: "באקה אל-גרביה ",
    stores: [],
  },
  {
    location: "באר אורה ",
    stores: [],
  },
  {
    location: "באר גנים ",
    stores: [],
  },
  {
    location: "באר טוביה ",
    stores: ["victory"],
  },
  {
    location: "באר יעקב ",
    stores: ["victory", "bitan"],
  },
  {
    location: "באר מילכה ",
    stores: [],
  },
  {
    location: "באר שבע ",
    stores: ["victory", "bitan", "mega", "mahsane", "shufersal"],
  },
  {
    location: "בארות יצחק ",
    stores: ["bitan", "mega"],
  },
  {
    location: "בארותיים ",
    stores: [],
  },
  {
    location: "בארי ",
    stores: ["victory"],
  },
  {
    location: "בוסתן הגליל ",
    stores: [],
  },
  {
    location: "בועיינה-נוג'ידאת ",
    stores: [],
  },
  {
    location: "בוקעאתא ",
    stores: [],
  },
  {
    location: "בורגתה ",
    stores: [],
  },
  {
    location: "בחן ",
    stores: [],
  },
  {
    location: "בטחה ",
    stores: ["victory"],
  },
  {
    location: "ביצרון ",
    stores: ["victory"],
  },
  {
    location: "ביר אל-מכסור ",
    stores: [],
  },
  {
    location: "ביר הדאג' ",
    stores: [],
  },
  {
    location: "ביריה ",
    stores: [],
  },
  {
    location: "בית אורן ",
    stores: [],
  },
  {
    location: "בית אל ",
    stores: [],
  },
  {
    location: "בית אלעזרי ",
    stores: ["mega"],
  },
  {
    location: "בית אלפא ",
    stores: [],
  },
  {
    location: "בית אריה ",
    stores: ["shufersal"],
  },
  {
    location: "בית ברל ",
    stores: [],
  },
  {
    location: "בית ג'ן ",
    stores: [],
  },
  {
    location: "בית גוברין ",
    stores: [],
  },
  {
    location: "בית גמליאל ",
    stores: [],
  },
  {
    location: "בית דגן ",
    stores: ["victory", "bitan", "shufersal"],
  },
  {
    location: "בית הגדי ",
    stores: ["victory"],
  },
  {
    location: "בית הלוי ",
    stores: [],
  },
  {
    location: "בית הלל ",
    stores: [],
  },
  {
    location: "בית העמק ",
    stores: [],
  },
  {
    location: "בית הערבה ",
    stores: [],
  },
  {
    location: "בית השיטה ",
    stores: [],
  },
  {
    location: "בית זיד ",
    stores: ["bitan"],
  },
  {
    location: "בית זית ",
    stores: ["bitan"],
  },
  {
    location: "בית זרע ",
    stores: [],
  },
  {
    location: "בית חורון ",
    stores: [],
  },
  {
    location: "בית חירות ",
    stores: [],
  },
  {
    location: "בית חלקיה ",
    stores: [],
  },
  {
    location: "בית חנן ",
    stores: [],
  },
  {
    location: "בית חנניה ",
    stores: [],
  },
  {
    location: "בית חשמונאי ",
    stores: [],
  },
  {
    location: "בית יהושע ",
    stores: [],
  },
  {
    location: "בית יוסף ",
    stores: [],
  },
  {
    location: "בית ינאי ",
    stores: [],
  },
  {
    location: "בית יצחק-שער חפר ",
    stores: ["shufersal"],
  },
  {
    location: "בית לחם הגלילית ",
    stores: [],
  },
  {
    location: "בית מאיר ",
    stores: [],
  },
  {
    location: "בית נחמיה ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "בית ניר ",
    stores: [],
  },
  {
    location: "בית נקופה ",
    stores: [],
  },
  {
    location: "בית עובד ",
    stores: ["victory"],
  },
  {
    location: "בית עוזיאל ",
    stores: [],
  },
  {
    location: "בית עזרא ",
    stores: ["victory"],
  },
  {
    location: "בית עריף ",
    stores: ["bitan", "mega"],
  },
  {
    location: "בית צבי ",
    stores: [],
  },
  {
    location: "בית קמה ",
    stores: ["mahsane"],
  },
  {
    location: "בית קשת ",
    stores: [],
  },
  {
    location: "בית רבן ",
    stores: [],
  },
  {
    location: "בית רימון ",
    stores: [],
  },
  {
    location: "בית שאן ",
    stores: ["victory", "shufersal"],
  },
  {
    location: "בית שמש ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "בית שערים ",
    stores: [],
  },
  {
    location: "בית שקמה ",
    stores: ["mahsane"],
  },
  {
    location: "ביתן אהרן ",
    stores: [],
  },
  {
    location: "ביתר עילית ",
    stores: ["mega"],
  },
  {
    location: "בלפוריה ",
    stores: ["victory"],
  },
  {
    location: "בן זכאי ",
    stores: ["victory"],
  },
  {
    location: "בן עמי ",
    stores: [],
  },
  {
    location: "בן שמן (כפר נוער) ",
    stores: ["bitan", "mega"],
  },
  {
    location: "בן שמן (מושב) ",
    stores: [],
  },
  {
    location: "בני ברק ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "בני דקלים ",
    stores: ["mahsane"],
  },
  {
    location: "בני דרום ",
    stores: ["victory"],
  },
  {
    location: "בני דרור ",
    stores: ["victory"],
  },
  {
    location: "בני יהודה ",
    stores: [],
  },
  {
    location: "בני נצרים ",
    stores: [],
  },
  {
    location: "בני עטרות ",
    stores: ["bitan", "mega"],
  },
  {
    location: "בני עיש ",
    stores: ["victory"],
  },
  {
    location: "בני ציון ",
    stores: [],
  },
  {
    location: "בני ראם ",
    stores: ["victory"],
  },
  {
    location: "בניה ",
    stores: [],
  },
  {
    location: "בנימינה-גבעת עדה ",
    stores: [],
  },
  {
    location: "בסמה ",
    stores: [],
  },
  {
    location: "בסמת טבעון ",
    stores: [],
  },
  {
    location: "בענה ",
    stores: [],
  },
  {
    location: "בצרה ",
    stores: [],
  },
  {
    location: "בצת ",
    stores: [],
  },
  {
    location: "בקוע ",
    stores: [],
  },
  {
    location: "בקעות ",
    stores: [],
  },
  {
    location: "בר גיורא ",
    stores: ["mega"],
  },
  {
    location: "בר יוחאי ",
    stores: [],
  },
  {
    location: "ברוכין ",
    stores: [],
  },
  {
    location: "ברור חיל ",
    stores: [],
  },
  {
    location: "ברוש ",
    stores: ["victory"],
  },
  {
    location: "ברכה ",
    stores: [],
  },
  {
    location: "ברכיה ",
    stores: [],
  },
  {
    location: "ברעם ",
    stores: [],
  },
  {
    location: "ברק ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "ברקאי ",
    stores: [],
  },
  {
    location: "ברקן ",
    stores: ["victory"],
  },
  {
    location: "ברקת ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "בת הדר ",
    stores: [],
  },
  {
    location: "בת חן ",
    stores: [],
  },
  {
    location: "בת חפר ",
    stores: [],
  },
  {
    location: "בת ים ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "בת עין ",
    stores: [],
  },
  {
    location: "בת שלמה ",
    stores: ["bitan", "mega"],
  },
  {
    location: "ג'דיידה-מכר ",
    stores: [],
  },
  {
    location: "ג'ולס ",
    stores: [],
  },
  {
    location: "ג'לג'וליה ",
    stores: ["shufersal"],
  },
  {
    location: "ג'נאביב (שבט) ",
    stores: [],
  },
  {
    location: "ג'סר א-זרקא ",
    stores: [],
  },
  {
    location: "ג'ש (גוש חלב) ",
    stores: [],
  },
  {
    location: "ג'ת ",
    stores: [],
  },
  {
    location: "גאולי תימן ",
    stores: [],
  },
  {
    location: "גאולים ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גאליה ",
    stores: ["victory"],
  },
  {
    location: "גבולות ",
    stores: [],
  },
  {
    location: "גבים ",
    stores: ["victory"],
  },
  {
    location: "גבע ",
    stores: [],
  },
  {
    location: "גבע בנימין ",
    stores: [],
  },
  {
    location: "גבע כרמל ",
    stores: [],
  },
  {
    location: "גבעולים ",
    stores: [],
  },
  {
    location: "גבעון החדשה ",
    stores: [],
  },
  {
    location: "גבעות בר ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "גבעות עדן ",
    stores: [],
  },
  {
    location: "גבעת אבני ",
    stores: [],
  },
  {
    location: "גבעת אלה ",
    stores: [],
  },
  {
    location: "גבעת ברנר ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גבעת השלושה ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גבעת זאב ",
    stores: ["shufersal"],
  },
  {
    location: "גבעת חיים (איחוד) ",
    stores: [],
  },
  {
    location: "גבעת חיים (מאוחד) ",
    stores: [],
  },
  {
    location: "גבעת חן ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גבעת יואב ",
    stores: ["victory"],
  },
  {
    location: "גבעת יערים ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "גבעת ישעיהו ",
    stores: [],
  },
  {
    location: "גבעת כח ",
    stores: [],
  },
  {
    location: "גבעת נילי ",
    stores: [],
  },
  {
    location: "גבעת עוז ",
    stores: [],
  },
  {
    location: "גבעת שמואל ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "גבעת שמש ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "גבעת שפירא ",
    stores: ["bitan"],
  },
  {
    location: "גבעתי ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "גבעתיים ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "גברעם ",
    stores: [],
  },
  {
    location: "גבת ",
    stores: [],
  },
  {
    location: "גדות ",
    stores: [],
  },
  {
    location: "גדיש ",
    stores: [],
  },
  {
    location: "גדעונה ",
    stores: [],
  },
  {
    location: "גדרה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "גונן ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גורן ",
    stores: [],
  },
  {
    location: "גורנות הגליל ",
    stores: [],
  },
  {
    location: "גזית ",
    stores: [],
  },
  {
    location: "גזר ",
    stores: [],
  },
  {
    location: "גיאה ",
    stores: [],
  },
  {
    location: "גיבתון ",
    stores: [],
  },
  {
    location: "גיזו ",
    stores: [],
  },
  {
    location: "גילון ",
    stores: [],
  },
  {
    location: "גילת ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "גינוסר ",
    stores: [],
  },
  {
    location: "גיניגר ",
    stores: [],
  },
  {
    location: "גינתון ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גיתה ",
    stores: [],
  },
  {
    location: "גיתית ",
    stores: [],
  },
  {
    location: "גלאון ",
    stores: [],
  },
  {
    location: "גלגל ",
    stores: [],
  },
  {
    location: "גליל ים ",
    stores: [],
  },
  {
    location: "גלעד (אבן יצחק) ",
    stores: [],
  },
  {
    location: "גמזו ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גן הדרום ",
    stores: ["victory"],
  },
  {
    location: "גן השומרון ",
    stores: [],
  },
  {
    location: "גן חיים ",
    stores: [],
  },
  {
    location: "גן יאשיה ",
    stores: [],
  },
  {
    location: "גן יבנה ",
    stores: ["victory", "shufersal"],
  },
  {
    location: "גן נר ",
    stores: ["victory", "shufersal"],
  },
  {
    location: "גן שורק ",
    stores: [],
  },
  {
    location: "גן שלמה ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גן שמואל ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "גנות ",
    stores: [],
  },
  {
    location: "גנות הדר ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גני הדר ",
    stores: [],
  },
  {
    location: "גני טל ",
    stores: [],
  },
  {
    location: "גני יוחנן ",
    stores: ["bitan", "mega"],
  },
  {
    location: "גני מודיעין ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "גני עם ",
    stores: ["bitan"],
  },
  {
    location: "גני תקווה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "געש ",
    stores: [],
  },
  {
    location: "געתון ",
    stores: [],
  },
  {
    location: "גפן ",
    stores: [],
  },
  {
    location: "גרופית ",
    stores: [],
  },
  {
    location: "גשור ",
    stores: [],
  },
  {
    location: "גשר ",
    stores: [],
  },
  {
    location: "גשר הזיו ",
    stores: [],
  },
  {
    location: "גת (קיבוץ) ",
    stores: [],
  },
  {
    location: "גת רימון ",
    stores: [],
  },
  {
    location: "דאלית אל-כרמל ",
    stores: [],
  },
  {
    location: "דבורה ",
    stores: ["victory"],
  },
  {
    location: "דבוריה ",
    stores: [],
  },
  {
    location: "דביר ",
    stores: ["mahsane"],
  },
  {
    location: "דברת ",
    stores: ["victory"],
  },
  {
    location: "דגניה א' ",
    stores: [],
  },
  {
    location: "דגניה ב' ",
    stores: [],
  },
  {
    location: "דובב ",
    stores: [],
  },
  {
    location: "דולב ",
    stores: [],
  },
  {
    location: "דור ",
    stores: [],
  },
  {
    location: "דורות ",
    stores: ["victory"],
  },
  {
    location: "דחי ",
    stores: [],
  },
  {
    location: "דייר אל-אסד ",
    stores: [],
  },
  {
    location: "דייר חנא ",
    stores: [],
  },
  {
    location: "דייר ראפאת ",
    stores: [],
  },
  {
    location: "דימונה ",
    stores: ["victory", "mega", "mahsane"],
  },
  {
    location: "דישון ",
    stores: [],
  },
  {
    location: "דליה ",
    stores: [],
  },
  {
    location: "דלתון ",
    stores: [],
  },
  {
    location: "דמיידה ",
    stores: [],
  },
  {
    location: "דן ",
    stores: [],
  },
  {
    location: "דפנה ",
    stores: [],
  },
  {
    location: "דקל ",
    stores: [],
  },
  {
    location: "דריג'את ",
    stores: [],
  },
  {
    location: "האון ",
    stores: [],
  },
  {
    location: "הבונים ",
    stores: [],
  },
  {
    location: "הגושרים ",
    stores: [],
  },
  {
    location: "הדר עם ",
    stores: ["victory"],
  },
  {
    location: "הוד השרון ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "הודיה ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "הודיות ",
    stores: [],
  },
  {
    location: "הוואשלה (שבט) ",
    stores: [],
  },
  {
    location: "הוזייל (שבט) ",
    stores: [],
  },
  {
    location: "הושעיה ",
    stores: [],
  },
  {
    location: "הזורע ",
    stores: [],
  },
  {
    location: "הזורעים ",
    stores: [],
  },
  {
    location: "החותרים ",
    stores: ["victory", "bitan"],
  },
  {
    location: "היוגב ",
    stores: [],
  },
  {
    location: "הילה ",
    stores: [],
  },
  {
    location: "המעפיל ",
    stores: [],
  },
  {
    location: "הסוללים ",
    stores: [],
  },
  {
    location: "העוגן ",
    stores: [],
  },
  {
    location: "הר אדר ",
    stores: [],
  },
  {
    location: "הר גילה ",
    stores: ["shufersal"],
  },
  {
    location: "הר עמשא ",
    stores: ["mahsane"],
  },
  {
    location: "הראל ",
    stores: [],
  },
  {
    location: "הרדוף ",
    stores: [],
  },
  {
    location: "הרצליה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "הררית ",
    stores: [],
  },
  {
    location: "ורד יריחו ",
    stores: [],
  },
  {
    location: "ורדון ",
    stores: [],
  },
  {
    location: "זבארגה (שבט) ",
    stores: [],
  },
  {
    location: "זבדיאל ",
    stores: ["mahsane"],
  },
  {
    location: "זוהר ",
    stores: [],
  },
  {
    location: "זיקים ",
    stores: [],
  },
  {
    location: "זיתן ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "זכרון יעקב ",
    stores: ["bitan", "mega", "shufersal"],
  },
  {
    location: "זכריה ",
    stores: [],
  },
  {
    location: "זמר ",
    stores: [],
  },
  {
    location: "זמרת ",
    stores: [],
  },
  {
    location: "זנוח ",
    stores: [],
  },
  {
    location: "זרועה ",
    stores: ["victory"],
  },
  {
    location: "זרזיר ",
    stores: [],
  },
  {
    location: "זרחיה ",
    stores: ["mahsane"],
  },
  {
    location: "ח'ואלד ",
    stores: [],
  },
  {
    location: "ח'ואלד (שבט) ",
    stores: [],
  },
  {
    location: "חבצלת השרון ",
    stores: [],
  },
  {
    location: "חבר ",
    stores: ["victory"],
  },
  {
    location: "חברון ",
    stores: [],
  },
  {
    location: "חגור ",
    stores: ["bitan"],
  },
  {
    location: "חגי ",
    stores: [],
  },
  {
    location: "חגלה ",
    stores: [],
  },
  {
    location: "חד-נס ",
    stores: [],
  },
  {
    location: "חדיד ",
    stores: ["bitan", "mega"],
  },
  {
    location: "חדרה ",
    stores: ["victory", "bitan", "shufersal"],
  },
  {
    location: "חוג'ייראת (ד'הרה) ",
    stores: [],
  },
  {
    location: "חולדה ",
    stores: [],
  },
  {
    location: "חולון ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "חולית ",
    stores: [],
  },
  {
    location: "חולתה ",
    stores: [],
  },
  {
    location: "חוסן ",
    stores: [],
  },
  {
    location: "חוסנייה ",
    stores: [],
  },
  {
    location: "חופית ",
    stores: [],
  },
  {
    location: "חוקוק ",
    stores: [],
  },
  {
    location: "חורה ",
    stores: [],
  },
  {
    location: "חורפיש ",
    stores: [],
  },
  {
    location: "חורשים ",
    stores: [],
  },
  {
    location: "חזון ",
    stores: [],
  },
  {
    location: "חיבת ציון ",
    stores: [],
  },
  {
    location: "חיננית ",
    stores: [],
  },
  {
    location: "חיפה ",
    stores: ["victory", "bitan", "mega", "mahsane", "shufersal"],
  },
  {
    location: "חירות ",
    stores: [],
  },
  {
    location: "חלוץ ",
    stores: [],
  },
  {
    location: "חלץ ",
    stores: [],
  },
  {
    location: "חמאם ",
    stores: [],
  },
  {
    location: "חמד ",
    stores: [],
  },
  {
    location: "חמדיה ",
    stores: [],
  },
  {
    location: "חמדת ",
    stores: [],
  },
  {
    location: "חמרה ",
    stores: [],
  },
  {
    location: "חניאל ",
    stores: [],
  },
  {
    location: "חניתה ",
    stores: [],
  },
  {
    location: "חנתון ",
    stores: [],
  },
  {
    location: "חספין ",
    stores: [],
  },
  {
    location: "חפץ חיים ",
    stores: [],
  },
  {
    location: "חפצי-בה ",
    stores: [],
  },
  {
    location: "חצב ",
    stores: ["victory"],
  },
  {
    location: "חצבה ",
    stores: ["victory"],
  },
  {
    location: "חצור-אשדוד ",
    stores: ["victory"],
  },
  {
    location: "חצור הגלילית ",
    stores: [],
  },
  {
    location: "חצרים ",
    stores: [],
  },
  {
    location: "חרב לאת ",
    stores: [],
  },
  {
    location: "חרוצים ",
    stores: [],
  },
  {
    location: "חריש ",
    stores: ["bitan", "shufersal"],
  },
  {
    location: "חרמש ",
    stores: [],
  },
  {
    location: "חרשים ",
    stores: [],
  },
  {
    location: "חשמונאים ",
    stores: [],
  },
  {
    location: "טבריה ",
    stores: ["shufersal"],
  },
  {
    location: "טובא-זנגריה ",
    stores: [],
  },
  {
    location: "טורעאן ",
    stores: [],
  },
  {
    location: "טייבה ",
    stores: [],
  },
  {
    location: "טייבה (בעמק) ",
    stores: [],
  },
  {
    location: "טירה ",
    stores: [],
  },
  {
    location: "טירת יהודה ",
    stores: ["bitan", "mega"],
  },
  {
    location: "טירת כרמל ",
    stores: ["victory", "bitan"],
  },
  {
    location: "טירת צבי ",
    stores: [],
  },
  {
    location: "טל-אל ",
    stores: [],
  },
  {
    location: "טל שחר ",
    stores: [],
  },
  {
    location: "טללים ",
    stores: [],
  },
  {
    location: "טלמון ",
    stores: [],
  },
  {
    location: "טמרה ",
    stores: [],
  },
  {
    location: "טמרה (יזרעאל) ",
    stores: [],
  },
  {
    location: "טנא ",
    stores: [],
  },
  {
    location: "טפחות ",
    stores: [],
  },
  {
    location: "יאנוח-ג'ת ",
    stores: [],
  },
  {
    location: "יבול ",
    stores: [],
  },
  {
    location: "יבנאל ",
    stores: ["shufersal"],
  },
  {
    location: "יבנה ",
    stores: ["victory", "shufersal"],
  },
  {
    location: "יגור ",
    stores: [],
  },
  {
    location: "יגל ",
    stores: ["victory"],
  },
  {
    location: "יד בנימין ",
    stores: ["mahsane"],
  },
  {
    location: "יד השמונה ",
    stores: [],
  },
  {
    location: "יד חנה ",
    stores: [],
  },
  {
    location: "יד מרדכי ",
    stores: [],
  },
  {
    location: "יד נתן ",
    stores: ["mahsane"],
  },
  {
    location: "יד רמבם ",
    stores: [],
  },
  {
    location: "ידידה ",
    stores: [],
  },
  {
    location: "יהוד-מונוסון ",
    stores: ["shufersal"],
  },
  {
    location: "יהל ",
    stores: [],
  },
  {
    location: "יובל ",
    stores: [],
  },
  {
    location: "יובלים ",
    stores: [],
  },
  {
    location: "יודפת ",
    stores: [],
  },
  {
    location: "יונתן ",
    stores: [],
  },
  {
    location: "יושיביה ",
    stores: ["victory"],
  },
  {
    location: "יזרעאל ",
    stores: [],
  },
  {
    location: "יחיעם ",
    stores: [],
  },
  {
    location: "יטבתה ",
    stores: [],
  },
  {
    location: "ייטב ",
    stores: [],
  },
  {
    location: "יכיני ",
    stores: ["victory"],
  },
  {
    location: "ינוב ",
    stores: [],
  },
  {
    location: "ינון ",
    stores: ["victory"],
  },
  {
    location: "יסוד המעלה ",
    stores: [],
  },
  {
    location: "יסודות ",
    stores: [],
  },
  {
    location: "יסעור ",
    stores: [],
  },
  {
    location: "יעד ",
    stores: [],
  },
  {
    location: "יעל ",
    stores: ["victory"],
  },
  {
    location: "יעף ",
    stores: [],
  },
  {
    location: "יערה ",
    stores: [],
  },
  {
    location: "יפיע ",
    stores: [],
  },
  {
    location: "יפית ",
    stores: [],
  },
  {
    location: "יפעת ",
    stores: [],
  },
  {
    location: "יפתח ",
    stores: [],
  },
  {
    location: "יצהר ",
    stores: [],
  },
  {
    location: "יציץ ",
    stores: ["bitan", "mega"],
  },
  {
    location: "יקום ",
    stores: [],
  },
  {
    location: "יקיר ",
    stores: [],
  },
  {
    location: "יקנעם (מושבה) ",
    stores: [],
  },
  {
    location: "יקנעם עילית ",
    stores: ["shufersal"],
  },
  {
    location: "יראון ",
    stores: [],
  },
  {
    location: "ירדנה ",
    stores: [],
  },
  {
    location: "ירוחם ",
    stores: ["shufersal"],
  },
  {
    location: "ירושלים ",
    stores: ["victory", "bitan", "mega", "mahsane", "shufersal"],
  },
  {
    location: "ירחיב ",
    stores: ["shufersal"],
  },
  {
    location: "ירכא ",
    stores: [],
  },
  {
    location: "ירקונה ",
    stores: ["bitan"],
  },
  {
    location: "ישע ",
    stores: ["victory"],
  },
  {
    location: "ישעי ",
    stores: ["victory"],
  },
  {
    location: "ישרש ",
    stores: [],
  },
  {
    location: "יתד ",
    stores: [],
  },
  {
    location: "כאבול ",
    stores: [],
  },
  {
    location: "כאוכב אבו אל-היג'א ",
    stores: [],
  },
  {
    location: "כברי ",
    stores: [],
  },
  {
    location: "כדורי ",
    stores: [],
  },
  {
    location: "כדיתה ",
    stores: [],
  },
  {
    location: "כוכב השחר ",
    stores: [],
  },
  {
    location: "כוכב יאיר ",
    stores: [],
  },
  {
    location: "כוכב יעקב ",
    stores: [],
  },
  {
    location: "כוכב מיכאל ",
    stores: [],
  },
  {
    location: "כורזים ",
    stores: [],
  },
  {
    location: "כחל ",
    stores: [],
  },
  {
    location: "כחלה ",
    stores: [],
  },
  {
    location: "כיסופים ",
    stores: ["victory"],
  },
  {
    location: "כישור ",
    stores: ["victory"],
  },
  {
    location: "כליל ",
    stores: [],
  },
  {
    location: "כלנית ",
    stores: [],
  },
  {
    location: "כמאנה ",
    stores: [],
  },
  {
    location: "כמהין ",
    stores: [],
  },
  {
    location: "כמון ",
    stores: [],
  },
  {
    location: "כנות ",
    stores: [],
  },
  {
    location: "כנף ",
    stores: [],
  },
  {
    location: "כנרת (מושבה) ",
    stores: [],
  },
  {
    location: "כנרת (קבוצה) ",
    stores: [],
  },
  {
    location: "כסיפה ",
    stores: [],
  },
  {
    location: "כסלון ",
    stores: [],
  },
  {
    location: "כסרא-סמיע ",
    stores: [],
  },
  {
    location: "כעביה-טבאש-חג'אג'רה ",
    stores: [],
  },
  {
    location: "כפר אביב ",
    stores: ["victory"],
  },
  {
    location: "כפר אדומים ",
    stores: [],
  },
  {
    location: "כפר אוריה ",
    stores: [],
  },
  {
    location: "כפר אחים ",
    stores: ["victory"],
  },
  {
    location: "כפר ביאליק ",
    stores: ["bitan", "mahsane"],
  },
  {
    location: "כפר בילו ",
    stores: ["bitan", "mega"],
  },
  {
    location: "כפר בלום ",
    stores: [],
  },
  {
    location: "כפר בן נון ",
    stores: [],
  },
  {
    location: "כפר ברא ",
    stores: [],
  },
  {
    location: "כפר ברוך ",
    stores: [],
  },
  {
    location: "כפר גדעון ",
    stores: ["victory"],
  },
  {
    location: "כפר גלים ",
    stores: ["victory"],
  },
  {
    location: "כפר גליקסון ",
    stores: [],
  },
  {
    location: "כפר גלעדי ",
    stores: [],
  },
  {
    location: "כפר דניאל ",
    stores: ["bitan", "mega"],
  },
  {
    location: "כפר האורנים ",
    stores: ["victory", "bitan", "shufersal"],
  },
  {
    location: "כפר החורש ",
    stores: [],
  },
  {
    location: "כפר המכבי ",
    stores: [],
  },
  {
    location: "כפר הנגיד ",
    stores: ["victory"],
  },
  {
    location: "כפר הנוער הדתי ",
    stores: [],
  },
  {
    location: "כפר הנשיא ",
    stores: [],
  },
  {
    location: "כפר הס ",
    stores: ["bitan", "mega"],
  },
  {
    location: "כפר הראה ",
    stores: [],
  },
  {
    location: "כפר הריף ",
    stores: [],
  },
  {
    location: "כפר ויתקין ",
    stores: [],
  },
  {
    location: "כפר ורבורג ",
    stores: [],
  },
  {
    location: "כפר ורדים ",
    stores: [],
  },
  {
    location: "כפר זוהרים ",
    stores: [],
  },
  {
    location: "כפר זיתים ",
    stores: [],
  },
  {
    location: "כפר חבד ",
    stores: [],
  },
  {
    location: "כפר חושן ",
    stores: [],
  },
  {
    location: "כפר חיטים ",
    stores: [],
  },
  {
    location: "כפר חיים ",
    stores: [],
  },
  {
    location: "כפר חנניה ",
    stores: [],
  },
  {
    location: "כפר חסידים א' ",
    stores: [],
  },
  {
    location: "כפר חסידים ב' ",
    stores: [],
  },
  {
    location: "כפר חרוב ",
    stores: [],
  },
  {
    location: "כפר טרומן ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "כפר יאסיף ",
    stores: [],
  },
  {
    location: "כפר ידידיה ",
    stores: [],
  },
  {
    location: "כפר יהושע ",
    stores: [],
  },
  {
    location: "כפר יונה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "כפר יחזקאל ",
    stores: [],
  },
  {
    location: "כפר יעבץ ",
    stores: [],
  },
  {
    location: "כפר כמא ",
    stores: [],
  },
  {
    location: "כפר כנא ",
    stores: [],
  },
  {
    location: "כפר מונש ",
    stores: [],
  },
  {
    location: "כפר מימון ",
    stores: ["victory"],
  },
  {
    location: "כפר מלל ",
    stores: [],
  },
  {
    location: "כפר מנדא ",
    stores: [],
  },
  {
    location: "כפר מנחם ",
    stores: [],
  },
  {
    location: "כפר מסריק ",
    stores: ["mahsane"],
  },
  {
    location: "כפר מצר ",
    stores: [],
  },
  {
    location: "כפר מרדכי ",
    stores: ["victory"],
  },
  {
    location: "כפר נטר ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "כפר סאלד ",
    stores: [],
  },
  {
    location: "כפר סבא ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "כפר סילבר ",
    stores: [],
  },
  {
    location: "כפר סירקין ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "כפר עבודה ",
    stores: [],
  },
  {
    location: "כפר עזה ",
    stores: ["victory"],
  },
  {
    location: "כפר עציון ",
    stores: ["mahsane"],
  },
  {
    location: "כפר פינס ",
    stores: [],
  },
  {
    location: "כפר קאסם ",
    stores: [],
  },
  {
    location: "כפר קיש ",
    stores: [],
  },
  {
    location: "כפר קרע ",
    stores: ["shufersal"],
  },
  {
    location: "כפר ראש הנקרה ",
    stores: [],
  },
  {
    location: "כפר רוזנואלד (זרעית) ",
    stores: [],
  },
  {
    location: "כפר רופין ",
    stores: [],
  },
  {
    location: "כפר רות ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "כפר שמאי ",
    stores: [],
  },
  {
    location: "כפר שמואל ",
    stores: ["bitan"],
  },
  {
    location: "כפר שמריהו ",
    stores: ["bitan", "mega"],
  },
  {
    location: "כפר תבור ",
    stores: [],
  },
  {
    location: "כפר תפוח ",
    stores: [],
  },
  {
    location: "כרי דשא ",
    stores: [],
  },
  {
    location: "כרכום ",
    stores: ["bitan"],
  },
  {
    location: "כרם ביבנה (ישיבה) ",
    stores: [],
  },
  {
    location: "כרם בן זמרה ",
    stores: [],
  },
  {
    location: "כרם בן שמן ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "כרם מהרל ",
    stores: [],
  },
  {
    location: "כרם שלום ",
    stores: [],
  },
  {
    location: "כרמי יוסף ",
    stores: [],
  },
  {
    location: "כרמי צור ",
    stores: [],
  },
  {
    location: "כרמי קטיף ",
    stores: ["mahsane"],
  },
  {
    location: "כרמיאל ",
    stores: [],
  },
  {
    location: "כרמיה ",
    stores: ["mahsane"],
  },
  {
    location: "כרמים ",
    stores: ["mahsane"],
  },
  {
    location: "כרמל ",
    stores: ["mahsane"],
  },
  {
    location: "לא רשום ",
    stores: [],
  },
  {
    location: "לבון ",
    stores: [],
  },
  {
    location: "לביא ",
    stores: [],
  },
  {
    location: "לבנים ",
    stores: [],
  },
  {
    location: "להב ",
    stores: ["mahsane"],
  },
  {
    location: "להבות הבשן ",
    stores: [],
  },
  {
    location: "להבות חביבה ",
    stores: [],
  },
  {
    location: "להבים ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "לוד ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "לוזית ",
    stores: ["shufersal"],
  },
  {
    location: "לוחמי הגיטאות ",
    stores: [],
  },
  {
    location: "לוטם ",
    stores: [],
  },
  {
    location: "לוטן ",
    stores: [],
  },
  {
    location: "לימן ",
    stores: [],
  },
  {
    location: "לכיש ",
    stores: ["mahsane"],
  },
  {
    location: "לפיד ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "לפידות ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "לקיה ",
    stores: [],
  },
  {
    location: "מאור ",
    stores: [],
  },
  {
    location: "מאיר שפיה ",
    stores: [],
  },
  {
    location: "מבוא ביתר ",
    stores: ["mega"],
  },
  {
    location: "מבוא דותן ",
    stores: [],
  },
  {
    location: "מבוא חורון ",
    stores: [],
  },
  {
    location: "מבוא חמה ",
    stores: [],
  },
  {
    location: "מבוא מודיעים ",
    stores: [],
  },
  {
    location: "מבואות ים ",
    stores: [],
  },
  {
    location: "מבואות יריחו ",
    stores: [],
  },
  {
    location: "מבועים ",
    stores: ["victory"],
  },
  {
    location: "מבטחים ",
    stores: ["victory"],
  },
  {
    location: "מבקיעים ",
    stores: [],
  },
  {
    location: "מבשרת ציון ",
    stores: ["mega", "shufersal"],
  },
  {
    location: "מג'ד אל-כרום ",
    stores: [],
  },
  {
    location: "מג'דל שמס ",
    stores: [],
  },
  {
    location: "מגאר ",
    stores: [],
  },
  {
    location: "מגדים ",
    stores: ["bitan"],
  },
  {
    location: "מגדל ",
    stores: [],
  },
  {
    location: "מגדל העמק ",
    stores: ["shufersal"],
  },
  {
    location: "מגדל עוז ",
    stores: [],
  },
  {
    location: "מגדלים ",
    stores: [],
  },
  {
    location: "מגידו ",
    stores: [],
  },
  {
    location: "מגל ",
    stores: [],
  },
  {
    location: "מגן ",
    stores: ["victory"],
  },
  {
    location: "מגן שאול ",
    stores: ["victory"],
  },
  {
    location: "מגשימים ",
    stores: [],
  },
  {
    location: "מדרך עוז ",
    stores: [],
  },
  {
    location: "מדרשת בן גוריון ",
    stores: [],
  },
  {
    location: "מדרשת רופין ",
    stores: [],
  },
  {
    location: "מודיעין-מכבים-רעות ",
    stores: ["victory", "mega"],
  },
  {
    location: "מודיעין עילית ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "מולדת ",
    stores: [],
  },
  {
    location: "מוצא עילית ",
    stores: ["mega"],
  },
  {
    location: "מוקייבלה ",
    stores: [],
  },
  {
    location: "מורן ",
    stores: [],
  },
  {
    location: "מורשת ",
    stores: [],
  },
  {
    location: "מזור ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "מזכרת בתיה ",
    stores: ["bitan", "mega"],
  },
  {
    location: "מזרע ",
    stores: [],
  },
  {
    location: "מזרעה ",
    stores: [],
  },
  {
    location: "מחולה ",
    stores: [],
  },
  {
    location: "מחנה הילה ",
    stores: [],
  },
  {
    location: "מחנה טלי ",
    stores: [],
  },
  {
    location: "מחנה יהודית ",
    stores: [],
  },
  {
    location: "מחנה יוכבד ",
    stores: [],
  },
  {
    location: "מחנה יפה ",
    stores: [],
  },
  {
    location: "מחנה יתיר ",
    stores: ["mahsane"],
  },
  {
    location: "מחנה מרים ",
    stores: [],
  },
  {
    location: "מחנה תל נוף ",
    stores: [],
  },
  {
    location: "מחניים ",
    stores: [],
  },
  {
    location: "מחסיה ",
    stores: [],
  },
  {
    location: "מטולה ",
    stores: [],
  },
  {
    location: "מטע ",
    stores: [],
  },
  {
    location: "מי עמי ",
    stores: [],
  },
  {
    location: "מיטב ",
    stores: ["victory"],
  },
  {
    location: "מיטל ",
    stores: [],
  },
  {
    location: "מייסר ",
    stores: [],
  },
  {
    location: "מיצר ",
    stores: [],
  },
  {
    location: "מירב ",
    stores: [],
  },
  {
    location: "מירון ",
    stores: [],
  },
  {
    location: "מישר ",
    stores: ["victory"],
  },
  {
    location: "מיתר ",
    stores: ["mahsane"],
  },
  {
    location: "מכורה ",
    stores: [],
  },
  {
    location: "מכחול ",
    stores: [],
  },
  {
    location: "מכמורת ",
    stores: [],
  },
  {
    location: "מכמנים ",
    stores: [],
  },
  {
    location: "מלאה ",
    stores: ["victory"],
  },
  {
    location: "מלילות ",
    stores: ["victory"],
  },
  {
    location: "מלכיה ",
    stores: [],
  },
  {
    location: "מנוחה ",
    stores: [],
  },
  {
    location: "מנוף ",
    stores: [],
  },
  {
    location: "מנות ",
    stores: [],
  },
  {
    location: "מנחמיה ",
    stores: [],
  },
  {
    location: "מנרה ",
    stores: [],
  },
  {
    location: "מנשית זבדה ",
    stores: [],
  },
  {
    location: "מסד ",
    stores: [],
  },
  {
    location: "מסדה ",
    stores: [],
  },
  {
    location: "מסילות ",
    stores: [],
  },
  {
    location: "מסילת ציון ",
    stores: [],
  },
  {
    location: "מסלול ",
    stores: ["victory"],
  },
  {
    location: "מסעדה ",
    stores: [],
  },
  {
    location: "מסעודין אל-עזאזמה ",
    stores: [],
  },
  {
    location: "מעברות ",
    stores: [],
  },
  {
    location: "מעגלים ",
    stores: ["victory"],
  },
  {
    location: "מעגן ",
    stores: [],
  },
  {
    location: "מעגן מיכאל ",
    stores: [],
  },
  {
    location: "מעוז חיים ",
    stores: [],
  },
  {
    location: "מעון ",
    stores: ["mahsane"],
  },
  {
    location: "מעונה ",
    stores: [],
  },
  {
    location: "מעיליא ",
    stores: [],
  },
  {
    location: "מעין ברוך ",
    stores: [],
  },
  {
    location: "מעין צבי ",
    stores: [],
  },
  {
    location: "מעלה אדומים ",
    stores: ["bitan", "shufersal"],
  },
  {
    location: "מעלה אפרים ",
    stores: [],
  },
  {
    location: "מעלה גלבוע ",
    stores: [],
  },
  {
    location: "מעלה גמלא ",
    stores: [],
  },
  {
    location: "מעלה החמישה ",
    stores: [],
  },
  {
    location: "מעלה לבונה ",
    stores: [],
  },
  {
    location: "מעלה מכמש ",
    stores: [],
  },
  {
    location: "מעלה עירון ",
    stores: [],
  },
  {
    location: "מעלה עמוס ",
    stores: [],
  },
  {
    location: "מעלות-תרשיחא ",
    stores: ["shufersal"],
  },
  {
    location: "מענית ",
    stores: [],
  },
  {
    location: "מעש ",
    stores: [],
  },
  {
    location: "מפלסים ",
    stores: ["victory"],
  },
  {
    location: "מצדות יהודה ",
    stores: ["mahsane"],
  },
  {
    location: "מצובה ",
    stores: [],
  },
  {
    location: "מצליח ",
    stores: [],
  },
  {
    location: "מצפה ",
    stores: [],
  },
  {
    location: "מצפה אביב ",
    stores: ["mahsane"],
  },
  {
    location: "מצפה אילן ",
    stores: [],
  },
  {
    location: "מצפה יריחו ",
    stores: [],
  },
  {
    location: "מצפה נטופה ",
    stores: [],
  },
  {
    location: "מצפה רמון ",
    stores: [],
  },
  {
    location: "מצפה שלם ",
    stores: [],
  },
  {
    location: "מצר ",
    stores: [],
  },
  {
    location: "מקווה ישראל ",
    stores: [],
  },
  {
    location: "מרגליות ",
    stores: [],
  },
  {
    location: "מרום גולן ",
    stores: [],
  },
  {
    location: "מרחב עם ",
    stores: [],
  },
  {
    location: "מרחביה (מושב) ",
    stores: [],
  },
  {
    location: "מרחביה (קיבוץ) ",
    stores: [],
  },
  {
    location: "מרכז שפירא ",
    stores: [],
  },
  {
    location: "משאבי שדה ",
    stores: [],
  },
  {
    location: "משגב דב ",
    stores: ["victory", "shufersal"],
  },
  {
    location: "משגב עם ",
    stores: [],
  },
  {
    location: "משהד ",
    stores: [],
  },
  {
    location: "משואה ",
    stores: [],
  },
  {
    location: "משואות יצחק ",
    stores: [],
  },
  {
    location: "משכיות ",
    stores: [],
  },
  {
    location: "משמר איילון ",
    stores: [],
  },
  {
    location: "משמר דוד ",
    stores: ["mahsane"],
  },
  {
    location: "משמר הירדן ",
    stores: [],
  },
  {
    location: "משמר הנגב ",
    stores: ["victory"],
  },
  {
    location: "משמר העמק ",
    stores: [],
  },
  {
    location: "משמר השבעה ",
    stores: ["victory", "bitan"],
  },
  {
    location: "משמר השרון ",
    stores: [],
  },
  {
    location: "משמרות ",
    stores: ["bitan"],
  },
  {
    location: "משמרת ",
    stores: [],
  },
  {
    location: "משען ",
    stores: [],
  },
  {
    location: "מתן ",
    stores: [],
  },
  {
    location: "מתת ",
    stores: [],
  },
  {
    location: "מתתיהו ",
    stores: ["bitan", "mega"],
  },
  {
    location: "נאות גולן ",
    stores: [],
  },
  {
    location: "נאות הכיכר ",
    stores: [],
  },
  {
    location: "נאות מרדכי ",
    stores: [],
  },
  {
    location: "נאות סמדר ",
    stores: [],
  },
  {
    location: "נאעורה ",
    stores: [],
  },
  {
    location: "נבטים ",
    stores: [],
  },
  {
    location: "נגבה ",
    stores: [],
  },
  {
    location: "נגוהות ",
    stores: ["mahsane"],
  },
  {
    location: "נהורה ",
    stores: ["mahsane"],
  },
  {
    location: "נהלל ",
    stores: [],
  },
  {
    location: "נהריה ",
    stores: [],
  },
  {
    location: "נוב ",
    stores: [],
  },
  {
    location: "נוגה ",
    stores: ["mahsane"],
  },
  {
    location: "נוה צוף ",
    stores: [],
  },
  {
    location: "נווה ",
    stores: [],
  },
  {
    location: "נווה אבות ",
    stores: [],
  },
  {
    location: "נווה אור ",
    stores: ["bitan"],
  },
  {
    location: "נווה אטיב ",
    stores: [],
  },
  {
    location: "נווה אילן ",
    stores: [],
  },
  {
    location: "נווה איתן ",
    stores: [],
  },
  {
    location: "נווה דניאל ",
    stores: ["mahsane"],
  },
  {
    location: "נווה זוהר ",
    stores: [],
  },
  {
    location: "נווה זיו ",
    stores: [],
  },
  {
    location: "נווה חריף ",
    stores: [],
  },
  {
    location: "נווה ים ",
    stores: [],
  },
  {
    location: "נווה ימין ",
    stores: ["bitan"],
  },
  {
    location: "נווה ירק ",
    stores: [],
  },
  {
    location: "נווה מבטח ",
    stores: ["victory"],
  },
  {
    location: "נווה מיכאל ",
    stores: [],
  },
  {
    location: "נווה שלום ",
    stores: ["mahsane"],
  },
  {
    location: "נועם ",
    stores: ["mahsane"],
  },
  {
    location: "נוף איילון ",
    stores: ["bitan", "mega"],
  },
  {
    location: "נוף הגליל ",
    stores: ["mahsane"],
  },
  {
    location: "נופים ",
    stores: [],
  },
  {
    location: "נופית ",
    stores: [],
  },
  {
    location: "נופך ",
    stores: ["bitan", "mega"],
  },
  {
    location: "נוקדים ",
    stores: [],
  },
  {
    location: "נורדיה ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "נורית ",
    stores: ["victory"],
  },
  {
    location: "נחושה ",
    stores: [],
  },
  {
    location: "נחל עוז ",
    stores: [],
  },
  {
    location: "נחלה ",
    stores: [],
  },
  {
    location: "נחליאל ",
    stores: [],
  },
  {
    location: "נחלים ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "נחם ",
    stores: [],
  },
  {
    location: "נחף ",
    stores: [],
  },
  {
    location: "נחשולים ",
    stores: [],
  },
  {
    location: "נחשון ",
    stores: [],
  },
  {
    location: "נחשונים ",
    stores: [],
  },
  {
    location: "נטועה ",
    stores: [],
  },
  {
    location: "נטור ",
    stores: [],
  },
  {
    location: "נטע ",
    stores: ["mahsane"],
  },
  {
    location: "נטעים ",
    stores: [],
  },
  {
    location: "נטף ",
    stores: [],
  },
  {
    location: "ניין ",
    stores: [],
  },
  {
    location: "נילי ",
    stores: [],
  },
  {
    location: "ניצן ",
    stores: [],
  },
  {
    location: "ניצן ב' ",
    stores: [],
  },
  {
    location: "ניצנה (קהילת חינוך) ",
    stores: [],
  },
  {
    location: "ניצני סיני ",
    stores: [],
  },
  {
    location: "ניצני עוז ",
    stores: [],
  },
  {
    location: "ניצנים ",
    stores: [],
  },
  {
    location: "ניר אליהו ",
    stores: [],
  },
  {
    location: "ניר בנים ",
    stores: ["mahsane"],
  },
  {
    location: "ניר גלים ",
    stores: ["victory"],
  },
  {
    location: "ניר דוד (תל עמל) ",
    stores: [],
  },
  {
    location: "ניר חן ",
    stores: [],
  },
  {
    location: "ניר יפה ",
    stores: ["victory"],
  },
  {
    location: "ניר יצחק ",
    stores: [],
  },
  {
    location: "ניר ישראל ",
    stores: [],
  },
  {
    location: "ניר משה ",
    stores: ["victory"],
  },
  {
    location: "ניר עוז ",
    stores: ["victory"],
  },
  {
    location: "ניר עם ",
    stores: ["victory"],
  },
  {
    location: "ניר עציון ",
    stores: ["mahsane"],
  },
  {
    location: "ניר עקיבא ",
    stores: ["victory", "bitan"],
  },
  {
    location: "ניר צבי ",
    stores: [],
  },
  {
    location: "נירים ",
    stores: ["victory"],
  },
  {
    location: "נירית ",
    stores: ["victory"],
  },
  {
    location: "נמרוד ",
    stores: [],
  },
  {
    location: "נס הרים ",
    stores: ["mega"],
  },
  {
    location: "נס עמים ",
    stores: [],
  },
  {
    location: "נס ציונה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "נעורים ",
    stores: [],
  },
  {
    location: "נעלה ",
    stores: [],
  },
  {
    location: "נעמה ",
    stores: [],
  },
  {
    location: "נען ",
    stores: [],
  },
  {
    location: "נערן ",
    stores: [],
  },
  {
    location: "נצאצרה (שבט) ",
    stores: [],
  },
  {
    location: "נצר חזני ",
    stores: [],
  },
  {
    location: "נצר סרני ",
    stores: [],
  },
  {
    location: "נצרת ",
    stores: ["mahsane"],
  },
  {
    location: "נשר ",
    stores: ["victory", "bitan", "mahsane"],
  },
  {
    location: "נתיב הגדוד ",
    stores: [],
  },
  {
    location: "נתיב הלה ",
    stores: [],
  },
  {
    location: "נתיב העשרה ",
    stores: [],
  },
  {
    location: "נתיב השיירה ",
    stores: [],
  },
  {
    location: "נתיבות ",
    stores: ["victory"],
  },
  {
    location: "נתניה ",
    stores: ["victory", "bitan", "mega", "mahsane", "shufersal"],
  },
  {
    location: "סאג'ור ",
    stores: [],
  },
  {
    location: "סאסא ",
    stores: [],
  },
  {
    location: "סביון ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "סגולה ",
    stores: [],
  },
  {
    location: "סואעד (חמרייה) ",
    stores: [],
  },
  {
    location: "סואעד (כמאנה) (שבט) ",
    stores: [],
  },
  {
    location: "סולם ",
    stores: [],
  },
  {
    location: "סוסיה ",
    stores: ["mahsane"],
  },
  {
    location: "סופה ",
    stores: [],
  },
  {
    location: "סח'נין ",
    stores: ["shufersal"],
  },
  {
    location: "סייד (שבט) ",
    stores: [],
  },
  {
    location: "סלמה ",
    stores: [],
  },
  {
    location: "סלעית ",
    stores: [],
  },
  {
    location: "סמר ",
    stores: [],
  },
  {
    location: "סנסנה ",
    stores: ["mahsane"],
  },
  {
    location: "סעד ",
    stores: ["victory"],
  },
  {
    location: "סעוה ",
    stores: [],
  },
  {
    location: "סער ",
    stores: [],
  },
  {
    location: "ספיר ",
    stores: [],
  },
  {
    location: "סתריה ",
    stores: [],
  },
  {
    location: "ע'ג'ר ",
    stores: [],
  },
  {
    location: "עבדון ",
    stores: [],
  },
  {
    location: "עברון ",
    stores: [],
  },
  {
    location: "עגור ",
    stores: [],
  },
  {
    location: "עדי ",
    stores: [],
  },
  {
    location: "עדנים ",
    stores: [],
  },
  {
    location: "עוזה ",
    stores: ["mahsane"],
  },
  {
    location: "עוזייר ",
    stores: [],
  },
  {
    location: "עולש ",
    stores: [],
  },
  {
    location: "עומר ",
    stores: ["victory", "bitan", "mahsane"],
  },
  {
    location: "עופר ",
    stores: [],
  },
  {
    location: "עופרה ",
    stores: [],
  },
  {
    location: "עוצם ",
    stores: ["mahsane"],
  },
  {
    location: "עוקבי (בנו עוקבה) ",
    stores: [],
  },
  {
    location: "עזוז ",
    stores: [],
  },
  {
    location: "עזר ",
    stores: ["victory"],
  },
  {
    location: "עזריאל ",
    stores: [],
  },
  {
    location: "עזריה ",
    stores: [],
  },
  {
    location: "עזריקם ",
    stores: ["victory"],
  },
  {
    location: "עטאוונה (שבט) ",
    stores: [],
  },
  {
    location: "עטרת ",
    stores: [],
  },
  {
    location: "עידן ",
    stores: [],
  },
  {
    location: "עיילבון ",
    stores: [],
  },
  {
    location: "עיינות ",
    stores: ["victory"],
  },
  {
    location: "עילוט ",
    stores: [],
  },
  {
    location: "עין איילה ",
    stores: [],
  },
  {
    location: "עין אל-אסד ",
    stores: [],
  },
  {
    location: "עין גב ",
    stores: [],
  },
  {
    location: "עין גדי ",
    stores: [],
  },
  {
    location: "עין דור ",
    stores: [],
  },
  {
    location: "עין הבשור ",
    stores: ["victory"],
  },
  {
    location: "עין הוד ",
    stores: [],
  },
  {
    location: "עין החורש ",
    stores: [],
  },
  {
    location: "עין המפרץ ",
    stores: [],
  },
  {
    location: "עין הנציב ",
    stores: [],
  },
  {
    location: "עין העמק ",
    stores: [],
  },
  {
    location: "עין השופט ",
    stores: ["victory"],
  },
  {
    location: "עין השלושה ",
    stores: ["victory"],
  },
  {
    location: "עין ורד ",
    stores: ["bitan", "mega"],
  },
  {
    location: "עין זיוון ",
    stores: [],
  },
  {
    location: "עין חוד ",
    stores: [],
  },
  {
    location: "עין חצבה ",
    stores: [],
  },
  {
    location: "עין חרוד (איחוד) ",
    stores: [],
  },
  {
    location: "עין חרוד (מאוחד) ",
    stores: [],
  },
  {
    location: "עין יהב ",
    stores: [],
  },
  {
    location: "עין יעקב ",
    stores: [],
  },
  {
    location: "עין כרם-ביס חקלאי ",
    stores: [],
  },
  {
    location: "עין כרמל ",
    stores: ["bitan"],
  },
  {
    location: "עין מאהל ",
    stores: [],
  },
  {
    location: "עין נקובא ",
    stores: [],
  },
  {
    location: "עין עירון ",
    stores: [],
  },
  {
    location: "עין צורים ",
    stores: [],
  },
  {
    location: "עין קנייא ",
    stores: [],
  },
  {
    location: "עין ראפה ",
    stores: [],
  },
  {
    location: "עין שמר ",
    stores: [],
  },
  {
    location: "עין שריד ",
    stores: ["bitan", "mega"],
  },
  {
    location: "עין תמר ",
    stores: [],
  },
  {
    location: "עינת ",
    stores: ["victory"],
  },
  {
    location: "עיר אובות ",
    stores: [],
  },
  {
    location: "עכו ",
    stores: ["victory"],
  },
  {
    location: "עלומים ",
    stores: ["victory"],
  },
  {
    location: "עלי ",
    stores: [],
  },
  {
    location: "עלי זהב ",
    stores: [],
  },
  {
    location: "עלמה ",
    stores: [],
  },
  {
    location: "עלמון ",
    stores: [],
  },
  {
    location: "עמוקה ",
    stores: [],
  },
  {
    location: "עמיחי ",
    stores: [],
  },
  {
    location: "עמינדב ",
    stores: ["bitan"],
  },
  {
    location: "עמיעד ",
    stores: [],
  },
  {
    location: "עמיעוז ",
    stores: ["victory"],
  },
  {
    location: "עמיקם ",
    stores: [],
  },
  {
    location: "עמיר ",
    stores: [],
  },
  {
    location: "עמנואל ",
    stores: [],
  },
  {
    location: "עמקה ",
    stores: [],
  },
  {
    location: "ענב ",
    stores: [],
  },
  {
    location: "עספיא ",
    stores: [],
  },
  {
    location: "עפולה ",
    stores: ["victory", "shufersal"],
  },
  {
    location: "עצמון שגב ",
    stores: [],
  },
  {
    location: "עראבה ",
    stores: [],
  },
  {
    location: "עראמשה ",
    stores: [],
  },
  {
    location: "ערב אל נעים ",
    stores: [],
  },
  {
    location: "ערד ",
    stores: ["mahsane", "shufersal"],
  },
  {
    location: "ערוגות ",
    stores: ["victory"],
  },
  {
    location: "ערערה-בנגב ",
    stores: [],
  },
  {
    location: "ערערה ",
    stores: [],
  },
  {
    location: "עשרת ",
    stores: ["victory"],
  },
  {
    location: "עתלית ",
    stores: ["victory", "bitan"],
  },
  {
    location: "עתניאל ",
    stores: ["mahsane"],
  },
  {
    location: "פארן ",
    stores: [],
  },
  {
    location: "פדואל ",
    stores: [],
  },
  {
    location: "פדויים ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "פדיה ",
    stores: [],
  },
  {
    location: "פוריה - כפר עבודה ",
    stores: [],
  },
  {
    location: "פוריה - נווה עובד ",
    stores: [],
  },
  {
    location: "פוריה עילית ",
    stores: [],
  },
  {
    location: "פוריידיס ",
    stores: [],
  },
  {
    location: "פורת ",
    stores: [],
  },
  {
    location: "פטיש ",
    stores: ["victory"],
  },
  {
    location: "פלך ",
    stores: [],
  },
  {
    location: "פלמחים ",
    stores: [],
  },
  {
    location: "פני חבר ",
    stores: [],
  },
  {
    location: "פסגות ",
    stores: [],
  },
  {
    location: "פסוטה ",
    stores: [],
  },
  {
    location: "פעמי תשז ",
    stores: [],
  },
  {
    location: "פצאל ",
    stores: [],
  },
  {
    location: "פקיעין (בוקייעה) ",
    stores: [],
  },
  {
    location: "פקיעין חדשה ",
    stores: [],
  },
  {
    location: "פרדס חנה-כרכור ",
    stores: [],
  },
  {
    location: "פרדסיה ",
    stores: ["bitan", "mega", "shufersal"],
  },
  {
    location: "פרוד ",
    stores: [],
  },
  {
    location: "פרזון ",
    stores: ["victory"],
  },
  {
    location: "פרי גן ",
    stores: [],
  },
  {
    location: "פתח תקווה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "פתחיה ",
    stores: [],
  },
  {
    location: "צאלים ",
    stores: [],
  },
  {
    location: "צביה ",
    stores: [],
  },
  {
    location: "צבעון ",
    stores: [],
  },
  {
    location: "צובה ",
    stores: [],
  },
  {
    location: "צוחר ",
    stores: ["victory"],
  },
  {
    location: "צופיה ",
    stores: [],
  },
  {
    location: "צופים ",
    stores: [],
  },
  {
    location: "צופית ",
    stores: [],
  },
  {
    location: "צופר ",
    stores: [],
  },
  {
    location: "צוקי ים ",
    stores: [],
  },
  {
    location: "צוקים ",
    stores: [],
  },
  {
    location: "צור הדסה ",
    stores: ["mega"],
  },
  {
    location: "צור יצחק ",
    stores: ["victory"],
  },
  {
    location: "צור משה ",
    stores: ["bitan", "mega"],
  },
  {
    location: "צור נתן ",
    stores: [],
  },
  {
    location: "צוריאל ",
    stores: [],
  },
  {
    location: "צורית ",
    stores: ["victory"],
  },
  {
    location: "ציפורי ",
    stores: [],
  },
  {
    location: "צלפון ",
    stores: [],
  },
  {
    location: "צנדלה ",
    stores: [],
  },
  {
    location: "צפריה ",
    stores: ["victory"],
  },
  {
    location: "צפרירים ",
    stores: [],
  },
  {
    location: "צפת ",
    stores: ["shufersal"],
  },
  {
    location: "צרופה ",
    stores: [],
  },
  {
    location: "צרעה ",
    stores: ["bitan"],
  },
  {
    location: "קבועה (שבט) ",
    stores: [],
  },
  {
    location: "קבוצת יבנה ",
    stores: ["victory"],
  },
  {
    location: "קדומים ",
    stores: [],
  },
  {
    location: "קדימה-צורן ",
    stores: [],
  },
  {
    location: "קדמה ",
    stores: [],
  },
  {
    location: "קדמת צבי ",
    stores: [],
  },
  {
    location: "קדר ",
    stores: [],
  },
  {
    location: "קדרון ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "קדרים ",
    stores: [],
  },
  {
    location: "קודייראת א-צאנע(שבט) ",
    stores: [],
  },
  {
    location: "קוואעין (שבט) ",
    stores: [],
  },
  {
    location: "קוממיות ",
    stores: ["mahsane"],
  },
  {
    location: "קורנית ",
    stores: ["victory"],
  },
  {
    location: "קטורה ",
    stores: [],
  },
  {
    location: "קיסריה ",
    stores: ["bitan"],
  },
  {
    location: "קלחים ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "קליה ",
    stores: [],
  },
  {
    location: "קלנסווה ",
    stores: [],
  },
  {
    location: "קלע ",
    stores: [],
  },
  {
    location: "קציר ",
    stores: [],
  },
  {
    location: "קצר א-סר ",
    stores: [],
  },
  {
    location: "קצרין ",
    stores: ["shufersal"],
  },
  {
    location: "קרית אונו ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "קרית ארבע ",
    stores: [],
  },
  {
    location: "קרית אתא ",
    stores: ["victory", "bitan", "mega", "mahsane"],
  },
  {
    location: "קרית ביאליק ",
    stores: ["victory", "bitan", "mahsane"],
  },
  {
    location: "קרית גת ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "קרית טבעון ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "קרית ים ",
    stores: ["victory", "bitan", "mahsane"],
  },
  {
    location: "קרית יערים ",
    stores: ["victory", "bitan", "mahsane"],
  },
  {
    location: "קרית יערים(מוסד) ",
    stores: [],
  },
  {
    location: "קרית מוצקין ",
    stores: ["victory", "bitan", "mahsane"],
  },
  {
    location: "קרית מלאכי ",
    stores: ["victory"],
  },
  {
    location: "קרית נטפים ",
    stores: [],
  },
  {
    location: "קרית ענבים ",
    stores: [],
  },
  {
    location: "קרית עקרון ",
    stores: ["bitan", "mega"],
  },
  {
    location: "קרית שלמה ",
    stores: [],
  },
  {
    location: "קרית שמונה ",
    stores: [],
  },
  {
    location: "קרני שומרון ",
    stores: [],
  },
  {
    location: "קשת ",
    stores: [],
  },
  {
    location: "ראמה ",
    stores: [],
  },
  {
    location: "ראס אל-עין ",
    stores: [],
  },
  {
    location: "ראס עלי ",
    stores: [],
  },
  {
    location: "ראש העין ",
    stores: ["victory", "mega", "shufersal"],
  },
  {
    location: "ראש פינה ",
    stores: ["shufersal"],
  },
  {
    location: "ראש צורים ",
    stores: [],
  },
  {
    location: "ראשון לציון ",
    stores: ["victory", "bitan", "shufersal"],
  },
  {
    location: "רבבה ",
    stores: [],
  },
  {
    location: "רבדים ",
    stores: [],
  },
  {
    location: "רביבים ",
    stores: [],
  },
  {
    location: "רביד ",
    stores: [],
  },
  {
    location: "רגבה ",
    stores: [],
  },
  {
    location: "רגבים ",
    stores: ["victory"],
  },
  {
    location: "רהט ",
    stores: ["mahsane"],
  },
  {
    location: "רווחה ",
    stores: ["mahsane"],
  },
  {
    location: "רוויה ",
    stores: [],
  },
  {
    location: "רוח מדבר ",
    stores: [],
  },
  {
    location: "רוחמה ",
    stores: [],
  },
  {
    location: "רומאנה ",
    stores: [],
  },
  {
    location: "רומת הייב ",
    stores: [],
  },
  {
    location: "רועי ",
    stores: [],
  },
  {
    location: "רותם ",
    stores: [],
  },
  {
    location: "רחוב ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "רחובות ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "רחלים ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "ריחאניה ",
    stores: [],
  },
  {
    location: "ריחן ",
    stores: [],
  },
  {
    location: "ריינה ",
    stores: [],
  },
  {
    location: "רימונים ",
    stores: ["victory"],
  },
  {
    location: "רינתיה ",
    stores: ["bitan", "mega"],
  },
  {
    location: "רכסים ",
    stores: [],
  },
  {
    location: "רם-און ",
    stores: [],
  },
  {
    location: "רמות ",
    stores: ["bitan"],
  },
  {
    location: "רמות השבים ",
    stores: ["bitan"],
  },
  {
    location: "רמות מאיר ",
    stores: [],
  },
  {
    location: "רמות מנשה ",
    stores: [],
  },
  {
    location: "רמות נפתלי ",
    stores: [],
  },
  {
    location: "רמלה ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "רמת גן ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "רמת דוד ",
    stores: [],
  },
  {
    location: "רמת הכובש ",
    stores: [],
  },
  {
    location: "רמת השופט ",
    stores: [],
  },
  {
    location: "רמת השרון ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "רמת טראמפ ",
    stores: [],
  },
  {
    location: "רמת יוחנן ",
    stores: ["mahsane"],
  },
  {
    location: "רמת ישי ",
    stores: ["victory", "shufersal"],
  },
  {
    location: "רמת מגשימים ",
    stores: [],
  },
  {
    location: "רמת צבי ",
    stores: [],
  },
  {
    location: "רמת רזיאל ",
    stores: [],
  },
  {
    location: "רמת רחל ",
    stores: ["bitan", "mega"],
  },
  {
    location: "רנן ",
    stores: ["victory"],
  },
  {
    location: "רעים ",
    stores: [],
  },
  {
    location: "רעננה ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "רקפת ",
    stores: [],
  },
  {
    location: "רשפון ",
    stores: ["bitan", "mega"],
  },
  {
    location: "רשפים ",
    stores: [],
  },
  {
    location: "רתמים ",
    stores: [],
  },
  {
    location: "שאר ישוב ",
    stores: [],
  },
  {
    location: "שבי דרום ",
    stores: ["victory"],
  },
  {
    location: "שבי ציון ",
    stores: [],
  },
  {
    location: "שבי שומרון ",
    stores: [],
  },
  {
    location: "שבלי - אום אל-גנם ",
    stores: [],
  },
  {
    location: "שגב-שלום ",
    stores: [],
  },
  {
    location: "שדה אילן ",
    stores: [],
  },
  {
    location: "שדה אליהו ",
    stores: [],
  },
  {
    location: "שדה אליעזר ",
    stores: [],
  },
  {
    location: "שדה בוקר ",
    stores: [],
  },
  {
    location: "שדה דוד ",
    stores: [],
  },
  {
    location: "שדה ורבורג ",
    stores: [],
  },
  {
    location: "שדה יואב ",
    stores: [],
  },
  {
    location: "שדה יעקב ",
    stores: [],
  },
  {
    location: "שדה יצחק ",
    stores: [],
  },
  {
    location: "שדה משה ",
    stores: ["mahsane"],
  },
  {
    location: "שדה נחום ",
    stores: [],
  },
  {
    location: "שדה נחמיה ",
    stores: [],
  },
  {
    location: "שדה ניצן ",
    stores: ["victory"],
  },
  {
    location: "שדה עוזיהו ",
    stores: ["victory"],
  },
  {
    location: "שדה צבי ",
    stores: ["mahsane"],
  },
  {
    location: "שדות ים ",
    stores: ["bitan"],
  },
  {
    location: "שדות מיכה ",
    stores: [],
  },
  {
    location: "שדי אברהם ",
    stores: [],
  },
  {
    location: "שדי חמד ",
    stores: ["bitan"],
  },
  {
    location: "שדי תרומות ",
    stores: [],
  },
  {
    location: "שדמה ",
    stores: ["victory"],
  },
  {
    location: "שדמות דבורה ",
    stores: [],
  },
  {
    location: "שדמות מחולה ",
    stores: [],
  },
  {
    location: "שדרות ",
    stores: ["victory", "shufersal"],
  },
  {
    location: "שואבה ",
    stores: [],
  },
  {
    location: "שובה ",
    stores: ["victory"],
  },
  {
    location: "שובל ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "שוהם ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "שומרה ",
    stores: [],
  },
  {
    location: "שומריה ",
    stores: ["mahsane"],
  },
  {
    location: "שוקדה ",
    stores: ["victory"],
  },
  {
    location: "שורש ",
    stores: [],
  },
  {
    location: "שורשים ",
    stores: [],
  },
  {
    location: "שושנת העמקים ",
    stores: [],
  },
  {
    location: "שזור ",
    stores: [],
  },
  {
    location: "שחר ",
    stores: ["mahsane"],
  },
  {
    location: "שחרות ",
    stores: ["victory", "bitan"],
  },
  {
    location: "שיבולים ",
    stores: ["victory"],
  },
  {
    location: "שיזף ",
    stores: [],
  },
  {
    location: "שיטים ",
    stores: [],
  },
  {
    location: "שייח' דנון ",
    stores: [],
  },
  {
    location: "שילה ",
    stores: [],
  },
  {
    location: "שילת ",
    stores: ["victory", "bitan", "mega"],
  },
  {
    location: "שכניה ",
    stores: [],
  },
  {
    location: "שלווה ",
    stores: ["mahsane"],
  },
  {
    location: "שלווה במדבר ",
    stores: [],
  },
  {
    location: "שלוחות ",
    stores: [],
  },
  {
    location: "שלומי ",
    stores: [],
  },
  {
    location: "שלומית ",
    stores: [],
  },
  {
    location: "שמיר ",
    stores: [],
  },
  {
    location: "שמעה ",
    stores: ["mahsane"],
  },
  {
    location: "שמרת ",
    stores: [],
  },
  {
    location: "שמשית ",
    stores: [],
  },
  {
    location: "שני ",
    stores: [],
  },
  {
    location: "שניר ",
    stores: [],
  },
  {
    location: "שעב ",
    stores: [],
  },
  {
    location: "שעל ",
    stores: [],
  },
  {
    location: "שעלבים ",
    stores: ["bitan", "mega"],
  },
  {
    location: "שער אפרים ",
    stores: [],
  },
  {
    location: "שער הגולן ",
    stores: [],
  },
  {
    location: "שער העמקים ",
    stores: ["mahsane"],
  },
  {
    location: "שער מנשה ",
    stores: [],
  },
  {
    location: "שער שומרון ",
    stores: [],
  },
  {
    location: "שפיים ",
    stores: [],
  },
  {
    location: "שפיר ",
    stores: [],
  },
  {
    location: "שפר ",
    stores: [],
  },
  {
    location: "שפרעם ",
    stores: [],
  },
  {
    location: "שקד ",
    stores: [],
  },
  {
    location: "שקף ",
    stores: ["mahsane"],
  },
  {
    location: "שרונה ",
    stores: [],
  },
  {
    location: "שריגים (לי-און) ",
    stores: [],
  },
  {
    location: "שריד ",
    stores: [],
  },
  {
    location: "שרשרת ",
    stores: ["victory"],
  },
  {
    location: "שתולה ",
    stores: [],
  },
  {
    location: "שתולים ",
    stores: ["victory"],
  },
  {
    location: "תאשור ",
    stores: ["victory"],
  },
  {
    location: "תדהר ",
    stores: ["victory"],
  },
  {
    location: "תובל ",
    stores: [],
  },
  {
    location: "תומר ",
    stores: [],
  },
  {
    location: "תושיה ",
    stores: ["victory"],
  },
  {
    location: "תימורים ",
    stores: [],
  },
  {
    location: "תירוש ",
    stores: [],
  },
  {
    location: "תל אביב - יפו ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "תל יוסף ",
    stores: [],
  },
  {
    location: "תל יצחק ",
    stores: ["victory"],
  },
  {
    location: "תל מונד ",
    stores: ["victory", "bitan", "mega", "shufersal"],
  },
  {
    location: "תל עדשים ",
    stores: ["victory"],
  },
  {
    location: "תל ציון ",
    stores: [],
  },
  {
    location: "תל קציר ",
    stores: [],
  },
  {
    location: "תל שבע ",
    stores: [],
  },
  {
    location: "תל תאומים ",
    stores: [],
  },
  {
    location: "תלם ",
    stores: [],
  },
  {
    location: "תלמי אליהו ",
    stores: ["victory"],
  },
  {
    location: "תלמי אלעזר ",
    stores: [],
  },
  {
    location: "תלמי בילו ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "תלמי יוסף ",
    stores: [],
  },
  {
    location: "תלמי יחיאל ",
    stores: ["victory"],
  },
  {
    location: "תלמי יפה ",
    stores: [],
  },
  {
    location: "תלמים ",
    stores: [],
  },
  {
    location: "תמרת ",
    stores: [],
  },
  {
    location: "תנובות ",
    stores: [],
  },
  {
    location: "תעוז ",
    stores: [],
  },
  {
    location: "תפרח ",
    stores: ["victory", "mahsane"],
  },
  {
    location: "תקומה ",
    stores: [],
  },
  {
    location: "תקוע ",
    stores: [],
  },
  {
    location: "תראבין א-צאנע (שבט) ",
    stores: [],
  },
  {
    location: "תראבין א-צאנע(ישוב) ",
    stores: [],
  },
  {
    location: "תרום ",
    stores: [],
  },
];

export default storesLocation;
