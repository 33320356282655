import { CSSProperties, ReactNode } from "react";

interface IPropsFlex {
  justify?: CSSProperties["justifyContent"];
  align?: CSSProperties["alignItems"];
  gap?: number;
  flexDirection?: CSSProperties["flexDirection"];
  style?: CSSProperties;
  children: ReactNode;
}

const Flex = ({ justify, align, gap, flexDirection, style, children }: IPropsFlex) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: justify,
        alignItems: align,
        gap,
        flexDirection,
        ...style,
      }}
    >
      {children}
    </div>
  );
};

export default Flex;
