import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

const DietHealth: FC<IconCustom> = ({ onClick, size = iconSizeDefault, hovered }) => {
  return (
    <svg
      onClick={onClick}
      version="1.1"
      viewBox="-51.2 -51.2 614.40 614.40"
      xmlSpace="preserve"
      width={size}
      height={size}
      fill={hovered ? "#000000" : "var(--iconC)"}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <circle
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={5}
          fill={hovered ? "#FFD15D" : "var(--iconB)"}
          cx="256"
          cy="256"
          r="256"
        ></circle>

        <rect
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={3}
          x="199.111"
          y="139.431"
          fill={hovered ? "#FFFFFF" : "var(--iconB)"}
          width="199.111"
          height="256"
        ></rect>
        <polygon
          stroke={hovered ? "#000000" : "var(--iconB)"}
          strokeWidth={2}
          fill={hovered ? "#D0D1D3" : "var(--iconB)"}
          points="398.222,139.431 302.545,139.431 301.971,139.431 300.248,139.431 300.248,395.431 398.222,395.431 "
        ></polygon>

        <path
          fill={hovered ? "#C1321F" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={3}
          d="M187.618,277.43c-40.781,0-73.84,14.6-73.84,55.38c0,28.057,15.651,72.025,38.697,84.516 c7.725,4.187,17.103,3.162,24.148-2.088c3.069-2.286,6.871-3.639,10.993-3.639s7.925,1.353,10.993,3.639 c7.046,5.249,16.425,6.275,24.148,2.088c23.045-12.491,38.697-56.461,38.697-84.516C261.458,292.03,228.399,277.43,187.618,277.43z"
        ></path>
        <path
          fill={hovered ? "#A82116" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={3}
          d="M187.618,277.43c-0.124,0-0.248,0.003-0.372,0.003v134.182c0.124-0.003,0.247-0.016,0.372-0.016 c4.122,0,7.925,1.353,10.993,3.639c7.046,5.248,16.424,6.273,24.148,2.088c23.045-12.491,38.697-56.461,38.697-84.516 C261.458,292.03,228.399,277.43,187.618,277.43z"
        ></path>
        <path
          fill={hovered ? "#2C9984" : "var(--iconB)"}
          d="M231.727,253.388c0,0-38.264-4.779-44.11,35.188C187.618,288.577,221.668,297.669,231.727,253.388z"
        ></path>
        <path
          fill={hovered ? "#268472" : "var(--iconC)"}
          d="M187.725,288.603c2.069,0.505,34.123,7.596,43.935-34.933L187.725,288.603z"
        ></path>
        <path
          fill={hovered ? "#935635" : "var(--iconC)"}
          d="M187.621,301.484c-0.079,0-0.157-0.002-0.238-0.005c-3.086-0.128-5.485-2.736-5.356-5.822 c0.802-19.213-16.056-32.084-16.227-32.213c-2.472-1.853-2.972-5.36-1.119-7.832c1.855-2.47,5.36-2.974,7.832-1.119 c0.888,0.665,21.735,16.598,20.694,41.627C193.081,299.13,190.604,301.484,187.621,301.484z"
        ></path>
        <path
          fill={hovered ? "#804000" : "var(--iconC)"}
          d="M187.245,271.939v29.518c0.048,0.003,0.091,0.017,0.14,0.021c0.079,0.003,0.159,0.005,0.238,0.005 c2.982,0,5.46-2.353,5.585-5.361C193.602,286.644,190.855,278.475,187.245,271.939z"
        ></path>
      </g>
    </svg>
  );
};

export default memo(DietHealth);
