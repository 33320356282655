import serverAPI from "../../api/server";
import { checkTokenExpIsValid, parseJwt } from "../../utils/utils";

class CsrfToken {
  private _csrfToken: string;

  constructor() {
    this._csrfToken = "";
  }

  private async fetchToken(email: string, isUser: boolean): Promise<string> {
    try {
      //* body here has no meaning at all just to confuse the token
      const res = await serverAPI("init/get", {
        email: email === "guest" ? "null" : email,
        isUser: isUser,
      });
      return res.data.cs;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  public validateToken() {
    const decoded = parseJwt(this._csrfToken);
    const isValid = checkTokenExpIsValid(decoded);

    if (!this._csrfToken || !decoded || !isValid) {
      // alert(`Error Detected Please Refresh The Page`);
      return false;
    }
    return true;
  }

  public async setToken(email: string, isUser: boolean): Promise<void> {
    this._csrfToken = await this.fetchToken(email, isUser);
    // console.log("Generate CSRF TOKEN :", csrfToken.getToken());
  }

  public getToken(): string {
    return this._csrfToken;
  }
}

const csrfToken = new CsrfToken();

export default csrfToken;
