import { useState, useEffect } from "react";

const useIntersectionObserver = (
  options: IntersectionObserverInit
): [(node: Element | null) => void, IntersectionObserverEntry | null] => {
  const [entry, setEntry] = useState<IntersectionObserverEntry | null>(null);
  const [node, setNode] = useState<Element | null>(null);

  useEffect(() => {
    if (node) {
      const observer = new IntersectionObserver(([entry]) => {
        setEntry(entry);
      }, options);

      observer.observe(node);

      return () => observer.disconnect();
    }
  }, [node, options]);

  return [setNode, entry];
};

export default useIntersectionObserver;
