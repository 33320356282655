import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

const Sweets: FC<IconCustom> = ({ onClick, cssClass, size = iconSizeDefault, hovered }) => {
  return (
    <svg
      onClick={onClick}
      className={cssClass}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      data-name="Layer 1"
      fill={hovered ? "black" : "var(--iconC)"}
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          d="M4,21a1,1,0,0,1-.707-1.707l7-7a.99985.99985,0,0,1,1.414,1.414l-7,7A.9967.9967,0,0,1,4,21Z"
          fill={hovered ? "#8799d6" : "var(--iconBC)"}
          stroke={hovered ? "#000000" : "var(--iconBC)"}
          strokeWidth={hovered ? 0 : 1}
        ></path>
        <path
          d="M21,11a7.9838,7.9838,0,0,1-.504,2.8008c-.30751.8227-.87291,1.1711-1.41791,1.8398a9.20609,9.20609,0,0,1-2.0763,2.2881,8.01916,8.01916,0,0,1-6.8555.5473c-.7127-.2722-1.0014-.9602-1.5994-1.4135a8.37683,8.37683,0,0,1-2.4585-2.0313,8.019,8.019,0,0,1-.5701-6.8699A6.37141,6.37141,0,0,1,6.9375,6.2344a9.424,9.424,0,0,1,2.036-2.1487,8.01876,8.01876,0,0,1,6.8802-.5617c.8599.3284,1.2552,1.0765,1.9432,1.6635.7097.6057,1.7056,1.0577,2.1685,1.8748A7.96353,7.96353,0,0,1,21,11Z"
          fill={hovered ? "#fa6d62" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconBC)"}
          strokeWidth={hovered ? 0 : 1}
          fillRule="evenodd"
        ></path>
        <path
          d="M14.1501,6.7859a4.82421,4.82421,0,0,1,2.0284-.6493,5.87459,5.87459,0,0,1,2.4753.3151,6.94181,6.94181,0,0,1,1.3051.6107,8.01629,8.01629,0,0,0-4.1612-3.5495,6.94284,6.94284,0,0,0-1.386.3695,5.87595,5.87595,0,0,0-2.0996,1.3485,4.82565,4.82565,0,0,0-1.1375,1.8005,3.23311,3.23311,0,0,0-.1499,1.7027,2.89223,2.89223,0,0,0,1.1851,1.9185A3.05607,3.05607,0,0,0,13,11a3.05569,3.05569,0,0,1-.3825-.7738,2.89158,2.89158,0,0,1,.3241-2.2316A3.23115,3.23115,0,0,1,14.1501,6.7859Z"
          fill={hovered ? "#ffe6ba" : "var(--iconBC)"}
        ></path>
        <path
          d="M13.0584,14.0054a3.23115,3.23115,0,0,1-1.2085,1.2087,4.82275,4.82275,0,0,1-2.0284.6492,5.87446,5.87446,0,0,1-2.4753-.315,6.93556,6.93556,0,0,1-1.3051-.6107,8.01657,8.01657,0,0,0,4.161,3.5494,6.936,6.936,0,0,0,1.3862-.3694,5.87615,5.87615,0,0,0,2.0996-1.3486,4.82532,4.82532,0,0,0,1.1375-1.8004,3.23318,3.23318,0,0,0,.1499-1.7027,2.892,2.892,0,0,0-1.1851-1.9185A3.05163,3.05163,0,0,0,13,11a3.05618,3.05618,0,0,1,.3825.7737A2.89185,2.89185,0,0,1,13.0584,14.0054Z"
          fill={hovered ? "#ffe6ba" : "var(--iconBC)"}
        ></path>
        <path
          d="M16.0054,10.9416a3.23115,3.23115,0,0,1,1.2087,1.2085,4.82421,4.82421,0,0,1,.6493,2.0284,5.87619,5.87619,0,0,1-.3151,2.4753,6.94181,6.94181,0,0,1-.6107,1.3051,8.01615,8.01615,0,0,0,3.54941-4.161,6.9431,6.9431,0,0,0-.36941-1.3862A5.87445,5.87445,0,0,0,18.769,10.3121a4.82452,4.82452,0,0,0-1.80041-1.1375,3.23311,3.23311,0,0,0-1.7027-.1499,2.89186,2.89186,0,0,0-1.9185,1.1851A3.04985,3.04985,0,0,0,13,11a3.056,3.056,0,0,1,.7737-.3825A2.89178,2.89178,0,0,1,16.0054,10.9416Z"
          fill={hovered ? "#ffe6ba" : "var(--iconBC)"}
        ></path>
        <path
          d="M9.9946,11.0583A3.23156,3.23156,0,0,1,8.7859,9.8499a4.82421,4.82421,0,0,1-.6493-2.0284,5.87619,5.87619,0,0,1,.3151-2.4753,6.93848,6.93848,0,0,1,.6107-1.3051A8.016,8.016,0,0,0,5.513,8.2021a6.94242,6.94242,0,0,0,.3694,1.3862A5.87478,5.87478,0,0,0,7.231,11.6878a4.822,4.822,0,0,0,1.8004,1.1375,3.23108,3.23108,0,0,0,1.7027.1499,2.8918,2.8918,0,0,0,1.9185-1.185A3.05177,3.05177,0,0,0,13,11a3.05431,3.05431,0,0,1-.7737.3824A2.89133,2.89133,0,0,1,9.9946,11.0583Z"
          fill={hovered ? "#ffe6ba" : "var(--iconBC)"}
        ></path>
      </g>
    </svg>
  );
};

export default memo(Sweets);
