// type getProductsEP = `/products/:category` | "products/search/store/category" | any;

import csrfToken from "../models/Tokens/CsrfToken";
import { urlBE } from "../utils/constants";
import { parseCookies } from "../utils/utils";
import { appEndPoints } from "./endPoints.types";

type ServerResponse = { data: any; error: any; description?: string; statusCode: number };

// const serverAPI = async (url: string, body: any, description?: string) => {
const serverAPI = async <T extends keyof appEndPoints>(
  url: T,
  body: appEndPoints[T]["body"],
  options?: { description?: string }
): Promise<ServerResponse> => {
  let BODY, method;
  if (body === "GET") {
    method = "GET";
    BODY = undefined;
  } else {
    method = "POST";
    BODY = JSON.stringify(body);
  }
  // console.log(process.env.NODE_ENV, "process env");
  try {
    const headers: any = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${parseCookies("uDwAs.ev.dd") || ""}`,
    };

    if (csrfToken.getToken()) {
      headers.csrfToken = csrfToken.getToken();
    }

    const response = await fetch(urlBE + url, {
      method,
      credentials: "include",
      // @ts-ignore
      headers,
      body: BODY,
    });

    const jsonData = await response.json();

    if (!response.ok) {
      if (response.status === 401) {
        alert("אין הרשאה");
        return jsonData;
      } else if (response.status === 403 && jsonData.reason === "warning") {
        alert("פג תוקף העמוד , נסו לרענן");
        return jsonData;
      }
      const { message, error } = jsonData;
      if (error) throw new Error(error);
      else if (message) throw new Error(message);
    }

    return { data: jsonData, error: null, description: options?.description, statusCode: response.status };
  } catch (error: any) {
    if (error instanceof Response) {
      // For fetch API errors
      console.log("Error status:", error.status);
      console.log("Error message:", error.statusText);
      // Handle the error accordingly
      // return { data: null, error: null, description: options?.description, statusCode: error.status };
    } else {
      // For other types of errors
      if (error.message === "Failed to fetch") alert("השרת סגור  כעת לצורך תחזוקה ");
    }
    return { data: null, error: error, description: options?.description, statusCode: 500 };
  }
};

export default serverAPI;
