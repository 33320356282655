import { ReactNode, useEffect, useRef, useState } from "react";
import "./SelectSearchUI.css";
import { Language } from "../../../../types/types";

export type Option = {
  value: string;
  label: string;
  data?: any;
};

type SelectProps = {
  placeholder?: string;
  options: Option[];
  onChange: (selectedOptions: Option[]) => void;
  htmlFor?: string;
  label?: string;
  language?: Language;
  disableLabelInInput?: boolean;
  values?: Option[];
  isMulti?: boolean;
  icon?: ReactNode;
  dropDownPosition?: "absolute" | "sticky";
  keepValuesOnRemove?: string[];
  required?: boolean;
};

const SelectSearchUI = ({
  options,
  onChange,
  placeholder,
  htmlFor = "",
  label = "",
  language = "hebrew",
  disableLabelInInput = false,
  values,
  icon,
  isMulti = false,
  dropDownPosition = "absolute",
  keepValuesOnRemove,
  required,
}: SelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<Option[]>(values ? values : []);
  const [searchText, setSearchText] = useState(disableLabelInInput && !isMulti && values ? values[0].label : "");
  const [openDropDown, setOpenDropDown] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: Option) => {
    if (!isMulti) {
      setSelectedOptions([option]);
      setSearchText(option.label);
      onChange([option]);
      return;
    }
    const optionIndex = selectedOptions.findIndex((selectedOption) => selectedOption.value === option.value);

    if (optionIndex === -1) {
      onChange([...selectedOptions, option]);
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const filteredOptions = selectedOptions.filter((options) => options.value !== option.value);
      onChange(filteredOptions);
      setSelectedOptions(filteredOptions);
    }
  };

  const handleRemoveOption = (option: Option) => {
    // if (keepValuesOnRemove && keepValuesOnRemove.includes(option.value)) return;
    const updatedSelectedOptions = selectedOptions.filter((selectedOption) => selectedOption.value !== option.value);
    setSelectedOptions(updatedSelectedOptions);
    onChange(updatedSelectedOptions);
  };

  const handleRemoveAll = () => {
    if (keepValuesOnRemove) {
      setSelectedOptions((prev) => {
        return prev.filter((o) => keepValuesOnRemove.includes(o.value));
      });

      const updatedSelectedOptions = selectedOptions.filter((selectedOption) =>
        keepValuesOnRemove.includes(selectedOption.value)
      );
      onChange(updatedSelectedOptions);
    } else {
      setSelectedOptions([]);
      onChange([]);
    }

    if (!isMulti) setSearchText("");
  };

  const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(searchText.toLowerCase()));

  useEffect(() => {
    if (values?.length && values[0].value) {
      setSelectedOptions(values);
    }
  }, [values]);

  const handleOnFocus = () => {
    setOpenDropDown(true);
  };

  const handleOnBlur = (e: any) => {
    if (ref.current && !ref.current.contains(e.relatedTarget as Node)) {
      setOpenDropDown(false);
    }
  };

  return (
    <div ref={ref} className="dynamic-select" onBlur={handleOnBlur} onFocus={handleOnFocus} tabIndex={0}>
      <div className={`selected-options ${openDropDown ? "selected-options-open" : ""}`}>
        {!disableLabelInInput &&
          selectedOptions.map((option) => (
            <div key={option.value} className="selected-option scrollable">
              {(!keepValuesOnRemove || !keepValuesOnRemove.includes(option.value)) && (
                <div className="remove-option" onClick={() => handleRemoveOption(option)}>
                  &#10005;
                </div>
              )}
              <h6>{option.label}</h6>
            </div>
          ))}
        <input
          type="text"
          className="search-input"
          placeholder={placeholder}
          value={searchText}
          required={required}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <label className={`pos-${language} dir-${language}`} htmlFor={htmlFor}>
          {label}
        </label>
        {!icon &&
          selectedOptions.length > 0 &&
          (!keepValuesOnRemove || selectedOptions.length > keepValuesOnRemove.length) && (
            <div onClick={handleRemoveAll} className="remove-all">
              &#10005;
            </div>
          )}
        {icon && (
          <span className={`${!["hebrew", "arabic"].includes(language) ? "rtl-icon" : "ltr-icon"}`}>{icon}</span>
        )}
      </div>
      {openDropDown && (
        <div className="option-list scrollable" style={{ position: dropDownPosition }}>
          {filteredOptions.map((option) => {
            const isChecked = selectedOptions.find((o) => o.value === option.value);

            return (
              <div
                key={option.value}
                className={`option ${isChecked ? "selected" : ""}`}
                onClick={() => handleOptionClick(option)}
              >
                {option.label}
                {isChecked && <span className="checkmark">&#10003;</span>}
              </div>
            );
          })}
          {filteredOptions.length === 0 && <div className="no-results">No results found</div>}
        </div>
      )}
    </div>
  );
};

export default SelectSearchUI;
