import { FC } from "react";
import { IconCustom } from "./types";

interface Props extends IconCustom {
  sign?: "plus" | "minus";
}

const PlusOrMinusIcon: FC<Props> = ({ color = "blue", size = "1rem", sign = "plus", clickable, onClick }) => {
  return (
    <svg
      onClick={() => onClick && onClick()}
      className={clickable ? "clickable-icon" : ""}
      fill={color}
      width={typeof size === "number" ? `${size}px` : size}
      height={typeof size === "number" ? `${size}px` : size}
      viewBox="0 0 20 20"
    >
      {sign === "plus" && <line x1="10" y1="2" x2="10" y2="18" stroke={color} strokeWidth="2" />}
      <line x1="2" y1="10" x2="18" y2="10" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default PlusOrMinusIcon;
