import { FC, useMemo } from "react";
import { Cart, OnlineStoresType, StoreEn, User } from "../../../types/types";
import { checkoutDictionaryDisclaimer } from "../Dictonary/checkoutDictonary";
import { dictStores } from "../../../dictionary/dictStores";
import "./CheckoutDisclaimer.css";

type Props = {
  user: User;
  cart: Cart;
  onlineStores: OnlineStoresType;
};

const CheckoutDisclaimer: FC<Props> = ({ user, cart, onlineStores }) => {
  const { language } = user;

  const favoriteStore = dictStores[onlineStores.favoriteStore][language] as StoreEn;
  const otherStores = onlineStores.otherStores.map((s) => dictStores[s][language]) as StoreEn[];

  const cartPrice = useMemo(() => {
    // const prices = new CartPrice(cart, [favoriteStore, ...otherStores], []);
    const selectedPrice = 500;
    const favoritePrice = 600;
    const fee = 0.1; //"10%"
    const commission = selectedPrice > favoritePrice ? (favoritePrice - selectedPrice) * fee : 0;

    return { range: "20-20", favoritePrice: 200, commission };
  }, []);

  return (
    <div className="checkout-text-wrapper">
      <p>{checkoutDictionaryDisclaimer.p1.translate(favoriteStore, otherStores, cartPrice.range)[language]}</p>
      <p>{checkoutDictionaryDisclaimer.p2.translate(favoriteStore, cartPrice.favoritePrice)[language]}</p>
      <div className="inner-paragraph-wrapper">
        <p> {checkoutDictionaryDisclaimer.p3.translate(favoriteStore, otherStores)[language]} </p>
        <p>{checkoutDictionaryDisclaimer.p4.translate(favoriteStore, otherStores)[language]} </p>
        <p> {checkoutDictionaryDisclaimer.p5.translate(otherStores)[language]} </p>
      </div>
    </div>
  );
};

export default CheckoutDisclaimer;
