import { FC } from "react";
import "./SpinnerA.css";
import Typography from "../../Typography/Typography";

type Props = {
  size: number;
  margin?: string;
  text?: string;
};

const SpinnerA: FC<Props> = ({ size, margin, text }) => {
  const spinnerStyle = {
    width: size,
    height: size,
    border: `${size / 10}px solid rgba(0, 0, 0, 0.1)`,
    borderTopColor: "#ffffff",
    borderRadius: "50%",
  };

  return (
    <div className="spinner-container" style={{ margin: margin || undefined }}>
      <div className="spinner" style={spinnerStyle}></div>
      {text && <Typography.Text>{text}</Typography.Text>}
    </div>
  );
};

export default SpinnerA;
