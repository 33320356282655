import { CSSProperties, useCallback, useEffect, useState } from "react";
import serverAPI from "../../../api/server";
import CategoryPricesChart, { CategoryPricesType } from "./CategoryPricesChart/CategoryPricesChart";
import { screenSize } from "../../../utils/constants";
import { CarouselFull, Modal, SkeletonScreen, SkeletonStyle, Typography } from "../../../components/UI";
import CategoriesMap from "../../../assets/Data/categories";
import { Language } from "../../../types/types";
import { InfoIcon } from "../../../components/UI/Icons";
import PriceChartInfo from "./PriceChartInfo/PriceChartInfo";

const style: CSSProperties = {
  width: "100%",
};

interface ICategoriesPricesChartsCarouselProps {
  title: string;
  showIcons: boolean;
  language: Language;
}

const CategoriesPricesChartsCarousel = ({ title, showIcons, language }: ICategoriesPricesChartsCarouselProps) => {
  const [chartsData, setChartsData] = useState<CategoryPricesType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await serverAPI("products/prices/all", "GET");

      if (res?.data) setChartsData(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const drawCharts = () => {
    return chartsData
      .filter((cat) => cat.count > 0)
      .map((category, idx) => {
        category.category_label = CategoriesMap[category.category_value].category[language];
        return <CategoryPricesChart showIcons={showIcons} key={idx} data={category} />;
      });
  };

  const drawChartSkeletonLoader = useCallback(() => {
    return [1, 2, 3, 4].map((i) => <ChartSkeleton key={i} />);
  }, []);

  return (
    <>
      <div className="flex-align">
        <InfoIcon color="var(--primary-color)" size="20px" clickable onClick={() => setInfoModalOpen(true)} />

        <div className="transparent">x</div>
        <Typography.Text size={20} type="secondary">
          {title}
        </Typography.Text>
      </div>

      <div style={style}>
        <CarouselFull infinite slideToShow={window.innerWidth < screenSize.sm ? 1 : 7} gap={5}>
          {isLoading ? drawChartSkeletonLoader() : drawCharts()}
        </CarouselFull>
      </div>
      {infoModalOpen && (
        <Modal
          withFooter={false}
          title="השוואת קטגוריות"
          onCancel={() => setInfoModalOpen(false)}
          visible={infoModalOpen}
          dir="rtl"
          withLogo={false}
          style={{ maxWidth: 500 }}
          // width={350}
          language={language}
        >
          <PriceChartInfo language={language} />
        </Modal>
      )}
    </>
  );
};

export default CategoriesPricesChartsCarousel;

// #region skeleton
const chartCardSkeletonArr: SkeletonStyle[] = [
  { height: "90%", width: "50%" },
  { height: "90%", width: "100%" },
];

const ChartSkeleton = () => {
  return (
    <SkeletonScreen
      SkeletonStyle={chartCardSkeletonArr}
      numOfElements={1}
      cssClass="chart_card_container"
      skeletonCardWrapperStyle={{ flexDirection: "row" }}
    />
  );
};

//#endregion
