import { useEffect, useMemo, useRef, useState } from "react";
import serverAPI from "../../api/server";
import { Divider, SideBar, SkeletonScreen } from "../UI";
import { useLocation } from "react-router-dom";
import { getTranslatedCategory } from "../../utils/utils";
import { ProductDataType } from "../../types/types";
import drawProducts from "../../pages/Products/Shared/DrawProducts";
import Cart from "../Cart/Cart";
import ScrollTopBtn from "../UI/ScrollTopBtn/ScrollTopBtn";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import OnlineStoresMapper from "../../hooks/useOnlineStores";

/**
 * Similar to products per category but is live on his own route /product/list
 * Fetching data directly according to the shopping list api and the site version context.
 *
 * * Supported just in Hebrew for now
 *
 * version changes only in 2 places here:
 *  a. v1 using fetchData function where v2 using fetchDataV2
 *
 *
 * Flow :
 * 1. we know the data of the user from context
 * 2. creating the relevant states
 * 3. getting the state that came from the ShoppingList with useNavigation through the state into useLocation here
 * 4. save fetched data categories in order to introduce the page per category , memo the fetch data categories and translate them
 * 5.
 * */

const ProductShoppingList = () => {
  const {
    user: { language },
    onlineStores: { favoriteStore, otherStores },
    siteVersion,
    isCartOpen,
  } = useCustomContext();
  const ref = useRef<HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [productsAuto, setProductsAuto] = useState<ProductDataType[]>([]);
  const [categories, setCategories] = useState([""]);

  const {
    state: { inputs },
  } = useLocation();

  const categoryDict = useMemo(() => {
    return getTranslatedCategory(language);
  }, [language]);

  const updateCategories = (products: ProductDataType[]) => {
    const categoriesSet: Set<string> = new Set();
    products.forEach((item) => {
      categoriesSet.add(item[favoriteStore].sub_category);
    });
    setCategories([...categoriesSet]);
  };

  useEffect(() => {
    console.log(categories);
  }, [categories]);

  // TODO : HTTP TO HTTPS
  // CONTINU WITH TITLES PER CATEGORY HERE
  // TODO : INPUTS
  // inputs need fixed to use stringfy or somthing due to using ', " " , ` , it brake the strings

  // * Version 1
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const res: { data: ProductDataType[] } = await serverAPI("products/common/get", {
        favoriteStore: OnlineStoresMapper.convertOnlineStoreToObj(favoriteStore),
        otherStores: OnlineStoresMapper.convertOnlineStoresToArrOfObj(otherStores),
        ilikeValue: inputs,
        similarityScore: 0.4,
        joinByItemCode: true,
        joinBySimilarity: true,
      });
      updateCategories(res.data);
      setProductsAuto(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // *  Version 2
  const fetchDataV2 = async () => {
    try {
      setIsLoading(true);
      const res: { data: ProductDataType[] } = await serverAPI("products/common/v2/get", {
        store: OnlineStoresMapper.convertOnlineStoreToObj(favoriteStore),
        searchValues: inputs,
        mCode: 2, //2 is sim when 1 is reg
      });

      updateCategories(res.data);
      setProductsAuto(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    siteVersion === 2 ? fetchDataV2() : fetchData();
    // eslint-disable-next-line
  }, [inputs]);

  const drawProductsPerCategory = () => {
    return categories.map((cat) => {
      const products = productsAuto.filter((p) => p[favoriteStore].sub_category === cat);
      return (
        <div key={cat}>
          <Divider margin="35px 0" isTitle text={categoryDict[cat]} />
          <div className="product-main-container" style={{ height: "fit-content" }}>
            {drawProducts(favoriteStore, products, "auto", language, siteVersion)}
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <div
          ref={ref}
          style={{ height: "98vh", overflow: "auto", flex: 1 }}
          className={`${isCartOpen ? "responsive-hidden" : ""}`}
        >
          <Divider
            isLine={false}
            alignment="center"
            fontSize="3.4rem"
            margin="25px 0"
            text={"הרשימה שלי"}
            isTitle
            color="var(--primary-color)"
          />
          {isLoading ? <SkeletonScreen cssClass="product_card_wrapper_vvv" /> : drawProductsPerCategory()}
        </div>
        <ScrollTopBtn targetElementRef={ref} />

        <SideBar isOpen={isCartOpen}>
          <Cart />
        </SideBar>
      </div>
    </>
  );
};

export default ProductShoppingList;
