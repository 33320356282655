import React, { FC } from "react";
import { IconCustom } from "../types";

const CartComparisonIcon: FC<IconCustom> = ({ cssClass, onClick, size, hovered, color = "#5edf07" }) => {
  return (
    <svg
      className={cssClass}
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 100 80"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <rect x="0" y="0" width="100" height="15" />

      <rect x="1" y="20" width="20" height="5" stroke={color} />
      <rect x="1" y="25" width="20" height="45" stroke={color} strokeWidth={1} fill="transparent" />
      {/* horizontal lines */}
      <line x1="1" y1="30" x2="21" y2="30" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="35" x2="21" y2="35" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="40" x2="21" y2="40" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="45" x2="21" y2="45" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="50" x2="21" y2="50" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="55" x2="21" y2="55" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="60" x2="21" y2="60" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="65" x2="21" y2="65" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="70" x2="21" y2="70" stroke={color} strokeWidth={0.5} />
      <rect x="1" y="70" width="20" height="5" stroke={color} />

      <rect x="26" y="20" width="20" height="5" stroke={color} />
      <rect x="26" y="25" width="20" height="45" stroke={color} strokeWidth={1} fill="transparent" />
      {/* horizontal lines */}
      <line x1="26" y1="30" x2="46" y2="30" stroke={color} strokeWidth={0.5} />
      <line x1="26" y1="35" x2="46" y2="35" stroke={color} strokeWidth={0.5} />
      <line x1="26" y1="40" x2="46" y2="40" stroke={color} strokeWidth={0.5} />
      <line x1="26" y1="45" x2="46" y2="45" stroke={color} strokeWidth={0.5} />
      <line x1="26" y1="50" x2="46" y2="50" stroke={color} strokeWidth={0.5} />
      <line x1="26" y1="55" x2="46" y2="55" stroke={color} strokeWidth={0.5} />
      <line x1="26" y1="60" x2="46" y2="60" stroke={color} strokeWidth={0.5} />
      <line x1="26" y1="65" x2="46" y2="65" stroke={color} strokeWidth={0.5} />
      <line x1="26" y1="70" x2="46" y2="70" stroke={color} strokeWidth={0.5} />
      <rect x="26" y="70" width="20" height="5" stroke={color} />

      <rect x="52" y="20" width="20" height="5" stroke={color} />
      <rect x="52" y="25" width="20" height="45" stroke={color} strokeWidth={1} fill="transparent" />
      {/* horizontal lines */}
      <line x1="52" y1="30" x2="71" y2="30" stroke={color} strokeWidth={0.5} />
      <line x1="52" y1="35" x2="71" y2="35" stroke={color} strokeWidth={0.5} />
      <line x1="52" y1="40" x2="71" y2="40" stroke={color} strokeWidth={0.5} />
      <line x1="52" y1="45" x2="71" y2="45" stroke={color} strokeWidth={0.5} />
      <line x1="52" y1="50" x2="71" y2="50" stroke={color} strokeWidth={0.5} />
      <line x1="52" y1="55" x2="71" y2="55" stroke={color} strokeWidth={0.5} />
      <line x1="52" y1="60" x2="71" y2="60" stroke={color} strokeWidth={0.5} />
      <line x1="52" y1="65" x2="71" y2="65" stroke={color} strokeWidth={0.5} />
      <line x1="52" y1="70" x2="71" y2="70" stroke={color} strokeWidth={0.5} />
      <rect x="52" y="70" width="20" height="5" stroke={color} />

      <rect x="77" y="20" width="20" height="5" stroke={color} />
      <rect x="77" y="25" width="20" height="45" stroke={color} strokeWidth={1} fill="transparent" />

      <line x1="77" y1="30" x2="97" y2="30" stroke={color} strokeWidth={0.5} />
      <line x1="77" y1="35" x2="97" y2="35" stroke={color} strokeWidth={0.5} />
      <line x1="77" y1="40" x2="97" y2="40" stroke={color} strokeWidth={0.5} />
      <line x1="77" y1="45" x2="97" y2="45" stroke={color} strokeWidth={0.5} />
      <line x1="77" y1="50" x2="97" y2="50" stroke={color} strokeWidth={0.5} />
      <line x1="77" y1="55" x2="97" y2="55" stroke={color} strokeWidth={0.5} />
      <line x1="77" y1="60" x2="97" y2="60" stroke={color} strokeWidth={0.5} />
      <line x1="77" y1="65" x2="97" y2="65" stroke={color} strokeWidth={0.5} />
      <line x1="77" y1="70" x2="97" y2="70" stroke={color} strokeWidth={0.5} />
      <rect x="77" y="70" width="20" height="5" stroke={color} />
    </svg>
  );
};

export default CartComparisonIcon;
