import { useEffect, useState } from "react";
import { StoreEn } from "../../../../types/types";
import "./CategoryPricesChart.css";
import { Bar, BarChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { dictStores } from "../../../../dictionary/dictStores";
import { Card, Tooltip as TooltipUI } from "../../../../components/UI";
import DepartmentsIcons from "../../../../components/UI/Icons/DepartmentsIcons/DepartmentsIcons";
type ChartDataRecord = {
  real: number;
  prices: number;
  promotions: number;
  store: StoreEn;
  displayStore?: string;
};

export type CategoryPricesType = {
  category_value: string;
  category_label?: string;
  lvl: number;
  count: number;
  result: ChartDataRecord[];
};

interface IPropsCategoryPricesChart {
  data: CategoryPricesType;
  showIcons: boolean;
}

const barColors = [
  ["#5edf07", "#6eff08"],
  ["#4ebf06", "#5edf07"],
  ["#3e9f05", "#4ebf06"],
  ["#2e7f04", "#3e9f05"],
  ["#1e5f03", "#2e7f04"],
  ["#0e3f02", "#1e5f03"],
];

const CategoryPricesChart = ({ data, showIcons }: IPropsCategoryPricesChart) => {
  const [chartData, setChartData] = useState(data);

  const sortingData = (a: ChartDataRecord, b: ChartDataRecord) => {
    return a.real - b.real;
  };

  useEffect(() => {
    setChartData((prev) => {
      return {
        ...prev,
        result: prev.result
          .map((r) => ({ ...r, displayStore: dictStores[r.store].hebrew, real: r.real - r.promotions }))
          .sort(sortingData),
        // .slice(0, 3),
      };
    });
  }, []);

  const drawIcon = () => {
    const Icon = DepartmentsIcons[data.category_value as keyof typeof DepartmentsIcons];
    if (data.category_value === "Dairy  & Eggs") {
      return <Icon both={false} size="70px" hovered />;
    }
    return <Icon size="80px" hovered />;
  };

  const drawInfoContent = () => {
    const { count } = data;

    return (
      <div className="tooltip-content">
        <p>
          <span>כמות הפריטים שנבחנו היום:</span>
          <span> {count} </span>
        </p>
      </div>
    );
  };
  return (
    <Card title={<div className="primary-contrast-color">{data.category_label}</div>}>
      <div className="card-subtitle chart-card-recommended-store-tooltip-wrapper">
        <TooltipUI title={drawInfoContent()} placement="bottom">
          <>
            <span>החנות המומלצת</span>
            <span>:</span>
            <span>&nbsp;</span>
            <span>{dictStores[chartData.result[0].store as StoreEn].hebrew}</span>
          </>
        </TooltipUI>
      </div>
      <div className="chart_card_body-chart">
        <ResponsiveContainer width={"100%"} height={"80%"} debounce={3}>
          <BarChart title={data.category_label} data={chartData.result}>
            <YAxis type="number" hide={true} />
            <XAxis type="category" dataKey="displayStore" hide={true} />
            <Tooltip />
            <defs>
              {barColors.map((color, index) => (
                <linearGradient
                  key={index}
                  id={`colorUv${index}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="100%"
                  spreadMethod="reflect"
                >
                  <stop offset="0" stopColor={color[0]} />
                  <stop offset="1" stopColor={color[1]} />
                </linearGradient>
              ))}
            </defs>
            <Bar unit={` ש"ח `} name={"מחיר כולל מבצעים"} dataKey="real" radius={4}>
              {chartData.result.map((_, index) => (
                <Cell key={`cell-${index}`} fill={`url(#colorUv${index})`} name={"x"} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
        {showIcons && drawIcon()}
      </div>
    </Card>
  );
};

export default CategoryPricesChart;
