import { FC, useState } from "react";
import "./HamburgerButton.css";

const HamburgerButton: FC = () => {
  const [isActive, setIsActive] = useState(false);

  const toggleButton = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`hamburger-button ${isActive ? "active" : ""}`} onClick={toggleButton}>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
};

export default HamburgerButton;
