import { CartComparisonIcon, FillCartIcon, ListIcon, LunchCartToStoreIcon } from "../../../components/UI/Icons";

export const howItsWorkData = [
  {
    text: {
      hebrew: "בחרו מוצרים ומלאו את העגלה שלכם",
      english: "Select products and fill your cart",
      arab: "",
      russian: "",
    },
    icon: FillCartIcon,
  },
  {
    text: {
      hebrew: "עברו להשוואת עגלות ובחרו את העגלה המתאימה ביותר עבורכם",
      english: "Compare selected cart from other stores",
      arab: "",
      russian: "",
    },
    icon: CartComparisonIcon,
  },
  {
    text: {
      hebrew: "שגרו את פריטי העגלה הנבחרת לאתר החנות הנבחרת",
      english: "Select your desired cart and lunch product to selected store ",
      arab: "",
      russian: "",
    },
    icon: LunchCartToStoreIcon,
  },
  {
    text: {
      hebrew: "עברו לאתר של החנות הנבחרת , המוצרים שבחרתם כאן יחכו לכם שם",
      english: "Go to your selected store site , your products will be there for you",
      arab: "",
      russian: "",
    },
    icon: ListIcon,
  },
];

export const howItWorkDict = {
  title: {
    hebrew: "איך זה עובד?",
    english: "How its work?",
  },
};
