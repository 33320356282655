import { Dispatch, SetStateAction } from "react";
import serverAPI from "../../../api/server";
import { CartSource, ProductDataType } from "../../../types/types";
import { fromBErawDataToFE } from "../ProductsV2/fetch_products_v2";
import { UsersProductsEP } from "../../../api/features/usersProducts.ep.types";
import { ProductsEP } from "../../../api/features/products.ep.types";

type UserRequestBody = UsersProductsEP["usersProducts/get/user"]["body"];
export type AutoRequestBody = ProductsEP["products/common/get"]["body"];

type Args<T extends CartSource> = {
  fetchSourceType: T;
  requestBody: T extends "auto" ? AutoRequestBody : UserRequestBody;
  setIsLoading: Dispatch<SetStateAction<{ auto: boolean; user: boolean; autoFinish: boolean }>>;
  setProducts: Dispatch<SetStateAction<ProductDataType[]>>;
};

const getUrlPerSource = (fetchSourceType: CartSource) => {
  switch (fetchSourceType) {
    case "auto":
      return `products/common/get`;
    case "user":
      return `usersProducts/get/user`;

    default:
      throw new Error("Not Selected Source Of DB - products main v1");
  }
};

const fetchProductsV1 = async <T extends CartSource>({
  fetchSourceType,
  requestBody,
  setIsLoading,
  setProducts,
}: Args<T>) => {
  try {
    setIsLoading((p) => ({ ...p, [fetchSourceType]: true }));
    const url = getUrlPerSource(fetchSourceType);
    const { data, statusCode } = await serverAPI(url, requestBody as any, { description: "success" });

    if (statusCode === 200 && data.length) {
      setProducts(fromBErawDataToFE(data));
    } else {
      // TODO : create popup that explain
      setProducts([]);
    }

    // todo if === 401 popup login
  } catch (e: any) {
    console.log(e);
  } finally {
    setIsLoading((p) => ({ ...p, [fetchSourceType]: false, autoFinish: true }));
  }
};

export default fetchProductsV1;
