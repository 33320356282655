import CartModel from "../../../models/Cart/CartModel";
import PriceCalculator from "../../../models/ItemPriceCalculator/PriceCalculator";
import { Cart, StoreEn } from "../../../types/types";

/**
 Return cart price of other store (normalizing store) according favorite by removing items that not exist on favorite
 * 
*/
export const getNormalizingStorePrice = (
  normalizingStore: StoreEn,
  favoriteStore: StoreEn,
  cart: Cart,
  hasClubCard: StoreEn[],
  type?: "string" | "number"
) => {
  // removing products that not exist in normalizingStore
  const fakeFavoriteCart: Cart = CartModel.normalizeCartAccordingOtherStore(cart, normalizingStore);
  const fakeCartTotalPrice = PriceCalculator.getTotalCartCost(favoriteStore, fakeFavoriteCart);
  if (type === "number") return fakeCartTotalPrice;
  return fakeCartTotalPrice.toFixed(2);
};

/**
 Get the missing items on current store compare to favorite store
 * */
export const getMissingItems = (cart: Cart, favoriteStore: StoreEn, currentStore: StoreEn) => {
  let missing: string[] = [];
  Object.keys(cart).forEach((item) => {
    if (!cart[item].product[currentStore]) {
      missing.push(cart[item].product[favoriteStore].item_name);
    }
  });

  return missing;
};
