export const storePreferenceDict = {
  howItWorkTitle: {
    english: "How to select?",
    hebrew: "שימו לב",
    arabic: "",
    russian: "",
  },
  howItWork: {
    english: "",
    hebrew:
      "אתם מגדירים את החנות הראשית ממנה אתם רוכשים בדרך כלל , סמנו חנות זאת כחנות ראשית. במקביל , בחרו מספר חנויות נוספות אליהם תרצו להשוות מחירים. שימו לב שהחנויות שאתם בוחרים אכן עושים משלוחים לאזור מגוריכם . תוכלו לשנות מעת לעת את בחירתכם , אך שימו לב , כי שינוי חנויות תרוקן את עגלת הקניות הנוכחית שלכם , לכן אם ברצונכם להחליף חנויות , עשו זאת לפני שהתחלתם בקנייה. במידה ויש לכם כרטיס מועדון בחנות כלשהי סמנו זאת ואנו נחשב לכם את המחירים המוצעים בהתאם לפרופיל המועדון שלכם.",
    arabic: "",
    russian: "",
  },

  storesAndLocation: {
    english: "",
    hebrew: "",
    arabic: "",
    russian: "",
  },
  mySelectedStores: {},
};
