import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { Button, Typography } from "../../UI";
import Dropdown from "../../UI/Dropdown/Dropdown";

const UISettings = () => {
  const { theme, setTheme } = useCustomContext();
  return (
    <div>
      <h2>UISettings</h2>
      <div>font size - adjust </div>
      <div style={{ background: "red" }}>
        change theme contrast current theme -{" "}
        <Button onClick={() => setTheme((p) => (p === " theme-dark" ? "" : " theme-dark"))}>{theme}</Button>
      </div>
      <div>change nav view</div>
      <Typography.Paragraph>
        Lorem ipsum dolor, sit amet consectetur adipisicing elit. Facilis eaque eveniet explicabo enim. Officiis,
        adipisci quisquam et ut expedita laudantium illo dolores animi nisi obcaecati modi alias rem facere vel.
      </Typography.Paragraph>

      <Typography.Title level={1}>h1</Typography.Title>
      <Typography.Title level={2}>h2</Typography.Title>
      <Typography.Title level={3}>h3</Typography.Title>
      <Typography.Title level={4}>h4</Typography.Title>
      <Typography.Title level={5}>h5</Typography.Title>
      <Typography.Title level={6}>h6</Typography.Title>

      <Typography.Text variant="p">This is text</Typography.Text>
      <Typography.Text variant="p" strong>
        This is text
      </Typography.Text>

      <Dropdown title="Fruits" items={["banana", "orange", "somthing"]} />
    </div>
  );
};

export default UISettings;
