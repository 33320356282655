import { Dispatch, FC, SetStateAction, useState } from "react";
import { Cart, Language, StoreEn } from "../../../../../types/types";
import { dictStores } from "../../../../../dictionary/dictStores";
import { Modal } from "../../../../UI";
import "./loginToStore.css";
import InputUI from "../../../../UI/Input/Input";
import { dictAuth } from "../../../../Authentication/dictionary";
import { dictButtonsAndActions } from "../../../../../dictionary/dictButtons";
import { EmailIcon, PasswordIcon } from "../../../../UI/Icons";
import serverAPI from "../../../../../api/server";

type Props = {
  store: StoreEn;
  isOpen: boolean;
  language: Language;
  cart: Cart;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const dict = {
  title: {
    hebrew: (store: StoreEn) => `התחברות ל${dictStores[store].hebrew}`,
    english: (store: StoreEn) => `Login To ${dictStores[store].english}`,
    russian: (store: StoreEn) => `Вход в ${dictStores[store].english}`,
    arabic: (store: StoreEn) => `تسجيل الدخول إلى ${dictStores[store].english}`,
  },
  description: {
    hebrew: (store: StoreEn, language: Language) => {
      return (
        <>
          <p>
            ברצוננו לחסוך לך זמן ולהעתיק את תוכן העגלה אל עגלת החנות שבחרת. אנחנו זקוקים רק לפרטי הכניסה שלך לחשבון{" "}
            {dictStores[store][language]}. <b>אנחנו לא שומרים את הפרטים שלך בשום מקום</b>; אנחנו פשוט ממלאים את העגלה
            עבורך.
          </p>
          <p>
            <b>שימו לב:</b> הפעולה הזו זמינה רק לחנות אחת. אם תבחרו להמשיך, זה עשוי לקחת זמן וירוקן את העגלה הנוכחית
            שלך.
          </p>
        </>
      );
    },
    english: (store: StoreEn, language: Language) => {
      return (
        <>
          <>
            <p>
              We want to save you time by copying the cart content to your selected store's cart. We only need your{" "}
              {dictStores[store][language]} credentials to log in to your account.{" "}
              <b>We do not store your credentials anywhere</b>; we simply fill the cart for you.
            </p>
            <p>
              <b>Pay attention:</b> This operation is only available for one store. If you choose to proceed, it may
              take some time and will empty your current cart.
            </p>
          </>
        </>
      );
    },
    russian: (store: StoreEn, language: Language) => {
      return (
        <>
          <p>
            Мы хотим сэкономить ваше время, скопировав содержимое корзины в корзину выбранного вами магазина. Нам нужны
            только ваши учетные данные {dictStores[store][language]}, чтобы войти в ваш аккаунт.{" "}
            <b>Мы не сохраняем ваши учетные данные нигде</b>; мы просто заполняем корзину для вас.
          </p>
          <p>
            <b>Обратите внимание:</b> Эта операция доступна только для одного магазина. Если вы решите продолжить, это
            может занять некоторое время и приведет к очистке вашей текущей корзины.
          </p>
        </>
      );
    },
    arabic: (store: StoreEn, language: Language) => {
      return (
        <>
          <p>
            نرغب في توفير وقتك عن طريق نسخ محتوى السلة إلى سلة المتجر الذي اخترته. نحتاج فقط إلى بيانات تسجيل الدخول
            الخاصة بك في متجر {dictStores[store][language]} لتسجيل الدخول إلى حسابك.{" "}
            <b>نحن لا نقوم بتخزين بيانات الدخول الخاصة بك في أي مكان</b>؛ نقوم ببساطة بملأ السلة بالنسبة لك.
          </p>
          <p>
            <b>يرجى الملاحظة:</b> هذه العملية متاحة فقط لمتجر واحد. إذا اخترت المتابعة، قد يستغرق الأمر وقتًا وسيتم
            تفريغ سلة التسوق الحالية.
          </p>
        </>
      );
    },
  },
};

const LoginToStoreModal: FC<Props> = ({ store, isOpen = false, language, cart, setIsOpen }) => {
  const [credentials, setCredentials] = useState({ email: "", password: "" });
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const handleTransferCart = async () => {
    try {
      setIsLoading(true);
      const cartValues = Object.values(cart)
        .filter((p) => p.product[store])
        .map((p) => {
          const id = parseInt(p.product[store].retailer_product_id);
          const type = p.product[store].item_type;
          const quantity = p.amount;
          return { id, quantity, type, soldBy: null };
        });

      const res = await serverAPI("usersProducts/cart/copy", { store, lines: cartValues, credentials });

      console.log(res);
    } catch (error: any) {
      setMessage(error.message);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = e.target;
    setCredentials((prev) => ({ ...prev, [id]: value }));
  };

  return (
    <Modal
      loading={isLoading}
      language={language}
      visible={isOpen}
      onCancel={() => setIsOpen(false)}
      onOk={handleTransferCart}
      title={<h2>{dict.title[language](store)}</h2>}
      okText={dictButtonsAndActions.ok[language]}
      cancelText={dictButtonsAndActions.cancel[language]}
    >
      <div className="login_to_store_wrapper">
        {dict.description[language](store, language)}
        <InputUI
          label={dictAuth.email[language]}
          type="email"
          id="email"
          htmlFor="email"
          required
          icon={<EmailIcon size={"50%"} color="var(--primary)" />}
          onChange={handleInputChange}
          value={credentials.email}
        />
        <InputUI
          value={credentials.password}
          onChange={handleInputChange}
          label={dictAuth.password[language]}
          type="password"
          id="password"
          htmlFor="password"
          required
          icon={<PasswordIcon size={"55%"} color="var(--primary)" />}
        />
        <p>{message}</p>
      </div>
    </Modal>
  );
};

export default LoginToStoreModal;
