import { CSSProperties, FC } from "react";
import "./SkeletonScreen.css";

export type SkeletonStyle = CSSProperties;

type Props = {
  numOfElements?: number;
  ref?: any;
  cssClass?: string;
  SkeletonStyle?: SkeletonStyle[];
  skeletonCardWrapperStyle?: CSSProperties;
};

// Ordering the animated items in the card - here is example how product card skeleton and its reference for all others
const SkeletonStyleArr: SkeletonStyle[] = [
  { height: "30px", width: "20%", left: "-30%" },
  { height: "120px", width: "80%", left: "0" },
  { height: "30px", width: "70%", left: "0" },
  { height: "30px", width: "30%", left: "0" },
  { height: "30px", width: "50%", left: "0" },
];

const SkeletonScreen: FC<Props> = ({
  numOfElements = 14,
  cssClass,
  SkeletonStyle = SkeletonStyleArr,
  skeletonCardWrapperStyle,
}) => {
  const drawCardStyle = () => {
    return SkeletonStyle.map((style, idx) => {
      return <div className="animated-background" style={{ borderRadius: "15px", ...style }} key={idx}></div>;
    });
  };

  const drawFakeSkeletonCard = () => {
    const array = Array.from({ length: numOfElements }, (_, index) => index);
    return array.map((i) => {
      return (
        <div key={i} className={`skeleton-card-item ${cssClass}`} style={skeletonCardWrapperStyle}>
          {drawCardStyle()}
        </div>
      );
    });
  };

  return <>{drawFakeSkeletonCard()}</>;
};

export default SkeletonScreen;
