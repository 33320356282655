import { Cart, ProductDataType, StoreEn } from "../../types/types";
import PriceCalculator from "../ItemPriceCalculator/PriceCalculator";

class CartModel {
  static cleanCart = () => {
    return {};
  };

  static changeAmount = (cart: Cart, item_code: string, type: "minus" | "plus") => {
    const newCart = { ...cart };
    let newAmount = newCart[item_code]?.amount || 0;
    if (type === "minus" && newAmount >= 1) newAmount -= 1;
    else if (type === "minus") {
      delete newCart[item_code];
      return newCart;
    } else newAmount += 1;
    newCart[item_code] = { ...newCart[item_code], amount: newAmount };
    return newCart;
  };

  static addToCart = (cart: Cart, item_code: string, productData: ProductDataType) => {
    return { ...cart, [item_code]: { amount: 1, product: productData } };
  };

  static removeItem = (cart: Cart, item_code: string) => {
    const newCart = { ...cart };
    delete newCart[item_code];
    return newCart;
  };

  static addStoreDataToProduct = (cart: Cart, itemCode: string, productData: ProductDataType, store: StoreEn) => {
    const newCart = { ...cart };
    newCart[itemCode].product[store] = productData[store];

    return newCart;
  };

  // Cart comparison : select only product that available in normalizingStore (remove from selected the products that not exist on normalizingStore)
  static normalizeCartAccordingOtherStore = (cart: Cart, normalizingStore: StoreEn) => {
    const cartItems = Object.keys(cart);
    const fakeFavoriteCart: Cart = {};
    cartItems.forEach((itemCode) => {
      if (cart[itemCode].product[normalizingStore]) {
        fakeFavoriteCart[itemCode] = cart[itemCode];
      }
    });

    return fakeFavoriteCart;
  };

  static getCartPrice = (siteVersion: 1 | 2, cart: Cart, favoriteStore: StoreEn, allStores: StoreEn[]) => {
    if (siteVersion === 1) {
      return PriceCalculator.getCartCostRange({ stores: allStores, cart });
    } else {
      return PriceCalculator.getTotalCartCost(favoriteStore, cart).toFixed(2);
    }
  };
}

export default CartModel;
