import { FC } from "react";
import { Card, Flex, InfoBox, Typography } from "../../UI";
import { WarningIcon } from "../../UI/Icons";
import { Language } from "../../../types/types";

interface IProps {
  storeName: string;
  itemName: string;
  language: Language;
}

const text = {
  title: {
    hebrew: "פריט לא נמצא ב-",
    english: "Item not found in -",
    arabic: "العنصر غير موجود في -",
    russian: "Товар не найден в -",
  },

  p1: {
    english: "Try searching for an alternative using the search bar.",
    arabic: "حاول البحث عن بديل باستخدام شريط البحث.",
    russian: "Попробуйте найти альтернативу с помощью строки поиска.",
    hebrew: "נסו לחפש חלופה באמצעות שורת החיפוש.",
  },
  p2: {
    english: "You can also ignore this item and look at the normalized price for comparison.",
    arabic: "يمكنك أيضًا تجاهل هذا العنصر والنظر في السعر المعياري للمقارنة.",
    russian: "Вы также можете игнорировать этот товар и смотреть на нормализованную цену для сравнения.",
    hebrew: "ניתן גם להתעלם מפריט זה ולהסתכל על המחיר המנורמל לצורך השוואה.",
  },
  p3: {
    english: "You can remove this item from the cart.",
    arabic: "يمكنك إزالة هذا العنصر من العربة.",
    russian: "Вы можете удалить этот товар из корзины.",
    hebrew: "ניתן להסיר פריט זה מהעגלה.",
  },
  p4: {
    english: "The requested item may be out of stock, or we simply couldn’t identify a similar item.",
    arabic: "قد يكون العنصر المطلوب غير متوفر في المخزون، أو لم نتمكن ببساطة من تحديد عنصر مشابه.",
    russian: "Запрашиваемый товар может отсутствовать на складе, или мы просто не смогли найти похожий товар.",
    hebrew: "ייתכן והפריט המבוקש אינו במלאי, או שפשוט לא הצלחנו לזהות פריט דומה.",
  },
};

const ItemNotFoundDescription: FC<IProps> = ({ itemName, storeName, language }) => {
  return (
    <Card
      title={
        <Flex style={{ width: "100%", marginBottom: 20 }} justify="center" align="center" gap={3}>
          <WarningIcon size="1em" />
          <Typography.Text>{text.title[language]}</Typography.Text>
          <Typography.Text>{storeName}</Typography.Text>
        </Flex>
      }
    >
      <InfoBox language={language} type="success" content={text.p1[language]} />
      <InfoBox language={language} type="info" content={text.p2[language]} />
      <InfoBox language={language} type="warning" content={text.p3[language]} />
      <InfoBox language={language} type="gray" content={text.p4[language]} />
    </Card>
  );
};

export default ItemNotFoundDescription;
