import { FC, memo } from "react";
import { IconCustom } from "./types";

const GlobousIcon: FC<IconCustom> = ({ color = "#434343", size = "3rem" }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 16 16" version="1.1" fill="#000000">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g fill={color}>
            <path d="M8.048,0 C3.63045083,0 0.048,3.582 0.048,8 C0.048,12.418 3.63045083,16 8.048,16 C12.4665577,16 16.048,12.418 16.048,8 C16.048,3.582 12.4665577,0 8.048,0 L8.048,0 Z M8,15 C4.13533409,15 1,11.8671467 1,8 C1,4.13384159 4.13533409,1 8,1 C11.8646659,1 15,4.13384159 15,8 C15,11.868135 11.8646659,15 8,15 L8,15 Z"></path>
            <path d="M2.959,2.684 C2.689,4.028 3.694,6.083 5.831,6.583 C7.967,7.083 7.049,6.667 6.717,6 C6.383,5.333 6.682,4.833 7.199,4.667 C7.717,4.5 7.586,4.96 8.354,4 C8.548,2 6.882,3.973 6.716,3.14 C8.366,1.223 5.95,0.5 5.249,0.75 C4.548,1 3.229,1.339 2.959,2.684 L2.959,2.684 Z"></path>
            <path d="M6.873,7.694 C6.749,7.749 6.171,8.11 5.972,8.36 C5.773,8.61 5.686,9.138 5.972,9.36 C6.258,9.583 5.956,10.639 6.727,10.833 C7.498,11.027 8.27,11.14 8.299,11.75 C8.326,12.36 8.227,13.777 7.856,13.917 C8.356,14.194 9.573,12.722 10.001,11.944 C10.43,11.167 10.573,10.055 10.544,9.777 C10.515,9.5 10.715,8.917 10.801,8.167 C10.886,7.417 10.231,7.367 10.146,7.034 C10.059,6.7 9.943,6.333 6.873,7.694 L6.873,7.694 Z"></path>
            <path d="M13.125,4 C13.125,4 11.113,4.861 12.481,6.14 C13.847,7.417 14.543,8.63 14.476,8.908 C14.414,9.184 13.096,10.614 13.853,10.502 C14.614,10.391 15.175,8.891 15.296,8.001 C15.415,7.111 14.372,5.362 14.329,5.224 C14.293,5.083 13.607,4.015 13.125,4 L13.125,4 Z"></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default memo(GlobousIcon);
