import { ReactNode, CSSProperties } from "react";
import "./Card.css";

interface ICardProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  children: string | ReactNode | ReactNode[];
  footer?: string | ReactNode | ReactNode[];
  image?: { src: string | any; width?: string | number; height?: string | number };
  iconR?: ReactNode;
  iconL?: ReactNode;
  style?: CSSProperties;
  onClick?: () => void;
  cssClass?: string;
  shadow?: boolean;
  radius?: boolean;
  hover?: boolean;
}

const Card = ({
  cssClass = "",
  title,
  subtitle,
  children,
  footer,
  image,
  iconR,
  iconL,
  style,
  onClick,
  shadow = true,
  hover = false,
  radius = true,
}: ICardProps) => {
  return (
    <div
      className={`card ${cssClass} ${shadow ? "card-shadow" : ""} ${hover ? "card-shadow-hover" : ""} ${
        radius ? "card-radius" : ""
      }`}
      style={style}
      onClick={onClick}
    >
      {image && <img src={image.src} height={image.height} width={image.width} alt="Card" className="card-image" />}
      {iconR && <div className="card-icon card-icon-right">{iconR}</div>}
      {iconL && <div className="card-icon card-icon-left">{iconL}</div>}
      <div className="card-content">
        {title && <h3 className="card-title">{title}</h3>}
        {subtitle && <h4 className="card-subtitle">{subtitle}</h4>}
        <div className="card-body">{children}</div>
      </div>
      {footer && <div className="card-footer">{footer}</div>}
    </div>
  );
};

export default Card;
