import React, { Children, cloneElement, useState } from "react";
import "./Carousel.css";

// ***********************************************************************************************************************
// ? Shorter version of Carousel.
// ? Same code is on the CarouselFull where Carousel beta including more code for more cases
// ! The Goal Of This Carousel is for rendering product card according version 1 which require more efficency due to large data rendering
// ***********************************************************************************************************************

interface CarouselProps {
  children: React.ReactNode;
  onSliding?: (slideNum: number) => void;
  rightArrow?: boolean;
  leftArrow?: boolean;
  gap?: number;
  showBtnOnHover?: boolean;
}

const Carousel: React.FC<CarouselProps> = ({
  children,
  onSliding,
  rightArrow = true,
  leftArrow = true,
  gap = 0,
  showBtnOnHover,
}) => {
  const [state, setState] = useState({
    position: Children.count(children) - 1,
    direction: Children.count(children) === 2 ? "prev" : "next",
    sliding: false,
  });

  function getOrder(itemIndex: number) {
    const numItems = Children.count(children);

    if (numItems === 2) return itemIndex;

    if (itemIndex < state.position) return numItems - Math.abs(itemIndex - state.position);
    return itemIndex - state.position;
  }

  const doSliding = (direction: "prev" | "next", position: number) => {
    onSliding && onSliding((position + 1) % Children.count(children));
    setState({
      sliding: true,
      direction,
      position,
    });

    setTimeout(() => {
      setState((prev) => ({ ...prev, sliding: false }));
    }, 5);
  };

  const prevSlide = () => {
    const { position } = state;

    const numItems = Children.count(children);

    if (numItems === 2 && position === 1) return;

    doSliding("next", position === numItems - 1 ? 0 : position + 1);
  };

  const nextSlide = () => {
    const { position } = state;

    const numItems = Children.count(children);

    if (numItems === 2 && position === 0) return;

    doSliding("prev", position === 0 ? numItems - 1 : position - 1);
  };

  const childrenWithProps = Children.map(children, (child, index) =>
    cloneElement(child as React.ReactElement<any>, {
      key: index,
      numslides: Children.count(children) || 1,
    })
  );

  const transformContainer = () => {
    if (!state.sliding) return `translateX(-100%)`;
    if (state.direction === "prev") return `translateX(-200%)`;
    return "translateX(0%)";
  };

  return (
    <>
      <div className="carousel_container">
        <div
          className="carousel_content_wrapper"
          style={{ transition: state.sliding ? "none" : "transform 0.6s ease", transform: transformContainer() }}
        >
          {childrenWithProps?.map((child, index, arr) => (
            <div
              key={index}
              style={{
                order: getOrder(index),
                flex: `1 0 100%`,
                marginRight: `${gap}%`,
              }}
            >
              {child}
            </div>
          ))}
        </div>
        {leftArrow && (
          <button
            className={`carousel-btn carousel-prev ${showBtnOnHover ? "carousel-btn_not_visible" : ""}`}
            onClick={prevSlide}
          >
            ⮜
          </button>
        )}
        {rightArrow && (
          <button
            className={`carousel-btn carousel-next ${showBtnOnHover ? "carousel-btn_not_visible" : ""}`}
            onClick={nextSlide}
          >
            ⮞
          </button>
        )}
      </div>
    </>
  );
};

export default Carousel;
