import React, { useEffect, useState } from "react";
import "./AnimatedComponent.css";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";

interface AnimatedComponentProps {
  children: React.ReactNode;
  animationClass: string;
  asContainer?: boolean;
}

const AnimatedComponent: React.FC<AnimatedComponentProps> = ({ children, animationClass, asContainer }) => {
  const [setNode, entry] = useIntersectionObserver({ threshold: 0.1 });
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (entry && entry.isIntersecting) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [entry]);

  return (
    <div ref={setNode} className={`animated-component ${isVisible ? animationClass : ""}`}>
      {asContainer ? (isVisible ? children : null) : children}
    </div>
  );
};

export default AnimatedComponent;
