import { FC, memo } from "react";
import { IconCustom } from "./types";

const DeleteIcon: FC<IconCustom> = ({ color = "#777", size = "1em", clickable = true, onClick }) => {
  return (
    <svg
      onClick={onClick}
      viewBox="0 0 24 24"
      fill={"none"}
      width={size}
      height={size}
      className={clickable ? "clickable-icon" : ""}
    >
      <circle cx="12" cy="12" r="10" stroke={color} strokeWidth="2" />
      <line x1="6" y1="12" x2="18" y2="12" stroke={color} strokeWidth="2" />
    </svg>
  );
};

export default memo(DeleteIcon);
