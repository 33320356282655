import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

const Wine: FC<IconCustom> = ({ onClick, cssClass, size = iconSizeDefault, hovered }) => {
  return (
    <svg
      onClick={onClick}
      className={cssClass}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 496.067 496.067"
      xmlSpace="preserve"
      width={size}
      height={size}
      fill={hovered ? "#000000" : "var(--iconC)"}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill={hovered ? "#246642" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
          d="M233.634,213.6c-4-8.8-8.8-21.6-12-29.6h-72c-3.2,8-8,20.8-12,29.6c-23.2,52.8-24,65.6-24,100v44.8 v3.2v92c0,23.2,22.4,41.6,45.6,41.6h52.8c23.2,0,45.6-18.4,45.6-41.6v-91.2v-3.2v-44.8C249.634,280.8,256.834,266.4,233.634,213.6z"
        ></path>
        <path
          fill={hovered ? "#15492A" : "var(--iconB)"}
          d="M233.634,213.6c-4-8.8-8.8-21.6-12-29.6h-40l30.4,312c23.2,0,45.6-18.4,45.6-41.6v-92v-3.2v-44.8 C257.634,280,256.834,266.4,233.634,213.6z"
        ></path>
        <path
          fill={hovered ? "#F4CF31" : "var(--iconB)"}
          d="M201.634,36c0,8-3.2,12-10.4,12h-11.2c-7.2,0-10.4-4.8-10.4-12V12.8c0-8,3.2-12.8,10.4-12.8h11.2 c7.2,0,10.4,5.6,10.4,12.8V36z"
        ></path>
        <polygon
          fill={hovered ? "#C93131" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={5}
          points="113.634,320 113.634,359.2 113.634,362.4 113.634,454.4 113.634,456 257.634,456 257.634,454.4 257.634,362.4 257.634,359.2 257.634,320 "
        ></polygon>
        <polyline
          fill={hovered ? "#A51919" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={5}
          points="257.634,456 257.634,454.4 257.634,362.4 257.634,359.2 257.634,320 113.634,320 "
        ></polyline>
        <polygon
          fill={hovered ? "#DCFFFC" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={4}
          points="137.634,344 137.634,368 137.634,370.4 137.634,431.2 137.634,432 233.634,432 233.634,431.2 233.634,370.4 233.634,368 233.634,344 "
        ></polygon>
        <polyline
          fill={hovered ? "#B8E0D9" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={5}
          points="233.634,432 233.634,431.2 233.634,370.4 233.634,368 233.634,344 137.634,344 "
        ></polyline>
        <path
          fill={hovered ? "#F4CF31" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={5}
          d="M220.034,176c-13.6-56-15.2-128.8-15.2-128.8l-11.2-1.6V44l-8,0.8l-8-0.8v1.6l-11.2,1.6 c0,0-2.4,72.8-15.2,128.8H220.034z"
        ></path>
        <path
          fill={hovered ? "#E2B22B" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={5}
          d="M177.634,176h42.4c-13.6-56-15.2-128.8-15.2-128.8l-11.2-1.6V44l-8,0.8l-8-0.8v4"
        ></path>
        <ellipse
          transform="matrix(0.2434 -0.9699 0.9699 0.2434 -148.2678 336.4945)"
          fill={hovered ? "#338956" : "var(--iconC)"}
          cx="141.553"
          cy="263.284"
          rx="31.999"
          ry="8"
        ></ellipse>
        <path
          fill={hovered ? "#E2B22B" : "var(--iconC)"}
          d="M207.234,44c0,2.4-1.6,4-4,4h-35.2c-2.4,0-4-1.6-4-4l0,0c0-2.4,1.6-4,4-4h35.2 C205.634,40,207.234,41.6,207.234,44L207.234,44z"
        ></path>
        <path
          fill={hovered ? "#D39C20" : "var(--iconC)"}
          d="M207.234,44L207.234,44c0,2.4-1.6,4-4,4h-35.2c-2.4,0-4-1.6-4-4l0,0"
        ></path>
        <path
          fill={hovered ? "#E2B22B" : "var(--iconC)"}
          d="M200.834,40c0.8-0.8,0.8-3.2,0.8-4V12.8c0-8-3.2-12.8-10.4-12.8h-12.8l-0.8,28.8L200.834,40z"
        ></path>
        <g>
          <path
            fill={hovered ? "#A51919" : "var(--iconC)"}
            d="M177.634,386.4c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S181.634,386.4,177.634,386.4z M175.234,392c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6C176.034,388.8,176.034,390.4,175.234,392z"
          ></path>
          <path
            fill={hovered ? "#A51919" : "var(--iconC)"}
            d="M193.634,386.4c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S197.634,386.4,193.634,386.4z M192.034,392c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6S192.834,390.4,192.034,392z"
          ></path>
        </g>
        <g>
          <path
            fill={hovered ? "#C93131" : "var(--iconC)"}
            d="M168.834,370.4c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2 C176.834,373.6,172.834,370.4,168.834,370.4z M167.234,376.8c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6 C168.034,373.6,168.034,375.2,167.234,376.8z"
          ></path>
          <path
            fill={hovered ? "#C93131" : "var(--iconC)"}
            d="M185.634,370.4c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S189.634,370.4,185.634,370.4z M183.234,376.8c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6S184.834,375.2,183.234,376.8z"
          ></path>
          <path
            fill={hovered ? "#C93131" : "var(--iconC)"}
            d="M185.634,401.6c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2 C192.834,404.8,189.634,401.6,185.634,401.6z M183.234,407.2c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6 C184.834,404,184.834,405.6,183.234,407.2z"
          ></path>
          <path
            fill={hovered ? "#C93131" : "var(--iconC)"}
            d="M201.634,370.4c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2 C209.634,373.6,206.434,370.4,201.634,370.4z M200.034,376.8c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6 C200.834,373.6,200.834,375.2,200.034,376.8z"
          ></path>
        </g>
        <g>
          <path
            fill={hovered ? "#A51919" : "var(--iconC)"}
            d="M160.834,356c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S164.834,356,160.834,356z M159.234,362.4c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6C160.034,359.2,160.034,360.8,159.234,362.4z"
          ></path>
          <path
            fill={hovered ? "#A51919" : "var(--iconC)"}
            d="M177.634,356c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S181.634,356,177.634,356z M175.234,362.4c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6C176.034,359.2,176.034,360.8,175.234,362.4z"
          ></path>
          <path
            fill={hovered ? "#A51919" : "var(--iconC)"}
            d="M193.634,356c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S197.634,356,193.634,356z M192.034,362.4c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6C192.834,359.2,192.834,360.8,192.034,362.4z"
          ></path>
          <path
            fill={hovered ? "#A51919" : "var(--iconC)"}
            d="M210.434,356c-4,0-7.2,3.2-7.2,7.2s3.2,7.2,7.2,7.2s7.2-3.2,7.2-7.2S214.434,356,210.434,356z M208.034,362.4c-0.8,1.6-2.4,2.4-3.2,1.6s-0.8-2.4,0-4s2.4-2.4,3.2-1.6S208.834,360.8,208.034,362.4z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default memo(Wine);
