export const dictPricesTable = {
  // part 1 item data
  item_code: { show: true, hebrew: "קוד מוצר", english: "Item Code", russian: "Код товара", arabic: "رمز المنتج" },
  item_name: { show: true, hebrew: "שם מוצר", english: "Item Name", russian: "Наименование", arabic: "اسم المنتج" },
  item_price: { show: true, hebrew: "מחיר", english: "Item Price", russian: "Цена", arabic: "سعر المنتج" },
  allow_discount: {
    show: true,
    hebrew: "הנחה נוספת",
    english: "Allow Discount",
    russian: "Разрешить скидку",
    arabic: "السماح بالخصم",
  },
  price_update_date: {
    show: true,
    hebrew: "תאריך עדכון מחיר",
    english: "Price Update Date",
    russian: "Дата обновления цены",
    arabic: "تاريخ تحديث السعر",
  },

  // part 2 extra
  b_is_weighted: { show: false, hebrew: "שקיל", english: "", russian: "", arabic: "" },
  quantity: { show: true, hebrew: "כמות", english: "Quantity", russian: "Количество", arabic: "الكمية" },
  unit_qty: { show: true, hebrew: "נמדד ב", english: "Measured in", russian: "Измеряется в", arabic: "قياسه بـ" },
  qty_in_package: {
    show: true,
    hebrew: "כמות בחבילה",
    english: "Quantity in Package",
    russian: "Количество в упаковке",
    arabic: "الكمية في العبوة",
  },
  unit_of_measure: {
    show: true,
    hebrew: "יחידת מידה",
    english: "Unit of Measure",
    russian: "Единица измерения",
    arabic: "وحدة القياس",
  },
  unit_of_measure_price: {
    show: true,
    hebrew: "מחיר ל",
    english: "Price per",
    russian: "Цена за",
    arabic: "السعر للوحدة",
  },

  // part 3 meta
  manufacture_country: {
    show: true,
    hebrew: "ארץ ייצור",
    english: "Manufacture Country",
    russian: "Страна производства",
    arabic: "بلد التصنيع",
  },
  manufacturer_name: {
    show: true,
    hebrew: "שם יצרן",
    english: "Manufacturer Name",
    russian: "Название производителя",
    arabic: "اسم الشركة المصنعة",
  },
  manufacturer_item_description: {
    show: true,
    hebrew: "תיאור מוצר לפי היצרן",
    english: "Manufacturer Item Description",
    russian: "Описание товара от производителя",
    arabic: "وصف المنتج من الشركة المصنعة",
  },
  promotion_description: {
    show: true,
    hebrew: "מבצע",
    english: "Promotion Description",
    russian: "Описание акции",
    arabic: "وصف العرض",
  },
  available: { show: false, hebrew: "במלאי", english: "", russian: "", arabic: "" },
  item_status: { show: false, hebrew: "סטטוס", english: "", russian: "", arabic: "" },
  item_type: { show: false, hebrew: "סוג מוצר", english: "", russian: "", arabic: "" },
  created_at: { show: false, hebrew: "נוצר ב", english: "", russian: "", arabic: "" },
  updated_at: { show: false, hebrew: "עודכן ב", english: "", russian: "", arabic: "" },
  store_name_label: { show: false, hebrew: "שם הרשת", english: "Store Name", russian: "", arabic: "" },
};

export const dictPromotionTable = {};
