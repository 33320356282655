import { useCallback, useEffect, useState } from "react";
import { SpinnerA } from "../../components/UI";
import "./CartComparisonPage.css";
import CartComparisonHeader from "./CartComparisonHeader/CartComparisonHeader";
import CartCompareSingleItemView from "./CartCompariosnViews/CartCompareSingelItemView/archive/CartCompareSingelItemView";
import CartComparisonCarouselView from "./CartCompariosnViews/CartComparisonCarouselView/CartComparisonCarouselView";
import serverAPI from "../../api/server";
import { SplitCart, StoreEn } from "../../types/types";
import CartComparisonAllCartsView from "./CartCompariosnViews/CartComparisonAllCartsView/CartComparisonAllCartsView";
import CartComparisonLegend from "./CartComparisonLegend/CartComparisonLegend";
import { useNavigate } from "react-router-dom";
import CheckoutCart from "../../components/CheckOut/CheckoutCart";
import LoginToStoreModal from "../../components/CheckOut/CheckoutStoresData/checkoutStoreCart/LoginToStoreModal/LoginToStoreModal";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";

const defaultKeyFromCookie = "single_item";

const CartComparisonPage = () => {
  const {
    cart,
    siteVersion,
    user: { language },
    onlineStores: { favoriteStore, otherStores },
    setCart,
  } = useCustomContext();

  const [isLoading, setIsLoading] = useState(false);
  const [viewSegment, setViewSegment] = useState(defaultKeyFromCookie);
  const [selectedStore, setSelectedStore] = useState<StoreEn>(favoriteStore);
  const [isLoginToStoreModalOpen, setIsLoginToStoreModalOpen] = useState(false);
  const navigate = useNavigate();

  const updateCart = useCallback(
    (selectedItems: string[], splitedCart: SplitCart) => {
      setCart((prev) => {
        selectedItems.forEach((item) => {
          otherStores.forEach((s: StoreEn) => {
            if (splitedCart[item]) {
              prev[item].product[s] = splitedCart[item][s];
            }
          });
        });

        return prev;
      });
    },
    [setCart, otherStores]
  );

  const fetchCartsFromOtherStores = useCallback(async () => {
    if (siteVersion === 1) return null;

    const selectedItemsFromFavorite = Object.keys(cart).filter((item) => {
      const productStores = Object.keys(cart[item].product) as StoreEn[];
      return !productStores.some((store) => otherStores.includes(store));
    });

    if (!selectedItemsFromFavorite.length) return null;

    const response = await serverAPI("cart/checkout/split/v2only", {
      favoriteStore: favoriteStore,
      otherStores: otherStores,
      selectedItems: selectedItemsFromFavorite,
    });

    if (!response.data) throw new Error("todo error handler here");

    updateCart(selectedItemsFromFavorite, response.data);
  }, [siteVersion, cart, favoriteStore, otherStores, updateCart]);

  useEffect(() => {
    try {
      setIsLoading(true);
      fetchCartsFromOtherStores();
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }, [cart, fetchCartsFromOtherStores]);

  useEffect(() => {
    // We can navigate anytime when cart is empty but i think only at beginning is someone came here
    if (Object.keys(cart).length === 0) navigate(-1);
    // eslint-disable-next-line
  }, []);

  const handleSelectStore = (store: StoreEn) => {
    setSelectedStore(store);
    setIsLoginToStoreModalOpen(true);
  };

  const segments: { [key: string]: JSX.Element } = {
    // table: <CheckoutComparisonTableView />,
    single_item: <CartCompareSingleItemView handleSelectStore={handleSelectStore} />,
    carousel_cart: <CartComparisonCarouselView handleSelectStore={handleSelectStore} />,
    all_carts: <CartComparisonAllCartsView handleSelectStore={handleSelectStore} />,
    test: <CheckoutCart />,
  };

  return (
    <section className="comparison-page-container">
      <CartComparisonHeader
        handleSegmentChange={(option) => setViewSegment(option.key)}
        defaultSegment={defaultKeyFromCookie}
      />
      <div className="cart-comparison_segment_content_container">
        {isLoading ? <SpinnerA text={"מייבא מוצרים דומים..."} margin="auto" size={100} /> : segments[viewSegment]}
      </div>
      <div className="responsive-hidden">
        <CartComparisonLegend />
      </div>
      <LoginToStoreModal
        store={selectedStore}
        language={language}
        isOpen={isLoginToStoreModalOpen}
        setIsOpen={setIsLoginToStoreModalOpen}
        cart={cart}
      />
    </section>
  );
};

export default CartComparisonPage;
