import { FC } from "react";
import { Language, ProductDataType, StoreEn } from "../../../types/types";
import Description, { DescriptionItem } from "../../UI/Description/Description";
import { dictPricesTable } from "../../../dictionary/dictProducts";
import { dictStores } from "../../../dictionary/dictStores";

type Props = {
  productData: ProductDataType;
  store: StoreEn;
  language: Language;
};

const translate = (language: string, context: string) => {
  const obj: any = {
    NA: {
      hebrew: "?",
      english: "N/A",
      russian: "bla",
      arabic: "معلومات إضافية حول",
    },
  };

  return obj[context][language];
};

//
const ExtraInformation: FC<Props> = ({ productData, store, language }) => {
  return (
    <div className="selected_item_extra_info">
      <Description>
        {/* part1 : item data*/}
        <DescriptionItem
          rowSize="full"
          label={dictPricesTable.store_name_label[language]}
          content={dictStores[store as StoreEn][language]}
        />
        <DescriptionItem
          rowSize="full"
          label={dictPricesTable.price_update_date[language]}
          content={new Date(productData[store].price_update_date).toLocaleString()}
          styleContent={{ textAlign: "center", background: "var(--light-color)" }}
        />

        <DescriptionItem
          rowSize="full"
          label={dictPricesTable.item_name[language]}
          content={productData[store].item_name}
        />
        <DescriptionItem
          rowSize="complete"
          label={dictPricesTable.manufacturer_name[language]}
          content={productData[store].manufacturer_name || translate(language, "NA")}
        />

        {productData[store].promotions && (
          <DescriptionItem
            rowSize="full"
            label={dictPricesTable.promotion_description[language]}
            content={
              <>
                {productData[store].promotions?.map((p, i) => {
                  return <div key={p.promotion_id + i}>{p.promotion_description}</div>;
                })}
              </>
            }
          />
        )}

        <DescriptionItem
          label={dictPricesTable.item_price[language]}
          content={
            <>
              <span>{productData[store].item_price}</span>
              <span> &#8362;</span>
            </>
          }
        />
        <DescriptionItem
          rowSize="complete"
          label={dictPricesTable.item_code[language]}
          content={productData[store].item_code}
        />

        {/* part2 : extra*/}
        <DescriptionItem label={dictPricesTable.quantity[language]} content={productData[store].quantity} />
        <DescriptionItem
          rowSize="complete"
          label={dictPricesTable.unit_qty[language]}
          content={productData[store].unit_qty}
        />
        <DescriptionItem label={dictPricesTable.qty_in_package[language]} content={productData[store].qty_in_package} />
        <DescriptionItem
          label={dictPricesTable.unit_of_measure_price[language] + " " + productData[store].unit_of_measure}
          content={
            <>
              <span>{productData[store].unit_of_measure_price}</span>
              <span> &#8362;</span>
            </>
          }
          rowSize="complete"
        />

        {/* part3 : meta */}
      </Description>
    </div>
  );
};

export default ExtraInformation;
