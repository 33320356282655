import React, { CSSProperties, FC, ReactNode, useState } from "react";
import Radio from "../Radio/Radio";

export interface RadioGroupOption {
  label: string | ReactNode;
  value: string;
  disable?: boolean;
}

interface RadioGroupProps {
  name: string;
  options: RadioGroupOption[];
  onChange: (value: string) => void;
  defaultValue?: string;
  style?: CSSProperties;
  cssClass?: string;
}

const RadioGroup: FC<RadioGroupProps> = ({ name, options, onChange, defaultValue, style, cssClass }) => {
  const [selectedValue, setSelectedValue] = useState<string>(defaultValue || "");

  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
    onChange(value);
  };

  return (
    <div style={style} className={cssClass}>
      {options.map((option) => (
        <Radio
          key={option.value}
          name={name}
          value={option.value}
          checked={option.value === selectedValue}
          label={option.label}
          onChange={() => handleRadioChange(option.value)}
          disabled={option.disable}
        />
      ))}
    </div>
  );
};

export default RadioGroup;
