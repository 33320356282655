// @ts-nocheck
import "./test.css";

import { CSSProperties, ReactNode, useEffect, useState } from "react";
import { Card, Flex } from "../components/UI";
import RangeSlider from "../components/UI/RangeSlider/multiRangeSlider";
import Tooltip from "../components/UI/TooltipNew/Tooltip";

const images = [
  "https://d226b0iufwcjmj.cloudfront.net/gs1-products/1470/medium/3228022910023-797617/3228022910023/2023-06-20T16-02-11-262Z.jpg",
  "https://d226b0iufwcjmj.cloudfront.net/gs1-products/1470/medium/4823077632440-850300/4823077632440/2023-10-04T13-52-53-293Z.jpg",
  "https://d226b0iufwcjmj.cloudfront.net/gs1-products/1470/medium/5740900403239-823591/5740900403239/2023-06-13T12-40-35-658Z.jpg",
  "https://d226b0iufwcjmj.cloudfront.net/gs1-products/1470/medium/7290000040462-828769/7290000040462/2023-08-16T20-02-28-034Z.jpg",
  "https://d226b0iufwcjmj.cloudfront.net/gs1-products/1470/medium/7290000045573-828768/7290000045573/2023-08-16T20-02-29-924Z.jpg",
  "https://d226b0iufwcjmj.cloudfront.net/gs1-products/1470/medium/7290000047942-828993/7290000047942/2023-08-16T20-03-34-410Z.jpg",
  "https://d226b0iufwcjmj.cloudfront.net/gs1-products/1470/medium/7290000045917-890315/7290000045917/2023-08-16T20-01-08-397Z.jpg",
  "https://d226b0iufwcjmj.cloudfront.net/gs1-products/1470/medium/7290000048413-909465/7290000048413/2023-08-16T20-01-06-037Z.jpg",
];

const TestComponent = () => {
  return (
    <Card title={"Test component"} subtitle={"Tooltip new test"}>
      <Flex gap={10}>
        {images.map((i, idx) => {
          return (
            <Card title="גבינה לבנה 5%" subtitle={10.9} key={idx} image={{ src: i, width: 50 }} footer={10}></Card>
          );
        })}
      </Flex>
    </Card>
  );
};

export default TestComponent;
