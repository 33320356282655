import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

const House: FC<IconCustom> = ({ onClick, size = iconSizeDefault, hovered }) => {
  return (
    <svg
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 512 512"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill={hovered ? "#D1CFC3" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={hovered ? 0 : 10}
          d="M484.431 147.9a14.62 14.62 0 0 0-1.746-1.316L328.509 48.076a13.413 13.413 0 0 0-14.388-.036l-155.905 98.508c-.587.371-1.119.775-1.619 1.197c-3.968 1.11-6.882 4.744-6.882 9.067v334.493a9.423 9.423 0 0 0 9.423 9.423H481.39a9.423 9.423 0 0 0 9.423-9.423V156.813c0-4.139-2.672-7.647-6.382-8.913z"
        ></path>

        <path
          fill={hovered ? "#464A4C" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={hovered ? 0 : 10}
          d="M267.211 194.292v75.148a7.2 7.2 0 0 1-7.2 7.2H223.75a7.2 7.2 0 0 1-7.2-7.2v-75.148a7.2 7.2 0 0 1 7.2-7.2h36.261a7.2 7.2 0 0 1 7.2 7.2zm152.032-7.2h-36.261a7.2 7.2 0 0 0-7.2 7.2v75.148a7.2 7.2 0 0 0 7.2 7.2h36.261a7.2 7.2 0 0 0 7.2-7.2v-75.148a7.2 7.2 0 0 0-7.2-7.2z"
        ></path>
        <path
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={hovered ? 0 : 10}
          fill={hovered ? "#2B3B47" : "var(--iconA)"}
          d="M275.416 378.879h92.561v121.85h-92.561z"
        ></path>
        <path
          fill={hovered ? "#8E7B70" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={hovered ? 0 : 10}
          d="M269.357 288.651h-54.952a7.2 7.2 0 0 1-7.2-7.2v-3.836a7.2 7.2 0 0 1 7.2-7.2h54.952a7.2 7.2 0 0 1 7.2 7.2v3.836a7.2 7.2 0 0 1-7.2 7.2zm166.432-7.2v-3.836a7.2 7.2 0 0 0-7.2-7.2h-54.952a7.2 7.2 0 0 0-7.2 7.2v3.836a7.2 7.2 0 0 0 7.2 7.2h54.952a7.2 7.2 0 0 0 7.2-7.2zM378.22 387.157c8.352 0 14.322-7.854 12.443-15.991c-7.249-31.388-35.375-54.788-68.966-54.788s-61.717 23.4-68.966 54.788c-1.879 8.138 4.091 15.991 12.443 15.991H378.22z"
        ></path>
        <path
          fill={hovered ? "#FF473E" : "var(--iconC)"}
          d="M136.118 172.7h15.664c1.3 0 2.575-.361 3.682-1.042L319.987 70.46a7.027 7.027 0 0 1 7.442.049L487.084 171.61a7.031 7.031 0 0 0 3.76 1.09h14.115c6.985 0 9.693-9.085 3.847-12.909L327.586 41.243a7.03 7.03 0 0 0-7.602-.06l-187.62 118.548c-5.955 3.762-3.289 12.969 3.754 12.969z"
        ></path>
      </g>
    </svg>
  );
};

export default memo(House);
