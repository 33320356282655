import React, { ChangeEvent, FC, ReactNode } from "react";
import "./Radio.css";

interface IRadioProps {
  name: string;
  value: string;
  checked: boolean;
  label: string | ReactNode;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const Radio: FC<IRadioProps> = ({ name, value, checked, label, onChange, disabled }) => {
  return (
    <label className="ui-radio-label">
      <input type="radio" name={name} value={value} checked={checked} onChange={onChange} disabled={disabled} /> {label}
    </label>
  );
};

export default Radio;
