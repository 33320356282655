import useCustomContext from "../../../../../hooks/useCustomContext/useCustomContext";
import { CartIcon } from "../../../../UI/Icons";
import "./CartTogglePrimary.css";

const CartTogglePrimary = () => {
  const { setIsCartOpen, cart } = useCustomContext();

  return (
    <div onClick={() => setIsCartOpen((p) => !p)} className="flex-center cart-toggle-primary-wrapper">
      <CartIcon clickable size="75%" />
      {Object.keys(cart).length > 0 && (
        <div className="cart-toggle-primary-float flex-center">{Object.keys(cart).length}</div>
      )}
    </div>
  );
};

export default CartTogglePrimary;
