import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

const Grocery: FC<IconCustom> = ({ onClick, cssClass, size = iconSizeDefault, hovered }) => {
  return (
    <svg
      onClick={onClick}
      className={cssClass}
      width={size}
      height={size}
      viewBox="0 0 1024 1024"
      fill={hovered ? "#000000" : "var(--iconA)"}
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          d="M341.333333 426.645333m-170.666666 0a170.666667 170.666667 0 1 0 341.333333 0 170.666667 170.666667 0 1 0-341.333333 0Z"
          fill={hovered ? "#FFB300" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
        ></path>
        <path
          d="M362.666667 725.312m-170.666667 0a170.666667 170.666667 0 1 0 341.333333 0 170.666667 170.666667 0 1 0-341.333333 0Z"
          fill={hovered ? "#FFB300" : "var(--iconA)"}
        ></path>
        <path
          d="M810.666667 551.936c0-131.562667-80.576-101.184-80.576-384.533333h-201.493334C528.597333 450.752 448 420.373333 448 551.936v313.728A30.293333 30.293333 0 0 0 478.229333 896h302.229334A30.293333 30.293333 0 0 0 810.666667 865.664V551.936z"
          fill={hovered ? "#90CAF9" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
        ></path>
        <path
          d="M746.666667 160.021333C746.666667 177.685333 733.525333 192 717.333333 192h-176C525.141333 192 512 177.685333 512 160.021333v-21.333333C512 121.024 525.141333 106.666667 541.333333 106.666667h176C733.525333 106.666667 746.666667 121.024 746.666667 138.688v21.333333z"
          fill={hovered ? "#9C27B0" : "var(--iconC)"}
        ></path>
        <path
          d="M770.368 825.173333a10.090667 10.090667 0 0 1-10.090667 10.133334H498.368a10.112 10.112 0 0 1-10.090667-10.133334V551.936c0-43.776 9.856-63.658667 24.768-93.781333 13.461333-27.136 28.608-58.026667 39.786667-108.629334h153.002667c11.157333 50.602667 26.325333 81.472 39.786666 108.629334 14.890667 30.101333 24.746667 50.005333 24.746667 93.781333V825.173333z"
          fill={hovered ? "#FAFAFA" : "var(--iconA)"}
        ></path>
        <path
          d="M170.666667 554.688L213.333333 896h597.333334l42.666666-341.312H170.666667zM320 832h-42.666667v-85.333333h42.666667v85.333333z m0-128h-42.666667v-85.333333h42.666667v85.333333z m106.666667 128h-42.666667v-85.333333h42.666667v85.333333z m0-128h-42.666667v-85.333333h42.666667v85.333333z m106.666666 128h-42.666666v-85.333333h42.666666v85.333333z m0-128h-42.666666v-85.333333h42.666666v85.333333z m106.666667 128h-42.666667v-85.333333h42.666667v85.333333z m0-128h-42.666667v-85.333333h42.666667v85.333333z m106.666667 128h-42.666667v-85.333333h42.666667v85.333333z m0-128h-42.666667v-85.333333h42.666667v85.333333z"
          fill={hovered ? "#E91E63" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
        ></path>
        <path
          d="M885.205333 512a21.333333 21.333333 0 0 0-21.333333-21.333333H160.128a21.333333 21.333333 0 0 0-21.333333 21.333333v42.688a21.333333 21.333333 0 0 0 21.333333 21.333333h703.744a21.333333 21.333333 0 0 0 21.333333-21.333333V512z"
          fill={hovered ? "#AD1457" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
        ></path>
        <path
          d="M341.333333 331.306667s-21.333333-139.285333-192-117.952c42.666667 149.333333 192 117.952 192 117.952z"
          fill={hovered ? "#4CAF50" : "var(--iconC)"}
        ></path>
      </g>
    </svg>
  );
};

export default memo(Grocery);
