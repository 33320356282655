import React, { useState, ReactNode, CSSProperties } from "react";
import "./Popconfirm.css"; // Create this file for custom styles
import Button from "../Button/Button";
import WarningIcon from "../Icons/MessagesIcons/WarningIcon";
import Typography from "../Typography/Typography";

interface PopconfirmButtonProps {
  title: string | ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  children: ReactNode;
  description: string | ReactNode;
  position?: "top" | "bottom";
  popconfirmStyle?: CSSProperties;
  popconfirmTitleStyle?: CSSProperties;
  closable?: boolean;
  okText?: string;
  cancelText?: string;
}

const PopConfirm: React.FC<PopconfirmButtonProps> = ({
  title,
  onConfirm,
  onCancel,
  children,
  okText,
  cancelText,
  position = "top",
  popconfirmStyle,
  popconfirmTitleStyle,
  description,
  closable = true,
}) => {
  const [visible, setVisible] = useState(false);

  const hidePopconfirm = () => {
    setVisible(false);
  };

  const handleConfirm = () => {
    onConfirm();
    hidePopconfirm();
  };

  const handleCancel = () => {
    onCancel();
    hidePopconfirm();
  };

  const onBlur = (event: any) => {
    // currentTarget refers to this component.
    // relatedTarget refers to the element where the user clicked (or focused) which
    // triggered this event.
    // So in effect, this condition checks if the user clicked outside the component.
    if (closable && !event.currentTarget.contains(event.relatedTarget)) {
      hidePopconfirm();
    }
  };

  return (
    <div className="popconfirm-wrapper" tabIndex={0} onBlur={onBlur}>
      <Button type="default" onClick={() => setVisible((p) => !p)} cssClass="popconfirm-btn">
        {children}
      </Button>
      {visible && (
        <div
          style={popconfirmStyle}
          className={`popconfirm-content ${visible ? "popconfirm-content-show" : ""} ${position}-pop-content`}
        >
          <div className="popconfirm-message" style={popconfirmTitleStyle}>
            <WarningIcon size="18px" />
            <Typography.Title level={6}>{title}</Typography.Title>
          </div>
          <div style={{ margin: "5px auto" }}>{description}</div>
          <div className="popconfirm-buttons">
            <Button width={"fit-content"} size="small" onClick={handleConfirm}>
              {okText ?? "כן"}
            </Button>
            <Button width={"fit-content"} size="small" type="default" onClick={handleCancel}>
              {cancelText ?? "לא"}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PopConfirm;
