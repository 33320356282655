import { FC, Dispatch, SetStateAction } from "react";
import "./FilterCategorySearch.css";
import { getTranslatedCategory } from "../../../../../utils/utils";
import { SelectSearchUI } from "../../../../UI";
import CloseBtn from "../../../../UI/CloseBtn/CloseBtn";

type Props = {
  setCategory: Dispatch<SetStateAction<{ key: string; name: string } | null>>;
  category: { key: string; name: string } | null;
  hardCloseFilters?: any;
};

const FilterCategorySearch: FC<Props> = ({ setCategory, category, hardCloseFilters }) => {
  const categories: Record<string, string> = getTranslatedCategory("hebrew");
  const arrayOfObjects = Object.entries(categories).map(([key, name]) => ({ key, name }));

  const handleCloseFilters = () => {
    setCategory(null);
    hardCloseFilters(false);
  };

  return (
    <div style={{ padding: "5px", width: "95%", maxWidth: "600px", margin: "5px auto", border: "1px solid #dad9d9" }}>
      <CloseBtn onClose={handleCloseFilters} title="בחירת קטגוריה לייעול החיפוש" />
      <div style={{ padding: "5px" }}>
        <SelectSearchUI
          dropDownPosition="sticky"
          label={category?.name ? "selected in this category" : ""}
          placeholder="חפש קטגוריה"
          onChange={(option) => (option[0] ? setCategory({ key: option[0].value, name: option[0].label }) : null)}
          disableLabelInInput
          values={category ? [{ value: category?.key, label: category?.name }] : undefined}
          options={arrayOfObjects.map((c) => ({ label: c.name, value: c.key }))}
        />
      </div>
    </div>
  );
};

export default FilterCategorySearch;
