import React, { FC, ReactNode } from "react";
import { CrossIcon } from "../Icons";
import Divider from "../Divider/Divider";
import "./CloseBtn.css";

type Props = {
  title?: string | ReactNode;
  closeIcon?: string | ReactNode;
  showDivider?: boolean;
  onClose: () => void;
  cssClass?: string;
  closeIconColor?: string;
};

const CloseBtn: FC<Props> = ({
  title,
  closeIconColor,
  closeIcon = <CrossIcon clickable color={closeIconColor} size={"1.5rem"} />,
  showDivider = true,
  onClose,
  cssClass = "",
}) => {
  return (
    <>
      <div className={"ui-close-btn_wrapper " + cssClass}>
        <span
          onClick={() => onClose()}
          style={{ cursor: "pointer", height: "2rem", position: "absolute", left: "6px" }}
        >
          {closeIcon}
        </span>
        {title && <span style={{ textAlign: "center", flex: "1" }}>{title}</span>}
      </div>
      {showDivider && <Divider />}
    </>
  );
};

export default CloseBtn;
