import React, { FC } from "react";
import { Language, ProductDataType } from "../../../types/types";
import CartModel from "../../../models/Cart/CartModel";
import { Button } from "../../UI";
import { CartIcon } from "../../UI/Icons";
import { dictButtonsAndActions } from "../../../dictionary/dictButtons";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";

type Props = {
  item_code: string;
  productData: ProductDataType;
  language: Language;
};

const AddToCartBtn: FC<Props> = ({ item_code, productData, language }) => {
  const { setCart } = useCustomContext();

  const handleAddToCart = () => {
    setCart((prev) => CartModel.addToCart(prev, item_code, productData));
  };
  return (
    <Button size="small" cssClass="add_cart_btn" onClick={handleAddToCart}>
      <span>
        <CartIcon size={"1.5rem"} color="white" />
      </span>
      <span>{dictButtonsAndActions.addToCart[language]}</span>
    </Button>
  );
};

export default AddToCartBtn;
