import { createPortal } from "react-dom";
import { ReactNode, FC, CSSProperties } from "react";
import "./Modal.css";
import Button from "../Button/Button";
import SpinnerA from "../Loaders/spinnerA/SpinnerA";

import LogoIcon from "../Icons/Logo";
import { Language } from "../../../types/types";
import { useToken } from "../ThemeProvider/ThemeProvider";
import { resolveDirClass } from "../../../utils/utils";

export type variantType = "primary" | "secondary" | "success" | "info" | "light" | "dark" | "bgs";

export interface ModalButtons {
  text: string | ReactNode;
  onClick: () => void | any;
  variant?: variantType;
  size?: "small" | "medium" | "large";
  color?: string;
  backgroundColor?: string;
  cssClass?: string;
}

interface ModalProps {
  visible?: boolean;
  title?: string | ReactNode;
  onCancel?: () => void;
  onOk?: () => void;
  okText?: string;
  cancelText?: string;
  loading?: boolean;
  children: ReactNode;
  width?: string | number;
  height?: string | number;
  buttons?: ModalButtons[];
  style?: CSSProperties;
  dir?: "ltr" | "rtl";
  withFooter?: boolean;
  position?: "center" | "top-right" | "bottom-right" | "top-left" | "bottom-left";
  margin?: boolean;
  language?: Language;
  includeCloseBtn?: boolean;
  maskClosable?: boolean;
  withLogo?: boolean;
}

const Modal: FC<ModalProps> = ({
  title,
  visible,
  onCancel,
  onOk,
  okText,
  cancelText,
  loading,
  children,
  width,
  height,
  buttons,
  style,
  dir,
  withFooter = true,
  position = "center",
  margin,
  language,
  includeCloseBtn = true,
  maskClosable = true,
  withLogo = true,
}) => {
  const { theme } = useToken();
  if (!visible) return null;

  let modalClassDynamicStyle: CSSProperties = {
    width: width ? width : "auto",
    height: height ? height : "fit-content",
    direction: dir ? dir : language && resolveDirClass(language),
  };

  if (style) {
    modalClassDynamicStyle = { ...modalClassDynamicStyle, ...style };
  }

  const drawButtons = () => {
    return buttons?.map((btn, idx) => {
      return (
        <Button
          cssClass={btn.cssClass}
          onClick={btn.onClick}
          variant={btn.variant}
          size={btn.size || "medium"}
          backgroundColor={btn.backgroundColor}
          key={idx}
        >
          {btn.text}
        </Button>
      );
    });
  };

  const handleOverlayClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    maskClosable && onCancel && onCancel();
  };

  return createPortal(
    <div className={"modal-overlay"} onClick={handleOverlayClick}>
      <div
        className={`modal ${margin ? "margin-modal" : ""} ${position.split("-").join(" ")} ${theme}`}
        style={modalClassDynamicStyle}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="modal-header">
          {withLogo && (
            <div>
              <LogoIcon size={"40px"} />
            </div>
          )}
          {title && (typeof title === "string" ? <h2>{title}</h2> : title)}
          {onCancel && includeCloseBtn && <button onClick={onCancel}>X</button>}
        </div>
        <div className="modal-content">{children}</div>
        {withFooter && (
          <div className="modal-footer">
            {buttons?.length ? (
              drawButtons()
            ) : (
              <>
                <Button onClick={onOk} disabled={loading}>
                  {loading ? <SpinnerA size={20} /> : okText || "אישור"}
                </Button>
                <Button type="default" onClick={onCancel}>
                  {cancelText || "ביטול"}
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </div>,
    document.body
  );
};

export default Modal;
