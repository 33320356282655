import React, { ReactNode, useState } from "react";
import "./Collapse.css";

export type CollapseItemType = { title: string | ReactNode; content?: string | ReactNode };

interface IProps {
  items: CollapseItemType[];
}

const Collapse = ({ items }: IProps) => {
  return (
    <div className="collapse-ui-container">
      {items.map((i, idx) => {
        return <CollapseItem title={i.title} content={i.content} key={idx} />;
      })}
    </div>
  );
};

export default Collapse;

const CollapseItem = ({ title, content }: CollapseItemType) => {
  const [isOpen, setIsOpen] = useState(true);
  return (
    <div className="collapse-ui-single-wrapper">
      <div className="collapse-ui-single-title" onClick={() => content && setIsOpen((p) => !p)} aria-expanded={isOpen}>
        <span>{title}</span>
        {content && <span className={`collapse-ui-single-icon-${isOpen ? "open" : "close"}`}>› </span>}
      </div>
      {content && <div className={`collapse-ui-single-content-${isOpen ? "open" : "closed"}`}>{content}</div>}
    </div>
  );
};

Collapse.CollapseItem = CollapseItem;
