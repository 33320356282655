import { FC } from "react";

type NoDataFoundProps = {
  text?: string;
  imgHeight?: number | string;
};

const DataNotFound: FC<NoDataFoundProps> = ({ text, imgHeight }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "30px",
        backgroundColor: "rgba(230,230,230,0.3)",
      }}
    >
      <img src={"https://cdn.onlinewebfonts.com/svg/img_257095.png"} height={imgHeight || 50} alt="not found" />
      <span>{text ? text : "No Data Found"}</span>
    </div>
  );
};

export default DataNotFound;
