import { FC, useEffect, useState } from "react";
import "./PasswordStrengthMeter.css";

type Props = {
  password: string;
};

const getPasswordStrengthText = (strength: number) => {
  if (strength <= 1) {
    return "Weak";
  } else if (strength <= 3) {
    return "Fair";
  } else if (strength <= 5) {
    return "Good";
  } else if (strength <= 6) {
    return "Strong";
  } else if (strength < 8) {
    return "Very-Strong";
  } else {
    return "Excellent";
  }
};

export const calculateStrength = (password: string) => {
  let score = 0;

  if (password.match(/[a-z]/)) {
    score += 1;
  }
  if (password.match(/[א-ת]/)) {
    score += 1;
  }

  if (password.match(/[0-9]/)) {
    score += 1;
  }
  if (password.match(/[A-Z]/)) {
    score += 2;
  }

  if (password.match(/[$@#&!]/)) {
    score += 2;
  }

  score += Math.min(password.length - 1, 3);

  return Math.min(score, 10); // Cap the score at 10
};

const PasswordStrengthMeter: FC<Props> = ({ password }) => {
  const [strength, setStrength] = useState(0);

  useEffect(() => {
    if (password) setStrength(calculateStrength(password));
    else setStrength(0);
  }, [password, setStrength]);

  return (
    <div className="password-strength-meter">
      <div className={`strength-scale strength-${getPasswordStrengthText(strength)}`}>
        <div className="strength-level" />
        <div className="strength-level" />
        <div className="strength-level" />
        <div className="strength-level" />
        <div className="strength-level" />
      </div>
    </div>
  );
};

export default PasswordStrengthMeter;
