import { FC } from "react";
import { IconCustom } from "./types";

interface LogoIconProp extends IconCustom {}

const LogoIcon: FC<LogoIconProp> = ({ cssClass = "", color = "var(--primary-color)", size = "100%", clickable }) => {
  return (
    <svg
      className={`${cssClass} ${clickable ? "clickable-icon" : ""}`}
      width={size}
      height={size}
      viewBox={`0 -10 120 150`}
    >
      <title>תוזילו</title>
      <desc>תשוו תקנו תוזילו</desc>
      <g
        fill={color}
        stroke={color}
        strokeWidth="3.428"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
      >
        <path fill="none" d="m60 0 -60 60 60 60 60-60z" />
        <path d="m60 101.816-41.817-41.813 41.815-41.819 41.813 41.817-41.812 41.815zM36.366 60 60 83.63 83.63 60l-23.632-23.634L36.368 60z" />
        <path d="m54.54 60.003 5.45 5.45 5.452-5.45-5.451-5.452z M60 119.995 111.424 68.575 119.991 77.14 60 137.137 0 77.14 8.571 68.568z" />
      </g>
    </svg>
  );
};

export default LogoIcon;
