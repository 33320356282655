import { FC } from "react";
import "./ChangeAmount.css";
import { InfoIcon, PlusOrMinusIcon } from "../../UI/Icons";
import CartModel from "../../../models/Cart/CartModel";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";

type Props = {
  item_code: string;
  handleInfoClick: () => void;
};

const ChangeAmount: FC<Props> = ({ item_code, handleInfoClick }) => {
  const { setCart, cart } = useCustomContext();

  const handleChangeAmount = (type: "minus" | "plus") => {
    setCart((prev) => CartModel.changeAmount(prev, item_code, type));
  };

  return (
    <div className="product-amount-wrapper">
      <div className="product-amount-wrapper_info">
        <InfoIcon size="2rem" color="var(--secondary-color)" clickable onClick={handleInfoClick} />
      </div>
      <div className="amount-changer" onClick={() => handleChangeAmount("plus")}>
        <PlusOrMinusIcon color="var(--primary-color)" sign="plus" size={"4rem"} />
      </div>
      <div className="amount">{cart[item_code]?.amount || 0}</div>
      <div className="amount-changer" onClick={() => handleChangeAmount("minus")}>
        <PlusOrMinusIcon color="var(--primary-color)" sign="minus" size={"4rem"} />
      </div>
    </div>
  );
};

export default ChangeAmount;
