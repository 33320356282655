import { useState } from "react";

// Declare the SpeechRecognition object
declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

const langMap = {
  hebrew: "he-IL",
  english: "en-US",
  russian: "ru-RU",
  arabic: "ar-SA",
};

function useSpeechToText() {
  const [transcription, setTranscription] = useState("");
  const [listening, setListening] = useState(false);

  const startListening = (language: keyof typeof langMap) => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.onstart = () => {
      setListening(true);
    };

    recognition.lang = langMap[language];

    recognition.onresult = (event: any) => {
      const transcript = event.results[0][0].transcript;
      setTranscription(transcript);
    };

    recognition.onend = () => {
      setListening(false);
    };

    recognition.start();
  };

  return { transcription, listening, startListening };
}

export default useSpeechToText;
