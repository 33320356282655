import { FC } from "react";
import { Carousel, ImageComponent } from "../../UI";
import { Language, ProductDataType, StoreEn } from "../../../types/types";
import { dictStores } from "../../../dictionary/dictStores";
import PriceCalculator from "../../../models/ItemPriceCalculator/PriceCalculator";

interface IProductCardBodyProps {
  productData: ProductDataType;
  onClick: () => void;
  imgSize?: [string, string];
  stores: StoreEn[];
  language: Language;
}

export const ProductCardBodyV1: FC<IProductCardBodyProps> = ({
  stores,
  language,
  onClick,
  productData,
  imgSize = ["60%", "80px"],
}) => {
  const drawCarouselContent = () => {
    return stores.map((store: StoreEn) => {
      return (
        <div key={store} className="product_data_wrapper" style={{ height: 230 }}>
          <div className="store_name_card">{dictStores[store][language]}</div>
          <h3 className="item_name_card">{productData[store].item_name}</h3>
          <div className="item_img_card">
            <ImageComponent
              height={imgSize[1]}
              width={imgSize[0]}
              src={productData[store].img_url as string}
              alt={productData[store].item_name as string}
              onClick={onClick}
            />
          </div>
          <div className="">
            <span>{productData[store].item_price}</span>
            <span>&#8362;</span>
          </div>
        </div>
      );
    });
  };
  return <Carousel showBtnOnHover>{drawCarouselContent()}</Carousel>;
};

interface IProductCardFooterProps {
  stores: StoreEn[];
  productData: ProductDataType;
}

export const ProductCardFooterV1: FC<IProductCardFooterProps> = ({ stores, productData }) => {
  return (
    <div style={{ marginBottom: "5px" }}>
      <span>{PriceCalculator.getNormalPriceRange(stores, productData)}</span>
      <span>&#8362;</span>
    </div>
  );
};
