import React from "react";

const MyShoppingData = () => {
  return (
    <div>
      <p>היסטוריית קניות</p>
      <p>כרגע זה בתכנון</p>
      <p>
        הייעוד של קומפוננטה זו להראות למשתמש את היסטוריית הקניות שלו וכמה כסף הוא חסך בכל קנייה, כמובן שצריך להאפשר לו
        לראות מידע נוסף , כמו המוצרים שהוא קנה הכי הרבה , אולי גרפים של עלויות וכד'
      </p>
    </div>
  );
};

export default MyShoppingData;
