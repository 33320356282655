import React, { FC } from "react";
import { IconCustom } from "../types";

const FacebookIcon: FC<IconCustom> = ({ size, color = "currentColor" }) => {
  return (
    <svg fill={color} width={size} height={size} viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path d="M21.95 5.005l-3.306-.004c-3.206 0-5.277 2.124-5.277 5.415v2.495H10.05v4.515h3.317l-.004 9.575h4.641l.004-9.575h3.806l-.003-4.514h-3.803v-2.117c0-1.018.241-1.533 1.566-1.533l2.366-.001.01-4.256z"></path>
      </g>
    </svg>
  );
};

export default FacebookIcon;
