import { useState, useEffect, FC, CSSProperties } from "react";
import "./ScrollTopBtn.css";

export interface scrollToTopRef extends HTMLDivElement {
  detected?: boolean;
}

interface IProp {
  // targetElementRef: React.RefObject<HTMLDivElement>;
  targetElementRef: React.RefObject<scrollToTopRef>;
  style?: CSSProperties;
  onEndView?: () => void;
}

const ScrollTopBtn: FC<IProp> = ({ targetElementRef, style, onEndView }) => {
  const [showButton, setShowButton] = useState(false);

  const handleButtonClick = () => {
    const container = targetElementRef.current;
    if (container) {
      targetElementRef.current.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const detectEndView = () => {
    const container = targetElementRef.current;
    if (container) {
      const containerScrollTop = container.scrollTop;
      const containerHeight = container.clientHeight;
      const contentHeight = container.scrollHeight;

      // Check if content height exceeds client height, indicating scrolling is required
      const isScrollable = contentHeight > containerHeight;

      // If content is scrollable, consider end of view when scrollTop is at the bottom
      if (isScrollable) {
        return containerScrollTop >= contentHeight - containerHeight - 1;
      } else {
        // If content fits within viewport, consider end of view when scrollTop reaches the bottom
        return containerScrollTop >= containerHeight;
      }
    }
    return false;
  };

  useEffect(() => {
    const currentTargetElement = targetElementRef.current;
    const handleScroll = () => {
      if (currentTargetElement) {
        setShowButton(currentTargetElement.scrollTop > 110); // Adjust the scroll threshold as needed
        if (onEndView && detectEndView()) {
          // console.log("Activate On End View");
          onEndView();
          currentTargetElement.detected = true;
        }
      }
    };

    // Attach scroll event listener
    if (currentTargetElement) {
      currentTargetElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      // Clean up the event listener
      if (currentTargetElement) {
        currentTargetElement.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [targetElementRef]);

  return (
    <div
      style={style}
      className={`scroll-to-top${showButton ? " visible" : ""}`}
      onClick={() => {
        handleButtonClick();
      }}
    >
      <span>&#8593;</span>
    </div>
  );
};

export default ScrollTopBtn;
