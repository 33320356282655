import { FC, memo } from "react";
import { IconCustom } from "./types";

const CrossIcon: FC<IconCustom> = ({ color = "var(--font-color)", size = "1rem", clickable = true }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill={color}
      width={typeof size === "number" ? `${size}px` : size}
      height={typeof size === "number" ? `${size}px` : size}
      className={clickable ? "clickable-icon" : ""}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
    </svg>
  );
};

export default memo(CrossIcon);
