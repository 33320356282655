import React from "react";
import "./Toggle.css"; // Import your CSS file for styling

type ToggleProps = {
  checked?: boolean;
  onChange: (checked: boolean) => void;
  id?: string;
};

const Toggle: React.FC<ToggleProps> = ({ checked, onChange, id }) => {
  return (
    <div>
      <label className="switch">
        <input type="checkbox" onChange={() => onChange(!checked)} checked={checked} />
        <span className="slider round"></span>
      </label>
    </div>
  );
};

export default Toggle;
