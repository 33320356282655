import { Outlet, useLocation } from "react-router-dom";
import NavBar from "./components/Navbar/NavBar";
import { directionByLanguage } from "./utils/utils";
import "../src/components/UI/index.css";
import ThemeProvider from "./components/UI/ThemeProvider/ThemeProvider";
import { Content, Layout } from "./components/UI";
import MobileNav from "./components/Navbar/MobileNav/MobileNav";
import { screenSize } from "./utils/constants";
import HomePageFooter from "./components/Footer/HomepageFooter/HomePageFooter";
import useCustomContext from "./hooks/useCustomContext/useCustomContext";

const App = () => {
  const {
    user: { language },
    theme,
  } = useCustomContext();

  const location = useLocation();

  return (
    <ThemeProvider token={{ theme: theme }}>
      <Layout cssClass={directionByLanguage(language) + theme}>
        <NavBar language={language} />
        <Content style={{ background: location.pathname === "/" ? "var(--white)" : undefined }}>
          <Outlet />
        </Content>
        {window.innerWidth <= screenSize.sm && <MobileNav />}
        <HomePageFooter />
      </Layout>
    </ThemeProvider>
  );
};

export default App;
