import React, { useState, FC, Dispatch, SetStateAction } from "react";
import { Modal } from "../UI";
import { dictAuth } from "./dictionary";
import Register from "./Register/Register";
import Login from "./Login/Login";
import AlertMessage from "../UI/AlertMessage/AlertMessage";
import ForgetPassword from "./ForgetPassword/ForgetPassword";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";

type Prop = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  type?: AuthType;
};

export type AuthType = "login" | "register" | "forgetPassword" | "resetPassword";

const Authentication: FC<Prop> = ({ isOpen, setIsOpen, type = "login" }) => {
  const [authType, setAuthType] = useState<AuthType>(type);
  const { user } = useCustomContext();

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isOpen) return null;

  return (
    <>
      <Modal
        onCancel={handleClose}
        withFooter={false}
        visible={isOpen}
        margin={type === "register"}
        width={"fit-content"}
        dir="rtl"
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              margin: "0 auto",
              direction: "rtl",
              color: "var(--secondary)",
            }}
          >
            <h2 style={{ color: "var(--primary)" }}>
              <span>{authType === "register" ? dictAuth.register.hebrew : dictAuth.login.hebrew}</span>
            </h2>
            <div>
              {dictAuth[(authType + "MeetingMessage") as "loginMeetingMessage" | "registerMeetingMessage"].hebrew}
            </div>
          </div>
        }
      >
        {authType === "login" && <Login setAuthType={setAuthType} handleClose={handleClose} />}

        {authType === "register" && <Register setAuthType={setAuthType} handleClose={handleClose} />}
        {authType === "forgetPassword" && <ForgetPassword setAuthType={setAuthType} handleClose={handleClose} />}
      </Modal>
      <AlertMessage message={user.isUser ? `Welcome ${user.fullName}` : ""} type="success" position="top" />
    </>
  );
};

export default Authentication;
