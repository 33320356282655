import { useState } from "react";
import { Typography } from "../../../components/UI";
import "./Tutorial.css";
import tutorial1 from "../../../assets/images/tutorial/desktopScreen-hp.png";
import AnimatedComponent from "../../../components/AnimatedComponent/AnimatedComponent";

const Tutorial = () => {
  const [isEnlargedImg, setIsEnlargedImg] = useState(false);

  const toggleEnlarge = () => {
    setIsEnlargedImg(!isEnlargedImg);
  };

  const drawCards = (startIndex: number, endIndex: number, cssClass: string) => {
    return TutorialCards.slice(startIndex, endIndex).map((c, i) => {
      return (
        <div
          key={i}
          className={`tutorial-card staggered-animation-3 staggered-animation animation-fade-in-${cssClass} animation-fade-in`}
        >
          <Typography.Title color="currentColor" level={2}>
            {c.title}
          </Typography.Title>
          <Typography.Paragraph>{c.text}</Typography.Paragraph>
        </div>
      );
    });
  };

  return (
    <AnimatedComponent
      animationClass="staggered-animation-3 animation-fade-in-translateY animation-fade-in"
      asContainer
    >
      <div className="hp-card flex-column flex-center flex-gap-20">
        <Typography.Title className="staggered-animation-4 animation-fade-in-translateY animation-fade-in">
          מה אנחנו מציעים?
        </Typography.Title>

        <div className="tutorial-body-wrapper">
          <div className="tutorial-body-left">{drawCards(0, 3, "translateX")}</div>
          <div
            className={`flex-center tutorial-img-wrapper ${
              isEnlargedImg ? "enlarged" : ""
            }  animation-fade-in-opacity animation-fade-in`}
          >
            <img
              onClick={toggleEnlarge}
              className={`tutorial-img ${isEnlargedImg ? "enlarged" : ""}`}
              src={tutorial1}
              alt="tutorial"
            />
          </div>
          <div className="tutorial-body-right">{drawCards(3, 6, "translateX-reverse")}</div>
        </div>
      </div>
    </AnimatedComponent>
  );
};

export default Tutorial;

const TutorialCards = [
  {
    title: "Site Version",
    text: "Registered users can select between different versions of the ui for better user experience",
    img: tutorial1,
  },
  {
    title: "Stores Select",
    text: "User select his preference stores , for better results we suggest select up to 5 stores.",
    img: tutorial1,
  },
  { title: "Sorting Cart", text: "Now you can sort your card according user experience", img: tutorial1 },
  {
    title: "Shopping List",
    text: "You can create shopping list , we will bring you all items that similar enough to your selection",
    img: tutorial1,
  },
  {
    title: "Comparison page",
    text: "Before checkout , you can see your selected cart compared to other stores carts, with different views.",
    img: tutorial1,
  },
  {
    title: "Replace Items",
    text: "If we can't find items in comparison page , you can replace it according your search.",
    img: tutorial1,
  },
];
