import { ReactNode, useCallback, useEffect, useRef, useState } from "react";
import TooltipPortal from "./TooltipPortal";
import "./Tooltip.css";
import { InfoIcon } from "../Icons";

export type TooltipPlacementType =
  | "top"
  | "bottom"
  | "left"
  | "right"
  | "top-right"
  | "top-left"
  | "bottom-right"
  | "bottom-left";

interface ToolTipFullProps {
  children?: ReactNode;
  title: string | ReactNode;
  placement?: TooltipPlacementType;
  maxWidth?: number;
  color?: string;
}

export type ChildrenRectPosition = {
  [K in keyof DOMRect]?: DOMRect[K];
};

export const tooltipSpacing = 6;

const Tooltip = ({ children, title, placement = "bottom", maxWidth, color }: ToolTipFullProps) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState<ChildrenRectPosition>({ top: 0, left: 0 });
  const [tooltipWidth, setTooltipWidth] = useState<number>(maxWidth || 0);
  const containerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const handlePlacement = useCallback(
    ({ top, height, width, right, left }: DOMRect) => {
      let calculatedTop = top;
      let calculatedLeft = left;

      switch (placement) {
        case "bottom":
          calculatedTop = top + height + tooltipSpacing;
          calculatedLeft = left + (width - tooltipWidth) / 2;
          break;
        case "top":
          calculatedTop = top - height - 3 * tooltipSpacing;
          calculatedLeft = left + (width - tooltipWidth) / 2;
          break;
        case "right":
          calculatedLeft = right + tooltipSpacing;
          calculatedTop = top - (height - tooltipSpacing) / 2;
          break;
        case "left":
          calculatedLeft = left - tooltipWidth - tooltipSpacing;
          calculatedTop = top - (height - tooltipSpacing) / 2;
          break;
        case "bottom-right":
          calculatedTop = top + height + tooltipSpacing;
          calculatedLeft = right + tooltipSpacing;
          break;
        case "bottom-left":
          calculatedTop = top + height + tooltipSpacing;
          calculatedLeft = left - tooltipWidth - tooltipSpacing;
          break;
        case "top-right":
          calculatedTop = top - height - 2 * tooltipSpacing;
          calculatedLeft = right - width;
          break;
        case "top-left":
          calculatedTop = top - height - 2 * tooltipSpacing;
          calculatedLeft = left - tooltipWidth + width;
          break;
        default:
          break;
      }

      if (calculatedLeft < 0) {
        return { top: calculatedTop, left: 10, width: calculatedLeft + tooltipWidth - tooltipSpacing };
      }

      return { top: calculatedTop, left: calculatedLeft };
    },
    [placement, tooltipWidth]
  );

  useEffect(() => {
    if (visible && tooltipWidth) {
      // Recalculate the position now that we have the correct width
      const domRect = containerRef.current!.getBoundingClientRect();
      const placementCalc = handlePlacement(domRect);
      setPosition(placementCalc);
    }
  }, [tooltipWidth, visible, handlePlacement]);

  const handleMouseEnter = (event: React.MouseEvent) => {
    setVisible(true);
  };

  const handleMouseLeaveContainer = (event: React.MouseEvent) => {
    const { relatedTarget } = event; // Get the element that the mouse entered

    if (
      containerRef.current &&
      tooltipRef.current &&
      relatedTarget instanceof Node &&
      !containerRef.current.contains(relatedTarget as Node) &&
      !tooltipRef.current.contains(relatedTarget as Node)
    ) {
      setVisible(false);
    }
  };

  return (
    <div ref={containerRef} className="ui-tooltip-container" onMouseLeave={handleMouseLeaveContainer}>
      <TooltipPortal
        placement={placement}
        tooltipRef={tooltipRef}
        visible={visible}
        position={position}
        maxWidth={maxWidth}
        setTooltipWidth={setTooltipWidth}
        color={color}
      >
        {title}
      </TooltipPortal>
      <div onMouseEnter={handleMouseEnter} className="ui-tooltip-children-wrapper">
        {children ? children : <InfoIcon />}
      </div>
    </div>
  );
};

export default Tooltip;
