import serverAPI from "../../../api/server";
import { checkTokenExpIsValid, parseJwt, updateUserContextFromSnakeToCamelCase } from "../../../utils/utils";
import { redirect } from "react-router-dom";

export const autoLoginNewUserLoader = async (req: any, contextValues: any) => {
  const token = req.params.token;

  const decoded = parseJwt(token);

  if (!decoded) return redirect("/");

  const isValid = checkTokenExpIsValid(decoded);

  if (!isValid) return redirect("/");

  const loginReq = await serverAPI("users/login", { email: decoded.email, password: decoded.password });

  if (loginReq.statusCode === 200) {
    const { setUser } = contextValues;
    delete decoded.password;

    const parsedUser = updateUserContextFromSnakeToCamelCase(decoded);
    setUser(parsedUser);
  }

  return redirect("/");
};
