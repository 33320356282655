import React, { useState, useReducer, Dispatch, SetStateAction, FC } from "react";
import serverAPI from "../../../api/server";
import InputUI from "../../UI/Input/Input";
import { Button } from "../../UI";
import "../Authentication.css";
import { EmailIcon, PasswordIcon } from "../../UI/Icons";
import { AuthenticationFormReducer, AuthenticationInitialState } from "../AuthReducer/AuthReducer";
import { dictAuth } from "../dictionary";

import { updateUserContextFromSnakeToCamelCase } from "../../../utils/utils";
import { AuthType } from "../Authentication";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";

const language = "hebrew";

type Props = {
  setAuthType: Dispatch<SetStateAction<AuthType>>;
  handleClose: () => void;
};

const Login: FC<Props> = ({ setAuthType, handleClose }) => {
  const { setUser } = useCustomContext();
  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await serverAPI("users/login", { email: formState.email, password: formState.password });
      if (res.statusCode === 200) {
        setResponseMessage("Login successful!");
        const user = updateUserContextFromSnakeToCamelCase(res.data.user);
        user.isUser = true;
        setUser(user);

        handleClose();
      } else if (res.error) {
        setResponseMessage(res.error.message);
      }
    } catch (error) {
      console.log(error);
      setResponseMessage("Login failed. Please check your credentials.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <InputUI
            id="email"
            htmlFor="email"
            name="SET_EMAIL"
            required
            autoComplete="current-email"
            type="email"
            language={language}
            label={dictAuth.email[language]}
            value={formState.email}
            onChange={handleInputChange}
            icon={<EmailIcon size={"50%"} color="var(--primary)" />}
          />
        </div>
        <div className="form-group">
          <InputUI
            id="password"
            name="SET_PASSWORD"
            htmlFor="password"
            required
            autoComplete="current-password"
            type="password"
            language={language}
            label={dictAuth.password[language]}
            value={formState.password}
            onChange={handleInputChange}
            icon={<PasswordIcon size={"55%"} color="var(--primary)" />}
          />
        </div>
        <div className="form-group">
          <Button htmlType="submit" loading={loading}>
            {dictAuth.login[language]}
          </Button>
        </div>

        <p className="redirect-to">
          <span onClick={() => setAuthType("register")}>{dictAuth.notAMember[language]}</span>
          <span onClick={() => setAuthType("forgetPassword")}>{dictAuth.forgotPassword[language]}</span>
        </p>
      </form>
      {responseMessage && <p className="response-message">{responseMessage}</p>}
    </div>
  );
};

export default Login;
