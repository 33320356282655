import { FC, useState } from "react";
import { useLoaderData } from "react-router-dom";
import Tabs, { TabItems } from "../UI/Tab/Tab";
import { Button } from "../UI";
import CheckoutDisclaimer from "./CheckoutDisclaimer/CheckoutDisclaimer";
import CheckoutStoresData from "./CheckoutStoresData/CheckoutStoresData";
import { SiteContextType } from "../../hooks/useCustomContext/useCustomContext";
// import CheckoutComparisonProducts from "./CheckoutComparisonProducts/CheckoutComparisonProducts";

//! ON HOLD STATUS

type Props = {
  test?: string;
};

const CheckoutCart: FC<Props> = ({ test }) => {
  const data = useLoaderData();
  const { user, cart, siteVersion, onlineStores } = data as SiteContextType;
  const [disableTab, setDisableTab] = useState<1 | undefined>(1);

  const tabItems: TabItems = [
    { label: "הסבר", children: <CheckoutDisclaimer user={user} cart={cart} onlineStores={onlineStores} /> },
    // { label: "השוואת מוצרים", children: <CheckoutComparisonProducts />, visible: siteVersion === 2 },
    { label: "השוואת מוצרים", children: <div>CheckoutComparisonProducts ON PROGRESS</div>, visible: siteVersion === 2 },
    { label: "סיכום עגלה", children: <CheckoutStoresData cart={cart} user={user} onlineStores={onlineStores} /> },
  ];

  const footer = () => {
    if (!disableTab) return null;
    return (
      <Button onClick={() => setDisableTab(undefined)} width={"30%"}>
        קראתי והבנתי
      </Button>
    );
  };

  return (
    <div style={{ height: "95%", width: "95%", margin: "auto" }}>
      <Tabs
        currentTab={disableTab ? disableTab - 1 : 1}
        //   title={"title"}
        items={tabItems}
        footer={footer()}
        disableTab={disableTab}
        height="100%"
      />
    </div>
  );
};

export default CheckoutCart;
