import { FC, useState, InputHTMLAttributes, ReactNode } from "react";
import "./Input.css"; // Import your CSS file
import { Language } from "../../../types/types";
import { resolveDirClass } from "../../../utils/utils";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  onBlur?: () => void;
  onFocus?: () => void;
  label: string;
  htmlFor?: string;
  icon?: ReactNode;
  language?: Language;
  textMessage?: string | ReactNode;
  required?: boolean;
};

const InputUI: FC<Props> = ({
  language = "hebrew",
  label,
  onFocus,
  onBlur,
  htmlFor,
  value,
  icon,
  textMessage,
  required,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    onFocus && onFocus();
    setIsFocused(true);
  };

  const handleBlur = () => {
    onBlur && onBlur();
    setIsFocused(false);
  };

  const hasValue = value !== undefined && value !== "";

  const handleAutoFill = (e: any) => {
    setIsFocused(e.animationName === "onAutoFillStart");
  };

  return (
    <div className={`floating-label-text-field ${isFocused || hasValue ? "focused" : ""} `}>
      <input
        className={resolveDirClass(language)}
        style={{
          paddingLeft: ["hebrew", "arabic"].includes(language) ? "50px" : "15px",
          paddingRight: ["english", "russian"].includes(language) ? "50px" : "15px",
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onAnimationStart={handleAutoFill}
        value={value}
        {...rest}
      />
      <label className={resolveDirClass(language)} htmlFor={htmlFor}>
        {required ? "*" + label : label}
      </label>
      {icon && <span className={`${!["hebrew", "arabic"].includes(language) ? "rtl-icon" : "ltr-icon"}`}>{icon}</span>}
      <div
        style={{
          textAlign: "center",
          minHeight: "1rem",
          padding: "3px",
          position: window.innerWidth > 1300 ? "absolute" : "sticky",
          top: "100%",
          width: "300px",
          margin: "auto",
        }}
      >
        {textMessage}
      </div>
    </div>
  );
};

export default InputUI;
