import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

const Deli: FC<IconCustom> = ({ onClick, size = iconSizeDefault, hovered }) => {
  return (
    <div>
      <svg
        onClick={onClick}
        height={size}
        width={size}
        viewBox="0 -10 55 55.384"
        xmlSpace="preserve"
        fill={hovered ? "#000000" : "var(--iconC)"}
      >
        <g strokeWidth="0"></g>
        <g strokeLinecap="round" strokeLinejoin="round"></g>
        <g>
          <g>
            <path
              fill={hovered ? "#965E5E" : "var(--iconB)"}
              d="M5.384,17.192c0-0.639,0.066-1.263,0.168-1.874l-4.279-3.209c-0.609-0.457-1.449,0.122-1.24,0.854 l1.267,4.433c0.055,0.191,0.036,0.396-0.053,0.573l-0.958,1.915c-0.323,0.646,0.313,1.352,0.989,1.099l4.32-1.62 C5.461,18.658,5.384,17.934,5.384,17.192z"
            ></path>
            <path
              fill={hovered ? "#773F3A" : "var(--iconA)"}
              strokeWidth={1}
              stroke={hovered ? "#000000" : "var(--iconC)"}
              d="M45.884,28.692h-29c-6.325,0-11.5-5.175-11.5-11.5v0c0-6.325,5.175-11.5,11.5-11.5h29 c6.325,0,9.5,5.175,9.5,11.5v0C55.384,23.517,52.209,28.692,45.884,28.692z"
            ></path>
            <path
              fill={hovered ? "#C56458" : "var(--iconA)"}
              strokeWidth={1}
              stroke={hovered ? "#000000" : "var(--iconC)"}
              d="M55.384,17.192c0,6.351-3.149,11.5-9.5,11.5s-9.5-5.149-9.5-11.5s3.149-11.5,9.5-11.5 S55.384,10.841,55.384,17.192z"
            ></path>
            <g>
              <path
                fill={hovered ? "#CBA598" : "var(--iconB)"}
                d="M47.274,17.593c-0.431,0.008-0.785,0.418-0.785,0.916s0.355,0.882,0.785,0.858 c0.423-0.023,0.76-0.433,0.76-0.915C48.034,17.97,47.697,17.585,47.274,17.593z"
              ></path>
              <path
                fill={hovered ? "#CBA598" : "var(--iconC)"}
                d="M41.177,19.706c-0.548,0.03-1,0.511-1,1.074s0.452,0.977,1,0.926 c0.537-0.05,0.964-0.529,0.964-1.072S41.714,19.676,41.177,19.706z"
              ></path>
              <path
                fill={hovered ? "#CBA598" : "var(--iconC)"}
                d="M41.177,12.706c0.537,0.04,0.964-0.368,0.964-0.911c0-0.542-0.427-1.03-0.964-1.089 c-0.548-0.061-1,0.345-1,0.907S40.629,12.666,41.177,12.706z"
              ></path>
              <path
                fill={hovered ? "#CBA598" : "var(--iconC)"}
                d="M48.771,21.862c-0.404,0.045-0.737,0.473-0.737,0.955s0.333,0.824,0.737,0.764 c0.397-0.059,0.714-0.485,0.714-0.952C49.485,22.162,49.168,21.818,48.771,21.862z"
              ></path>
              <path
                fill={hovered ? "#CBA598" : "var(--iconC)"}
                d="M44.838,21.367c-0.96,0.089-1.766,1.028-1.766,2.094c0,1.066,0.806,1.795,1.766,1.635 c0.925-0.154,1.65-1.082,1.65-2.078C46.489,22.022,45.763,21.281,44.838,21.367z"
              ></path>
              <path
                fill={hovered ? "#CBA598" : "var(--iconC)"}
                d="M45.677,12.123c0.895,0.083,1.597-0.646,1.597-1.626c0-0.98-0.702-1.891-1.597-2.04 c-0.928-0.155-1.707,0.564-1.707,1.612C43.97,11.116,44.749,12.037,45.677,12.123z"
              ></path>
              <path
                fill={hovered ? "#CBA598" : "var(--iconA)"}
                d="M41.177,16.706c0,1.104,0.865,1.968,1.895,1.93c0.991-0.037,1.766-0.9,1.766-1.93 c0-1.03-0.776-1.893-1.766-1.93C42.042,14.738,41.177,15.601,41.177,16.706z"
              ></path>
              <path
                fill={hovered ? "#CBA598" : "var(--iconB)"}
                d="M49.131,12.443c-0.599-0.055-1.096,0.485-1.096,1.208s0.497,1.328,1.096,1.35 c0.584,0.022,1.047-0.521,1.047-1.211S49.715,12.497,49.131,12.443z"
              ></path>
            </g>
            <path
              fill={hovered ? "#965E5E" : "var(--iconA)"}
              d="M34.311,12.692H15.853c-0.553,0-1-0.447-1-1s0.447-1,1-1h18.458c0.553,0,1,0.447,1,1 S34.863,12.692,34.311,12.692z"
            ></path>
            <path
              fill={hovered ? "#965E5E" : "var(--iconA)"}
              d="M36.048,9.692H25.853c-0.553,0-1-0.447-1-1s0.447-1,1-1h10.195c0.553,0,1,0.447,1,1 S36.601,9.692,36.048,9.692z"
            ></path>
            <path
              fill={hovered ? "#965E5E" : "var(--iconA)"}
              d="M34.327,23.692H23.111c-0.553,0-1-0.447-1-1s0.447-1,1-1h11.216c0.553,0,1,0.447,1,1 S34.88,23.692,34.327,23.692z"
            ></path>
            <path
              fill={hovered ? "#965E5E" : "var(--iconC)"}
              d="M36.048,26.692H25.962c-0.553,0-1-0.447-1-1s0.447-1,1-1h10.086c0.553,0,1,0.447,1,1 S36.601,26.692,36.048,26.692z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default memo(Deli);
