import React from "react";
import "./PriceChartInfo.css";
import { Language } from "../../../../types/types";
import { resolveDirClass } from "../../../../utils/utils";
import { InfoBox } from "../../../../components/UI";

const dict = {
  intro: {
    hebrew:
      "עבור כל קטגוריה , בחרנו את כל הפריטים המשותפים בכל החנויות המוצגות ובדקנו מי החנות הזולה ביותר עבור השוואה זו.",
    english:
      "For each category, we selected all the common items in all the displayed stores and checked which store is the cheapest for this comparison.",
    russian:
      "Для каждой категории мы выбрали все общие товары во всех представленных магазинах и проверили, какой из них самый дешевый для этого сравнения.",
    arabic:
      "بالنسبة لكل فئة، اخترنا جميع العناصر المشتركة في جميع المتاجر المعروضة وتحققنا من المتجر الأرخص لهذه المقارنة.",
  },
  intro2: {
    hebrew: "אם הקנייה המתוכננת שלכם תתרכז בקטגוריה מסויימת , אז אולי ייטב עבורכם לבחור את החנות הזולה שמצאנו עבורכם.",
    english:
      "If your planned purchase focuses on a specific category, it might be better for you to choose the cheapest store we've found for you.",
    arabic: "إذا كان تركيز شرائك المخطط له على فئة معينة، فقد يكون من الأفضل لك اختيار المتجر الأرخص الذي وجدناه لك.",
    russian:
      "Если ваша запланированная покупка сосредоточена на определенной категории, вам может быть лучше выбрать самый дешевый магазин, который мы нашли для вас.",
  },
  update: {
    hebrew: "שימו לב שהמידע מתעדכן מידי יום על פי הפריטים שנמצאו כמשותפים בלבד",
    english: "Please note that the information is updated daily based on items that were found to be common only.",
    russian:
      "Обратите внимание, что информация обновляется ежедневно на основе товаров, которые были найдены как общие.",
    arabic: "يرجى ملاحظة أن المعلومات يتم تحديثها يوميًا بناءً على العناصر التي تم العثور عليها فقط.",
  },
  extra: {
    hebrew: "המידע מבוסס על נתונים שנאספים אוטומטית מהחנויות השונות וייתכנו שינויים בזמן אמת.",
    english:
      "The information is based on data automatically collected from the various stores and there may be real-time changes.",
    russian:
      "Информация основана на данных, автоматически собираемых из различных магазинов, и могут происходить изменения в реальном времени.",
    arabic: "تستند المعلومات إلى بيانات يتم جمعها تلقائيًا من المتاجر المختلفة وقد تكون هناك تغييرات في الوقت الفعلي.",
  },
};

const PriceChartInfo = ({ language }: { language: Language }) => {
  return (
    <div className={`price-chart-info-body ${resolveDirClass(language)}`}>
      <InfoBox language={language} type="info" content={dict.intro[language]} />
      <InfoBox language={language} type="success" content={dict.intro2[language]} />
      <InfoBox language={language} type="warning" content={dict.update[language]} />
      <InfoBox language={language} type="gray" content={dict.extra[language]} />
    </div>
  );
};

export default PriceChartInfo;
