import { Dispatch, SetStateAction } from "react";
import serverAPI from "../../../api/server";
import { ProductDataType, StoreEn } from "../../../types/types";
import OnlineStoresMapper from "../../../hooks/useOnlineStores";

type Args = {
  category: string;
  favoriteStore: StoreEn;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  // setProducts: Dispatch<SetStateAction<ProductDataType[]>>;
};

const fetchProductsV2 = async ({ category, favoriteStore, setIsLoading }: Args) => {
  try {
    setIsLoading(() => true);
    const favoriteStoreObj = OnlineStoresMapper.convertOnlineStoreToObj(favoriteStore);
    const { data, statusCode } = await serverAPI(
      `products/common/v2/get/?category=${category}&store=${favoriteStoreObj.name}&id=${favoriteStoreObj.id}` as any,
      "GET",
      { description: "success" }
    );
    if (statusCode === 200 && data.length) {
      return fromBErawDataToFE(data);
    } else {
      // TODO : create popup that explain
      return [];
    }

    // todo if === 401 popup login
  } catch (e: any) {
    console.log(e);
    return [];
    // throw new Response("xxx");
  } finally {
    setIsLoading(() => false);
    // console.log("fetch products");
  }
};

export default fetchProductsV2;

export const fromBErawDataToFE = (rawData: ProductDataType[]) => {
  if (Object.keys(rawData[0]).includes("rami")) {
    const ramiDomain = "https://img.rami-levy.co.il";
    return rawData.map((d) => {
      return { ...d, rami: { ...d.rami, img_url: ramiDomain + d.rami.img_url } };
    });
  }
  return rawData;
};
