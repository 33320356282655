import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { User, Cart, OnlineStoresType } from "../../types/types";
import { parseCookies } from "../../utils/utils";
import csrfToken from "../../models/Tokens/CsrfToken";
import { themeType } from "../../components/UI/ThemeProvider/ThemeProvider";
import useLocalStorage, { localStorageKeys } from ".././useLocalStorage";
import { getUserInitialValue, onlineStoresInitialValue } from "./helpers/initialValues";

export interface SiteContextType {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  cart: Cart;
  setCart: Dispatch<SetStateAction<Cart>>;
  children?: JSX.Element;
  siteVersion: 1 | 2;
  setSiteVersion: Dispatch<SetStateAction<1 | 2>>;
  theme: themeType;
  setTheme: Dispatch<SetStateAction<themeType>>;
  isCartOpen: boolean;
  setIsCartOpen: Dispatch<SetStateAction<boolean>>;
  setIsShoppingListOpen: Dispatch<SetStateAction<boolean>>;
  isShoppingListOpen: boolean;
  onlineStores: OnlineStoresType;
  setOnlineStores: Dispatch<SetStateAction<OnlineStoresType>>;
}

const token = parseCookies("uDwAs.ev.dd");

export const SiteContext = createContext<SiteContextType>({} as SiteContextType);

export const SiteProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>(getUserInitialValue(token));
  const [cart, setCart] = useState<Cart>({});
  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isShoppingListOpen, setIsShoppingListOpen] = useState(false);
  const [siteVersion, setSiteVersion] = useState<1 | 2>(2);
  const [theme, setTheme] = useState<themeType>("");

  const [onlineStores, setOnlineStores] = useLocalStorage(localStorageKeys.userOnlineStores, onlineStoresInitialValue);

  // CSRF
  const [isCsrfTokenSet, setIsCsrfTokenSet] = useState(false);

  const initializeCsrfToken = useCallback(async () => {
    await csrfToken.setToken(user.email, user.isUser);
    setIsCsrfTokenSet(true);
  }, [user.email, user.isUser]);

  useEffect(() => {
    initializeCsrfToken();
  }, [initializeCsrfToken]);

  if (!isCsrfTokenSet) return null;

  return (
    <SiteContext.Provider
      value={{
        user,
        setUser,
        cart,
        setCart,
        siteVersion,
        setSiteVersion,
        theme,
        setTheme,
        setIsCartOpen,
        isCartOpen,
        setIsShoppingListOpen,
        isShoppingListOpen,
        onlineStores,
        setOnlineStores,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

const useCustomContext = () => useContext(SiteContext);
export default useCustomContext;
