import React from "react";
import "./MyScore.css";

const MyScore = () => {
  return (
    <div>
      <h1>MyScore</h1>
      <p>הנקודות שצברתי</p>
      <p>איך לצבור נקודות</p>
      <p>איך זה עוזר לי כמשתמש</p>
      <p>להראות ממה צברתי נקודות</p>
    </div>
  );
};

export default MyScore;
