import { useEffect, useState, useRef } from "react";
import "../Shared/Products.css";
import { useLocation } from "react-router-dom";
import { ProductDataType } from "../../../types/types";
import { SideBar, SkeletonScreen } from "../../../components/UI";
import Cart from "../../../components/Cart/Cart";
import ScrollTopBtn from "../../../components/UI/ScrollTopBtn/ScrollTopBtn";
import fetchProductsV2 from "./fetch_products_v2";
import drawProducts from "../Shared/DrawProducts";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import ProductsFilter from "../../../components/ProductsFilter/ProductsFilter";
import { FilterOptionsState } from "../../../components/ProductsFilter/helpers/types";
import {
  initFiltersState,
  productsFilterCB,
  productsFilterInitialState,
} from "../../../components/ProductsFilter/helpers/utils";

const ProductsV2 = () => {
  const location = useLocation();
  const {
    user: { language },
    onlineStores: { favoriteStore },
    isCartOpen,
  } = useCustomContext();
  const [products, setProducts] = useState<ProductDataType[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const [filterOptions, setFilterOptions] = useState(productsFilterInitialState);
  const category = encodeURIComponent(`${location.pathname}`.replace("/products/", ""));

  useEffect(() => {
    const fetchAndInit = async () => {
      const data = await fetchProductsV2({ category, favoriteStore, setIsLoading });
      const fillFilterOptions: FilterOptionsState = initFiltersState(data, favoriteStore);
      setFilterOptions(fillFilterOptions);
      setProducts(data);
    };

    fetchAndInit();
  }, [category, favoriteStore]);

  return (
    <>
      <div style={{ display: "flex" }}>
        <ProductsFilter key={category} setFilterOptions={setFilterOptions} filterOptions={filterOptions} />
        <div ref={ref} className={`product-main-container ${isCartOpen ? "responsive-hidden" : ""}`}>
          {isLoading ? (
            <SkeletonScreen cssClass="product_card_wrapper_vvv" />
          ) : (
            drawProducts(
              favoriteStore,
              products.filter((p) => productsFilterCB(favoriteStore, p, filterOptions)),
              "auto",
              language,
              2
            )
          )}
        </div>
        <ScrollTopBtn targetElementRef={ref} />

        <SideBar isOpen={isCartOpen}>
          <Cart />
        </SideBar>
      </div>
    </>
  );
};

export default ProductsV2;
