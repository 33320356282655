import { ChangeEvent, FC } from "react";
import "./Checkbox.css";

interface IPropsCheckbox {
  label: string;
  dir?: "rtl" | "ltr";
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement> | undefined) => void;
}

const Checkbox: FC<IPropsCheckbox> = ({ label, dir = "rtl", onChange, checked }) => {
  return (
    <div className={`checkbox-wrapper-4 ${dir}`}>
      <input className="inp-cbx" id={`checkbox-${label}`} type="checkbox" checked={checked} onChange={onChange} />
      <label className="cbx" htmlFor={`checkbox-${label}`}>
        <span>
          <svg width="12px" height="10px">
            <use xlinkHref="#check-4"></use>
          </svg>
        </span>
        <span>{label}</span>
      </label>
      <svg className="inline-svg">
        <symbol id="check-4" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </div>
  );
};

export default Checkbox;
