import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from "react";
import ExtraInformation from "./ExtraInformation/ExtraInformation";
import SelectedItemImages from "./SelectedItemImages/SelectedItemImages";
import { Language, ProductDataType, StoreEn } from "../../types/types";
import { dictStores } from "../../dictionary/dictStores";
import { dictButtonsAndActions } from "../../dictionary/dictButtons";
import { SelectSearch, Modal, ImageComponent, Button } from "../UI";
import "./SelectedItem.css";
import { directionByLanguage } from "../../utils/utils";
import serverAPI from "../../api/server";
import Promotion from "../ProductCard/Promotions/Promotion";
import { PromoIcon } from "../UI/Icons";
import { ItemFeatureType } from "../Cart/CartItem/CartItem";
import CartModel from "../../models/Cart/CartModel";
import ItemNotFoundDescription from "./ItemNotFoundDescription/ItemNotFoundDescription";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import OnlineStoresMapper from "../../hooks/useOnlineStores";

type Props = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedProductProp: ProductDataType;
  setSelectedProductProp?: Dispatch<SetStateAction<ProductDataType>>;
  stores: StoreEn[];
  language: Language;
  featureType?: ItemFeatureType;
  selectedStore: StoreEn;
};

const SelectedItem: FC<Props> = ({
  isOpen,
  setIsOpen,
  selectedProductProp,
  stores,
  language,
  featureType = "normal",
  selectedStore,
  setSelectedProductProp,
}) => {
  const [storeSelected, setStoreSelected] = useState<StoreEn>(selectedStore);
  const [tempProductDataState, seTempProductDataState] = useState(selectedProductProp);
  const {
    setCart,
    cart,
    onlineStores: { favoriteStore },
    siteVersion,
  } = useCustomContext();

  const [itemCode, setItemCode] = useState("");

  useEffect(() => {
    seTempProductDataState(selectedProductProp);
    setItemCode(selectedProductProp[favoriteStore as StoreEn].item_code);
  }, [selectedProductProp, favoriteStore]);

  const getDebounceData = useMemo(() => {
    if (featureType === "comparison_cart") {
      const body = {
        store: OnlineStoresMapper.convertOnlineStoreToObj(storeSelected),
        searchValues: "",
        mCode: 1,
      };

      return {
        body: body,
        searchKey: "searchValues",
        getOptionsFromApi: async (): Promise<any> => {
          const res: { data: ProductDataType[] } = await serverAPI("products/common/v2/get", body);
          return res.data.map((i: any) => {
            return { value: i[storeSelected].item_code, label: i[storeSelected].item_name, data: i };
          });
        },
      };
    } else {
      const body = {
        favoriteStore: OnlineStoresMapper.convertOnlineStoreToObj(storeSelected),
        otherStores: [],
        ilikeValue: "",
        // similarityValue: "",
        category: selectedProductProp[storeSelected]?.sub_category,
        isWeighted: false,
        joinByItemCode: true,
        similarityScore: 0.3,
        joinBySimilarity: false,
      };

      return {
        body: body,
        searchKey: "ilikeValue",
        getOptionsFromApi: async (): Promise<any> => {
          const res: { data: ProductDataType[] } = await serverAPI("products/common/get", body);
          return res.data.map((i: any) => {
            return { value: i[storeSelected].item_code, label: i[storeSelected].item_name, data: i };
          });
        },
      };
    }
  }, [storeSelected, featureType, selectedProductProp]);

  const placeHolder = useMemo(() => {
    const favoriteProductName = tempProductDataState[favoriteStore].item_name;
    const productName = tempProductDataState[storeSelected]?.item_name ?? favoriteProductName;
    return {
      hebrew: ` ניתן לחפש ב-${dictStores[storeSelected][language]} ולהחליף ${productName} במוצר דומה לבחירתכם בחנות (${favoriteProductName})`,
      english: `Search in ${dictStores[storeSelected][language]} and replace ${productName} with other similar product`,
      russian: `Ищите в ${dictStores[storeSelected][language]} и замените ${productName} другим продуктом`,
      arabic: `ابحث في ${dictStores[storeSelected][language]} واستبدل ${productName} بمنتج آخر`,
    };
  }, [storeSelected, tempProductDataState, favoriteStore, language]);

  const handleSelectedFromSearch = (selectedItem: ProductDataType) => {
    if (isOpen && selectedItem) {
      const newSelectedItemData = selectedItem[storeSelected];
      newSelectedItemData.source = "FE-user-select";
      seTempProductDataState((prev) => ({ ...prev, [storeSelected]: newSelectedItemData }));
    }
  };

  const handleCloseModal = () => {
    seTempProductDataState(() => selectedProductProp);
    setIsOpen(false);
  };

  const handleAddToCart = () => {
    setCart((prev) => CartModel.addToCart(prev, itemCode, tempProductDataState));
    setSelectedProductProp && setSelectedProductProp(tempProductDataState);
    setIsOpen(false);
  };

  const handleRemoveFromCart = () => {
    setCart((prev) => CartModel.removeItem(prev, itemCode));
  };

  const drawSelectSearchLabel = (option: { value: string; label: string; data: ProductDataType }) => {
    const imgUrl = option.data[storeSelected].img_url;
    const price = option.data[storeSelected].item_price;
    const name = option.label;

    return (
      <span className="main-search-results">
        <span>
          <ImageComponent src={imgUrl} alt={option.value} width={40} height={40} />
        </span>
        <span>&nbsp; {name}</span>
        <span className="scrollable" style={{ maxHeight: "100px", overflow: "auto" }}>
          <Promotion
            language={language}
            productData={option.data}
            allStores={stores}
            type="search"
            title={<PromoIcon color="var(--primary-color)" />}
          />
        </span>
        {
          <span className="main-search-price-range">
            &nbsp; {price} <span>&#8362;</span>
          </span>
        }
      </span>
    );
  };

  return (
    <Modal
      visible={isOpen}
      onCancel={handleCloseModal}
      onOk={itemCode in cart ? handleRemoveFromCart : handleAddToCart}
      okText={dictButtonsAndActions[itemCode in cart ? "removeFromCart" : "addToCart"][language]}
      cancelText={dictButtonsAndActions.cancel[language]}
      loading={false}
      title={tempProductDataState[storeSelected]?.item_name ?? tempProductDataState[favoriteStore].item_name}
      dir={directionByLanguage(language)}
    >
      <div className="selected_item_wrapper">
        {/* We render select search only if it version 1 or using comparison cart feature . (both cases we not do so for favorite store) */}
        {storeSelected !== favoriteStore && (siteVersion === 1 || featureType === "comparison_cart") && (
          <div style={{ display: "flex", width: "100%", margin: "0.5rem 0", direction: "rtl", alignItems: "center" }}>
            <SelectSearch
              getDebounceData={getDebounceData}
              resetProps={[storeSelected]}
              placeholder={placeHolder[language]}
              handleSelected={handleSelectedFromSearch}
              Label={drawSelectSearchLabel}
            />
          </div>
        )}

        <div className="selected_item_meta_wrapper">
          {tempProductDataState[storeSelected] && (
            <ExtraInformation productData={tempProductDataState} store={storeSelected} language={language} />
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              height: "100%",
              alignItems: "center",
            }}
          >
            {tempProductDataState[storeSelected]?.item_code !== selectedProductProp[storeSelected]?.item_code && (
              <Button variant="success" width={"95%"} onClick={handleAddToCart}>
                אישור החלפת מוצר
              </Button>
            )}
            {!tempProductDataState[storeSelected] && (
              <ItemNotFoundDescription
                language={language}
                storeName={dictStores[selectedStore][language]}
                itemName={tempProductDataState[favoriteStore].item_name}
              />
            )}
            {tempProductDataState[storeSelected] && (
              <SelectedItemImages
                front={tempProductDataState}
                stores={stores}
                setCurrentStore={setStoreSelected}
                currentStore={storeSelected}
                /*Selected item images differ in versions. v1 show carousel , v2 not. but with comparison_cart we want show carousel also on v1 */
                siteVersion={featureType === "comparison_cart" ? 2 : siteVersion}
              />
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SelectedItem;
