import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from "react";

export type themeType = " theme-dark" | "";

interface InitializeContext {
  theme: themeType;
  setTheme?: Dispatch<SetStateAction<themeType>>;
}

export const tokenInit: InitializeContext = {
  theme: "",
};

const ThemeContext = createContext<InitializeContext>(tokenInit);

const ThemeProvider = ({ children, token }: { children: ReactNode; token?: InitializeContext }) => {
  const [theme, setTheme] = useState(token?.theme || tokenInit.theme);

  useEffect(() => {
    if (token) setTheme(token.theme);
  }, [token]);

  return <ThemeContext.Provider value={{ theme: theme, setTheme }}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;

export const useToken = () => useContext(ThemeContext);
