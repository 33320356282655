import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

interface IconCustomExtra extends IconCustom {
  both?: boolean;
}

export const IconEggs: FC<IconCustom> = ({ size, hovered }) => {
  return (
    <svg
      height={size}
      width={size}
      version="1.1"
      id="Layer_1"
      viewBox="0 0 550 511.999"
      xmlSpace="preserve"
      fill={hovered ? "#000000" : "var(--iconA)"}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth="5"
          fill={hovered ? "#CCD1D9" : "var(--iconA)"}
          d="M437.434,124.943c51.951,30.562,72.279,92.381,45.404,138.075 c-26.889,45.688-90.795,57.968-142.746,27.406S236.469,179.598,263.36,133.903C290.234,88.209,385.498,94.389,437.434,124.943z"
        ></path>
        <g opacity="0.2">
          <path
            fill={hovered ? "#FFFFFF" : "var(--iconA)"}
            d="M437.434,124.943c-28.203-16.593-69.17-25.992-105.045-23.804 c29.969,1.727,60.982,10.438,83.717,23.804c51.953,30.562,72.279,92.381,45.391,138.075 c-15.094,25.672-41.891,40.797-71.672,43.688c37.922,3.609,74.359-12,93.014-43.688 C509.713,217.324,489.385,155.505,437.434,124.943z"
          ></path>
        </g>
        <path
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth="5"
          fill={hovered ? "#FFA77B" : "var(--iconB)"}
          d="M367.311,209.449c-26.109,54.327-85.998,79.756-133.794,56.795 c-47.78-22.96-65.358-85.607-39.265-139.934C220.36,71.983,296.016,13.766,343.795,36.718 C391.574,59.679,393.402,155.122,367.311,209.449z"
        ></path>
        <g opacity="0.2">
          <path
            fill={hovered ? "#FFFFFF" : "var(--iconA)"}
            d="M343.795,36.718c-10.516-5.055-22.391-6.164-34.781-4.258c4.672,0.812,9.172,2.203,13.453,4.258 c47.781,22.96,49.609,118.404,23.516,172.73c-17.75,36.937-51.123,60.514-85.701,64.6c41.529,5.031,85.623-20.069,107.029-64.6 C393.402,155.122,391.574,59.679,343.795,36.718z"
          ></path>
        </g>
        <path
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth="2"
          fill={hovered ? "#E6E9ED" : "var(--iconB)"}
          d="M206.892,249.604c-25.421,54.647-84.998,80.818-133.06,58.444 c-48.077-22.359-66.42-84.788-40.999-139.435S133.19,54.819,181.252,77.186C229.313,99.553,232.329,194.957,206.892,249.604z"
        ></path>
        <g opacity="0.3">
          <path
            fill={hovered ? "#FFFFFF" : "var(--iconB)"}
            d="M181.252,77.186c-10.359-4.82-21.953-5.843-34.031-3.961c4.391,0.781,8.641,2.07,12.703,3.961 c48.061,22.367,51.077,117.771,25.64,172.418c-17.468,37.554-51.061,61.647-85.967,65.803 c41.905,5.094,86.279-20.624,107.294-65.803C232.329,194.957,229.313,99.553,181.252,77.186z"
          ></path>
        </g>
        <path
          fill={hovered ? "#FC6E51" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={5}
          d="M368.217,459.092c148.824-64.967,143.762-255.971,143.762-255.971 c0-11.781-9.547-21.336-21.328-21.336H21.365c-11.781,0-21.344,9.555-21.344,21.336c0,0-5.062,191.004,143.778,255.971H368.217z"
        ></path>
        <g opacity="0.1">
          <path d="M21.365,203.121c0-11.781,9.546-21.336,21.328-21.336H21.365c-11.781,0-21.344,9.555-21.344,21.336 c0,0-5.062,191.004,143.778,255.971h21.328C16.287,394.125,21.365,203.121,21.365,203.121z"></path>
        </g>
        <path
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth="1"
          fill={hovered ? "#FFA77B" : "var(--iconA)"}
          d="M511.51,371.297c0,60.265-42.982,109.123-95.998,109.123c-53.014,0-95.982-48.858-95.982-109.123 c0-60.279,42.969-145.513,95.982-145.513C468.527,225.784,511.51,311.018,511.51,371.297z"
        ></path>
        <g opacity="0.2">
          <path
            stroke={hovered ? "#000000" : "var(--iconC)"}
            strokeWidth={5}
            fill={hovered ? "#FFFFFF" : "var(--iconA)"}
            d="M415.512,225.784c-3.609,0-7.156,0.406-10.656,1.164c47.984,10.328,85.326,88.178,85.326,144.349 s-37.342,102.42-85.326,108.451c3.5,0.438,7.047,0.672,10.656,0.672c53.016,0,95.998-48.858,95.998-109.123 C511.51,311.018,468.527,225.784,415.512,225.784z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export const IconMilk: FC<IconCustom> = ({ size, hovered }) => {
  return (
    <svg
      height={size}
      width={size}
      viewBox="0 0 400 512"
      xmlSpace="preserve"
      fill={hovered ? "#000000" : "var(--iconB)"}
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <g>
          <g>
            <polygon
              fill={hovered ? "#8BB9C8" : "var(--iconB)"}
              stroke={hovered ? "#000000" : "var(--iconC)"}
              strokeWidth={5}
              points="195.038,137.409 195.038,212.812 0,156.057 92.234,106.372 "
            ></polygon>
            <polygon
              stroke={hovered ? "#000000" : "var(--iconC)"}
              strokeWidth={5}
              fill={hovered ? "#FFFFFF" : "var(--iconA)"}
              points="390.083,455.235 195.06,511.974 195.06,212.789 390.083,156.049 "
            ></polygon>
            <polygon
              stroke={hovered ? "#000000" : "var(--iconC)"}
              strokeWidth={5}
              fill={hovered ? "#5CB0D1" : "var(--iconB)"}
              points="390.083,455.235 195.06,511.974 195.06,364.084 390.083,307.345 "
            ></polygon>
            <polygon
              stroke={hovered ? "#000000" : "var(--iconC)"}
              strokeWidth={5}
              fill={hovered ? "#92CAE1" : "var(--iconA)"}
              points="287.249,49.666 92.226,106.405 195.06,212.789 390.083,156.049 "
            ></polygon>
            <polygon
              stroke={hovered ? "#000000" : "var(--iconC)"}
              strokeWidth={5}
              fill={hovered ? "#FFFFFF" : "var(--iconA)"}
              points="287.272,0 287.272,49.685 92.234,106.372 92.234,56.754 277.78,2.76 "
            ></polygon>
            <polygon
              stroke={hovered ? "#000000" : "var(--iconC)"}
              strokeWidth={5}
              fill={hovered ? "#EAE9E9" : "var(--iconB)"}
              points="195.038,212.812 195.038,512 25.987,462.787 0,455.246 0,156.057 "
            ></polygon>
            <polygon
              fill={hovered ? "#3498CB" : "var(--iconB)"}
              points="195.038,364.089 195.038,512 25.987,462.787 0,455.246 0,307.335 "
            ></polygon>
          </g>
          <g>
            <g>
              <path
                fill={hovered ? "#E67386" : "var(--iconA)"}
                d="M253.341,256.438l-13.465,31.912l-13.532-24.102l-10.031,2.828v51.974l10.166-2.962v-30.229 l9.964,16.764l6.867-1.953l9.897-22.554v30.229l10.166-2.894v-51.907L253.341,256.438z"
              ></path>
              <path
                fill={hovered ? "#E67386" : "var(--iconA)"}
                d="M275.491,250.042v51.907l10.099-2.962v-51.906L275.491,250.042z"
              ></path>
              <path
                fill={hovered ? "#E67386" : "var(--iconA)"}
                d="M307.806,283.502v-42.818l-10.099,2.895v51.907l33.662-9.694v-9.089L307.806,283.502z"
              ></path>
              <path
                fill={hovered ? "#E67386" : "var(--iconA)"}
                d="M357.357,247.013l17.168-25.651l-12.32,3.568l-18.245,27.939v-22.62l-10.166,2.894v51.907 l10.166-2.895v-15.619l6.665-9.964l13.33,19.793l11.849-3.434L357.357,247.013z"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

const DiaryAndEggs: FC<IconCustomExtra> = ({ both = true, onClick, hovered, size = iconSizeDefault }) => {
  return (
    <div
      onClick={onClick}
      style={{
        display: "flex",
        alignItems: "center",
        marginBottom: "5px",
        marginLeft: 15,
        position: "relative",
      }}
    >
      <IconMilk size={size} hovered={hovered} />

      {both && (
        <span style={{ position: "absolute", left: -25 }}>
          <IconEggs size={size} hovered={hovered} />
        </span>
      )}
    </div>
  );
};

export default memo(DiaryAndEggs);
