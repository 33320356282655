import { useEffect, useState } from "react";
import "./CartComparisonCarouselView.css";
import { Button, CarouselFull } from "../../../../components/UI";
import { dictStores } from "../../../../dictionary/dictStores";
import { StoreEn } from "../../../../types/types";
import { getMissingItems, getNormalizingStorePrice } from "../../Helpers/Utils";
import { cartComparisonExplain } from "../../Helpers/dict";
import { DrawFooterBox, drawCartItems } from "../../Helpers/Ui";
import useCustomContext from "../../../../hooks/useCustomContext/useCustomContext";
import PriceCalculator from "../../../../models/ItemPriceCalculator/PriceCalculator";

interface ICartComparisonCarouselViewProps {
  handleSelectStore: (store: StoreEn) => void;
}

const CartComparisonCarouselView = ({ handleSelectStore }: ICartComparisonCarouselViewProps) => {
  const {
    cart,
    user: { language },
    onlineStores: { favoriteStore, otherStores, hasClubCard },
  } = useCustomContext();

  const [carouselIndex, setCarouselIndex] = useState(0);
  const [missingItems, setMissingItems] = useState<string[]>([]);

  const calculateStoreTotalPrice = (store: StoreEn) => {
    return PriceCalculator.getTotalCartCost(store, cart);
  };

  // Normalizing the favorite cart per founded items only.
  const calculateFavoriteFakeCartAccordingCurrentStore = (normalizingStore: StoreEn) => {
    // removing products that not exist in normalizingStore
    return getNormalizingStorePrice(normalizingStore, favoriteStore, cart, hasClubCard, "string");
  };

  useEffect(() => {
    setMissingItems(getMissingItems(cart, favoriteStore, otherStores[carouselIndex]));
  }, [carouselIndex, cart, favoriteStore, otherStores]);

  return (
    <div className="cart-comparison_carouselView_wrapper">
      <div className="cart-comparison_cart_wrapper">
        <h2 className="cart-comparison_cart_header">{dictStores[favoriteStore][language]}</h2>
        <div style={{ flex: 1 }}>{drawCartItems(cart, favoriteStore)}</div>
        <h2 className="cart-comparison_cart_footer">
          <DrawFooterBox
            toolTipTitle={`סה"כ עלות העגלה שלי שמילאתי לפי ${dictStores[favoriteStore][language]}`}
            description={cartComparisonExplain.hebrew.originalTotal}
            price={calculateStoreTotalPrice(favoriteStore).toFixed(2)}
          />
          {!missingItems?.length ? null : (
            <DrawFooterBox
              toolTipTitle={cartComparisonExplain.hebrew.normalTotalFavoriteTooltip(
                favoriteStore,
                otherStores[carouselIndex]
              )}
              description={cartComparisonExplain.hebrew.normalTotal}
              price={calculateFavoriteFakeCartAccordingCurrentStore(otherStores[carouselIndex]) as string}
            />
          )}

          <Button onClick={() => handleSelectStore(favoriteStore)}>Go To</Button>
        </h2>
      </div>
      <div className="cart-comparison_cart_wrapper">
        <h2 className="cart-comparison_cart_header">{dictStores[otherStores[carouselIndex]][language]}</h2>
        <CarouselFull
          arrowsCss="cart-comparison-carousel-custom-fixed-btn"
          infinite
          onSliding={(s) => setCarouselIndex(s)}
          containerStyle={{ flex: 1 }}
        >
          {otherStores.map((s, index) => (
            <div key={index}>{drawCartItems(cart, s)}</div>
          ))}
        </CarouselFull>
        <h2 className="cart-comparison_cart_footer">
          {!missingItems.length ? null : (
            <DrawFooterBox
              toolTipTitle={`פריטים שחסרים בחנות זו :\n ${missingItems.join(", \n")}`}
              description={`חסרים ${missingItems.length} פריטים`}
            />
          )}
          <DrawFooterBox
            toolTipTitle="explain about real price "
            description={cartComparisonExplain.hebrew.originalTotal}
            price={calculateStoreTotalPrice(otherStores[carouselIndex]).toFixed(2)}
          />
          <Button onClick={() => handleSelectStore(otherStores[carouselIndex])}>Go To</Button>
        </h2>
      </div>
    </div>
  );
};

export default CartComparisonCarouselView;
