import { Language } from "../../types/types";

export type subCategory = {
  key: string;
  id: string;
  [key: string]: string;
};

export interface CategoriesAndSub {
  [category: string]: {
    category: {
      [lang in Language]: string;
    };
    subCategories: subCategory[];
  };
}
const CategoriesMap: CategoriesAndSub = {
  "Fresh Produce": {
    category: {
      hebrew: "ירקות ופירות",
      english: "Fresh Produce",
      arabic: "المنتجات الطازجة",
      russian: "Фрукты и овощи",
    },
    subCategories: [
      {
        key: "Platters & Sandwiches",
        id: "4",
        english: "Platters & Sandwiches",
        hebrew: "מגשי אירוח וסנדוויצ'ים ",
        russian: "Подносы с закусками и сэндвичи",
        arabic: "أطباق وشطائر",
      },
      {
        key: "Fresh Vegetables",
        id: "1",
        english: "Fresh Vegetables",
        hebrew: "ירקות",
        russian: "Свежие овощи",
        arabic: "خضراوات طازجة",
      },
      {
        key: "Fresh Fruit",
        id: "2",
        english: "Fresh Fruit",
        hebrew: "פירות",
        russian: "Свежие фрукты",
        arabic: "فواكه طازجة",
      },
      {
        key: "Dried Fruit & Nut",
        id: "3",
        english: "Dried Fruit & Nut",
        hebrew: "פירות יבשים ואגוזים",
        russian: "Сухофрукты",
        arabic: "فواكه مجففة ومكسرات",
      },
    ],
  },
  "Dairy  & Eggs": {
    category: {
      hebrew: "מוצרי חלב וביצים",
      english: "Dairy  & Eggs",
      arabic: "منتجات الألبان والبيض",
      russian: "Молочное и яйца",
    },
    subCategories: [
      {
        key: "Milk & Cream",
        id: "6",
        english: "Milk & Cream",
        hebrew: "חלב ומשקאות חלב",
        russian: "Молоко и сливки",
        arabic: "حليب وكريمة",
      },
      {
        key: "Cheese & Butter",
        id: "7",
        english: "Cheese & Butter",
        hebrew: "גבינות וחמאה",
        russian: "Сыр и масло",
        arabic: "أجبان وزبدة",
      },
      {
        key: "Heavy Cream & Sour Cream",
        id: "9",
        english: "Heavy Cream & Sour Cream",
        hebrew: "לבן אשל ושמנת",
        russian: "Простокваша и сметана",
        arabic: "كريمة كثيفة وكريمة حامضة",
      },
      {
        key: "Refrigerated",
        id: "10",
        english: "Refrigerated",
        hebrew: "עוגות ופסטה בקירור",
        russian: "Холодильник",
        arabic: "منتجات مثلجة",
      },
      {
        key: "Deli Cheese",
        id: "11",
        english: "Deli Cheese",
        hebrew: "מעדניית גבינות",
        russian: "Deli Cheese",
        arabic: "ديلي الجبن",
      },
      {
        key: "Yogurt & Puddings",
        id: "8",
        english: "Yogurt & Puddings",
        hebrew: "יוגורט ומעדנים",
        russian: "Йогурты и десерты",
        arabic: "زبادي وحلويات",
      },
      {
        key: "Eggs",
        id: "5",
        english: "Eggs",
        hebrew: "ביצים",
        russian: "Яйца",
        arabic: "بيض",
      },
    ],
  },
  "Cereal & Breakfast": {
    category: {
      hebrew: "דגנים",
      english: "Cereal & Breakfast",
      arabic: "الحبوب ووجبات الإفطار",
      russian: "Сухой завтрак",
    },
    subCategories: [
      {
        key: "Cereal",
        id: "21",
        english: "Cereal",
        hebrew: "דגני בוקר",
        russian: "Сухой завтрак",
        arabic: "حبوب",
      },
      {
        key: "Breakfast",
        id: "22",
        english: "Breakfast",
        hebrew: "דגנים",
        russian: "Злаки",
        arabic: "الإفطار",
      },
      {
        key: "Cereal & Energy Bars",
        id: "23",
        english: "Cereal & Energy Bars",
        hebrew: "חטיפי דגנים ואנרגיה",
        russian: "Батончики",
        arabic: "الحبوب وأصابع الطاقة",
      },
    ],
  },
  "Meat, Chicken & Fish": {
    category: {
      hebrew: "עוף בשר ודגים",
      english: "Meat, Chicken & Fish",
      arabic: "اللحوم والدجاج والأسماك",
      russian: "Мясо, рыба и птица",
    },
    subCategories: [
      {
        key: "Meat",
        id: "18",
        english: "Meat",
        hebrew: "בשר טרי",
        russian: "Свежее мясо",
        arabic: "لحم",
      },
      {
        key: "Fish",
        id: "20",
        english: "Fish",
        hebrew: "דגים",
        russian: "Рыба",
        arabic: "أسماك",
      },
      {
        key: "Frozen Chicken & Turkey",
        id: "17",
        english: "Chicken & Turkey",
        hebrew: "עוף והודו קפוא",
        russian: "Свежая птица",
        arabic: "دجاج وديك رومي",
      },
      {
        key: "Frozen Meat",
        id: "19",
        english: "Frozen Meat",
        hebrew: "בשר קפוא",
        russian: "Замороженное мясо",
        arabic: "لحوم مجمدة",
      },
      {
        key: "Chicken & Turkey",
        id: "16",
        english: "Chicken & Turkey",
        hebrew: "עוף והודו טרי",
        russian: "Свежая птица",
        arabic: "دجاج وديك رومي",
      },
    ],
  },
  Grocery: {
    category: {
      hebrew: "שימורים בישול ואפיה",
      english: "Grocery",
      arabic: "منتجات البقالة",
      russian: "Бакалея",
    },
    subCategories: [
      {
        key: "Canned Food & Sauces",
        id: "25",
        english: "Canned Food & Sauces",
        hebrew: "שימורים ורטבים",
        russian: "Консервация и соусы",
        arabic: "المعلبات والصلصات",
      },
      {
        key: "Basic Products & Spices",
        id: "24",
        english: "Basic Products & Spices",
        hebrew: "מוצרי יסוד ותבלינים",
        russian: "Основные продукты и специи",
        arabic: "المنتجات الأساسية والتوابل",
      },
      {
        key: "Honey, Jams & Spreads",
        id: "28",
        english: "Honey, Jams & Spreads",
        hebrew: "דבש, ריבות, ממרחים",
        russian: "Мёд, варенье и спреды",
        arabic: "العسل والمربى وطعام الدهن",
      },
      {
        key: "Baking Needs",
        id: "27",
        english: "Baking Needs",
        hebrew: "מוצרי אפיה",
        russian: "Всё для выпечки",
        arabic: "مستلزمات الخبز",
      },
      {
        key: "Cooking Essentials",
        id: "26",
        english: "Cooking Essentials",
        hebrew: "בישול",
        russian: "Крупы и макаронные изделия",
        arabic: "مكونات الطبخ الأساسية",
      },
    ],
  },
  "Deli & Salads": {
    category: {
      hebrew: "מעדניה סלטים ונקניקים",
      english: "Deli & Salads",
      arabic: "وجبات لذيذة وسلطات",
      russian: "Деликатесы и салаты",
    },
    subCategories: [
      {
        key: "Deli",
        id: "31",
        english: "Deli",
        hebrew: "מעדנייה",
        russian: "Гастроном",
        arabic: "وجبات لذيذة",
      },
      {
        key: "W Packaged Salami & Pastrami",
        id: "32",
        english: "Packaged Salami & Pastrami",
        hebrew: "נקניקים ופסטרמות במשקל",
        russian: "Колбасные изделия",
        arabic: "سجق و بسطرمة",
      },
      {
        key: "Packaged Salads",
        id: "29",
        english: "Packaged Salads",
        hebrew: "סלטים ארוזים",
        russian: "Фасованные салаты",
        arabic: "سلطات مغلفة",
      },
      {
        key: "Packaged Salami & Pastrami",
        id: "30",
        english: "Packaged Salami & Pastrami",
        hebrew: "נקניקים ופסטרמות",
        russian: "Колбасные изделия",
        arabic: "سجق و بسطرمة",
      },
      {
        key: "Prepared Foods",
        id: "33",
        english: "Prepared Foods",
        hebrew: "אוכל מוכן",
        russian: "Готовые блюда",
        arabic: "طعام جاهز",
      },
    ],
  },
  Bakery: {
    category: {
      hebrew: "לחמים עוגות ועוגיות",
      english: "Bakery",
      arabic: "المخبوزات",
      russian: "Пекарня",
    },
    subCategories: [
      {
        key: "Bread",
        id: "12",
        english: "Bread",
        hebrew: "לחם",
        russian: "Хлеб",
        arabic: "خبز",
      },
      {
        key: "Bakery",
        id: "15",
        english: "Bakery",
        hebrew: "מאפיה",
        russian: "Сдобные изделия",
        arabic: "مخبوزات",
      },
      {
        key: "Rice Cakes & Crackers",
        id: "14",
        english: "Rice Cakes & Crackers",
        hebrew: "קרקר, פריכיות ופתית",
        russian: "Крекеры",
        arabic: "كعك الأرز والمقرمشات",
      },
      {
        key: "Cookies & Cakes",
        id: "13",
        english: "Bakery",
        hebrew: "עוגות ועוגיות",
        russian: "Сдобные изделия",
        arabic: "مخبوزات",
      },
    ],
  },
  Frozen: {
    category: {
      hebrew: "קפואים",
      english: "Frozen",
      arabic: "مجمدات",
      russian: "Морозилка",
    },
    subCategories: [
      {
        key: "Ice Cream & Ices",
        id: "37",
        english: "Ice Cream & Ices",
        hebrew: "גלידה וקרחונים",
        russian: "Мороженое",
        arabic: "آيس كريم ومثلجات",
      },
      {
        key: "Frozen Pastry Goods",
        id: "34",
        english: "Frozen Pastry Goods",
        hebrew: "בצקים",
        russian: "Продукция из теста",
        arabic: "منتجات معجنات مجمدة",
      },
      {
        key: "Frozen Fruit and Vegetables",
        id: "35",
        english: "Frozen Fruit and Vegetables",
        hebrew: "פירות וירקות קפואים",
        russian: "Замороженные фрукты и овощи",
        arabic: "فواكه وخضروات مجمدة",
      },
      {
        key: "Ready Made Foods",
        id: "36",
        english: "Ready Made Foods",
        hebrew: "אוכל מוכן הכנה מהירה",
        russian: "Готовые блюда",
        arabic: "طعام جاهز",
      },
    ],
  },
  "Sweets & Snacks": {
    category: {
      hebrew: "חטיפים וממתקים",
      english: "Sweets & Snacks",
      arabic: "حلويات ووجبات خفيفة",
      russian: "Сладости, чипсы и снеки",
    },
    subCategories: [
      {
        key: "Snacks",
        id: "49",
        english: "Snacks",
        hebrew: "חטיפים",
        russian: "Снеки",
        arabic: "وجبات خفيفة",
      },
      {
        key: "Candy & Sweets",
        id: "50",
        english: "Candy & Sweets",
        hebrew: "ממתקים",
        russian: "Сладости",
        arabic: "حلويات",
      },
    ],
  },
  "Beverages & Wine": {
    category: {
      hebrew: "משקאות ויין",
      english: "Beverages & Wine",
      arabic: "مشروبات ونبيذ",
      russian: "Напитки и алкоголь",
    },
    subCategories: [
      {
        key: "Wine & Alcohol",
        id: "40",
        english: "Wine & Alcohol",
        hebrew: "יינות ואלכוהול",
        russian: "Вина и алкоголь",
        arabic: "النبيذ والكحول",
      },
      {
        key: "Soft Drinks",
        id: "38",
        english: "Soft Drinks",
        hebrew: "משקאות קלים",
        russian: "Лёгкие напитки",
        arabic: "المشروبات الغازية",
      },
      {
        key: "Hot Drinks",
        id: "39",
        english: "Hot Drinks",
        hebrew: "משקאות חמים",
        russian: "Горячие напитки",
        arabic: "المشروبات الساخنة",
      },
    ],
  },
  "Beauty & Baby": {
    category: {
      hebrew: "פארם ותינוקות",
      english: "Beauty & Baby",
      arabic: "الصحة والتجميل",
      russian: "Косметика и уход",
    },
    subCategories: [
      {
        key: "Baby Accessories",
        id: "62",
        english: "Baby Accessories",
        hebrew: "אביזרים לתינוק",
        russian: "Аксессуары",
        arabic: "ملحقات الأطفال",
      },
      {
        key: "Personal Care",
        id: "58",
        english: "Personal Care",
        hebrew: "פרפומריה ",
        russian: "Косметика и парфюмерия",
        arabic: "رعاية شخصية",
      },
      {
        key: "Oral Care",
        id: "56",
        english: "Oral Care",
        hebrew: "היגיינת הפה",
        russian: "Гигиена полости рта",
        arabic: "نظافة الفم",
      },
      {
        key: "Baby Hygiene",
        id: "60",
        english: "Baby Hygiene",
        hebrew: "היגיינה לתינוק",
        russian: "Детская гигиена",
        arabic: "نظافة الطفل",
      },
      {
        key: "Hygiene",
        id: "57",
        english: "Hygiene",
        hebrew: "מוצרי היגיינה",
        russian: "Гигиена",
        arabic: "منتجات نظافة شخصية",
      },
      {
        key: "Bath",
        id: "55",
        english: "Bath",
        hebrew: "רחצה",
        russian: "Для душа",
        arabic: "استحمام",
      },
      {
        key: "First Aid & Medicine",
        id: "59",
        english: "First Aid & Medicine",
        hebrew: "עזרה ראשונה ותרופות",
        russian: "Первая помощь и аптечка",
        arabic: "الإسعافات الأولية والأدوية",
      },
      {
        key: "Baby Food",
        id: "61",
        english: "Baby Food",
        hebrew: "מזון לתינוק",
        russian: "Детское питание",
        arabic: "أغذية الأطفال",
      },
    ],
  },
  "Health & Specialty Diets": {
    category: {
      hebrew: "בריאות ותזונה",
      english: "Health & Specialty Diets",
      arabic: "الصحة والأنظمة الغذائية المتخصصة",
      russian: "Здоровое питание",
    },
    subCategories: [
      {
        key: "Dairy Substitutes",
        id: "43",
        english: "Dairy Substitutes",
        hebrew: "תחליפי חלב",
        russian: "Растительные напитки",
        arabic: "بدائل الحليب",
      },
      {
        key: "Plant-Based Beverages and Snacks",
        id: "41",
        english: "Plant-Based Beverages and Snacks",
        hebrew: "משקאות ומעדנים צמחיים",
        russian: "Напитки и растительные десерты",
        arabic: " مشروبات وحلويات نباتية",
      },
      {
        key: "Vitamins & Supplements",
        id: "47",
        english: "Vitamins & Supplements",
        hebrew: "ויטמינים ותוספים",
        russian: "Витамины и добавки",
        arabic: "الفيتامينات والمكملات الغذائية",
      },
      {
        key: "Soy, Tofu, Seitan, and Cheese Alternatives",
        id: "42",
        english: "Soy, Tofu, Seitan, and Cheese Alternatives",
        hebrew: "מוצרי סויה, טופו, סייטן ותחליפי גבינה",
        russian: "Заменители сои, тофу, сейтана и сыра",
        arabic: "بدائل الصويا، التوفو، السيتان، والجبن",
      },
      {
        key: "Gluten Free",
        id: "45",
        english: "Gluten Free",
        hebrew: "ללא גלוטן",
        russian: "Безглютеновая продукция",
        arabic: "خالي من الغلوتين",
      },
      {
        key: "Sugar Free",
        id: "46",
        english: "Sugar Free",
        hebrew: "דיאט וללא סוכר",
        russian: "Продукция без добавления сахара",
        arabic: "خالي من السكر",
      },
      {
        key: "Special Diets",
        id: "48",
        english: "Special Diets",
        hebrew: "תזונה מיוחדת",
        russian: "Специализированное питание",
        arabic: "أنظمة غذائية خاصة",
      },
      {
        key: "Meat Substitutes",
        id: "44",
        english: "Meat Substitutes",
        hebrew: "תחליפי בשר",
        russian: "Заменители мяса",
        arabic: "بدائل اللحوم",
      },
    ],
  },
  Cleaning: {
    category: {
      hebrew: "ניקיון",
      english: "Cleaning",
      arabic: "نظافة",
      russian: "Бытовая химия",
    },
    subCategories: [
      {
        key: "Cleaning Accessories",
        id: "54",
        english: "Cleaning Accessories",
        hebrew: "אביזרי ניקיון",
        russian: "Хозяйственные товары",
        arabic: "ملحقات نظافة",
      },
      {
        key: "Household Cleaning",
        id: "51",
        english: "Household Cleaning",
        hebrew: "חומרי ניקוי לבית",
        russian: "Всё для уборки",
        arabic: "مواد تنظيف منزلية",
      },
      {
        key: "Tissue & Paper Towels",
        id: "52",
        english: "Tissue & Paper Towels",
        hebrew: "נייר טואלט, טישו ומגבונים לחים",
        russian: "Туалетная бумага и салфетки",
        arabic: "مناديل ومناشف ورقية",
      },
      {
        key: "Laundry & Ironing",
        id: "53",
        english: "Laundry & Ironing",
        hebrew: "כביסה וגיהוץ",
        russian: "Всё для стирки",
        arabic: "غسيل وكي",
      },
    ],
  },
  "Houseware & Leisure": {
    category: {
      hebrew: "כלי בית ופנאי",
      english: "Houseware & Leisure",
      arabic: "أدوات منزلية وترفيه",
      russian: "Товары для дома и отдыха",
    },
    subCategories: [
      {
        key: "Home Care",
        id: "64",
        english: "Home Care",
        hebrew: "מוצרים לבית",
        russian: "Инвентарь для дома",
        arabic: "المنتجات المنزلية",
      },
      {
        key: "Kitchen Accessories",
        id: "65",
        english: "Kitchen Accessories",
        hebrew: "אביזרי מטבח",
        russian: "Кухонные пренадлежности",
        arabic: "ملحقات المطبخ",
      },
      {
        key: "Disposables",
        id: "63",
        english: "Disposables",
        hebrew: "חד פעמי",
        russian: "Одноразовая посуда",
        arabic: "للاستعمال مرة واحدة",
      },
      {
        key: "Pets",
        id: "66",
        english: "Pets",
        hebrew: "בעלי חיים",
        russian: "Зоотовары",
        arabic: "الحيوانات الأليفة",
      },
      {
        key: "Electronics",
        id: "70",
        english: "Electronics",
        hebrew: "חשמל",
        russian: "Электроника",
        arabic: "الأجهزة الإلكترونية",
      },
      {
        key: "Textiles",
        id: "69",
        english: "Textiles",
        hebrew: "טקסטיל",
        russian: "Текстиль",
        arabic: "النسيج",
      },
      {
        key: "Leisure",
        id: "68",
        english: "Leisure",
        hebrew: "פנאי",
        russian: "Всё для отдыха",
        arabic: "الترفيه",
      },
      {
        key: "Festive Decorations",
        id: "71",
        english: "Festive Decorations",
        hebrew: "קישוטים לחג",
        russian: "Праздничные украшения",
        arabic: "زينة العيد",
      },
    ],
  },
};

export default CategoriesMap;
