import { IconCustom } from "../types";
import { FC, memo } from "react";
import { iconSizeDefault } from "../../../../utils/constants";

const BabyPharm: FC<IconCustom> = ({ onClick, size = iconSizeDefault, hovered }) => {
  return (
    <svg
      onClick={onClick}
      height={size}
      width={size}
      viewBox="0 0 712.00 512.00"
      fill={hovered ? "black" : "var(--iconC)"}
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          fill={hovered ? "#F3437F" : "var(--iconA)"}
          d="M56.405,113.7l0.024-74.773c0-15.712,12.741-28.456,28.456-28.456h174.515 c15.715,0,28.456,12.745,28.456,28.456l-0.024,74.773H56.405z"
        ></path>
        <path
          fill={hovered ? "#FFD41D" : "var(--iconA)"}
          d="M292.024,501.53c23.082,0,41.792-18.71,41.792-41.792c0,0,0-222.544,0-263.292 c0-52.24-41.792-82.746-41.792-82.746H52.24c0,0-41.792,30.505-41.792,82.746c0,40.748,0,263.292,0,263.292 c0,23.082,18.71,41.792,41.792,41.792H292.024z"
        ></path>
        <path d="M172.132,33.911c-5.77,0-10.448,4.679-10.448,10.448v32.794c0,5.769,4.678,10.448,10.448,10.448 c5.77,0,10.448-4.679,10.448-10.448V44.359C182.58,38.589,177.903,33.911,172.132,33.911z"></path>
        <path d="M136.608,33.911c-5.77,0-10.448,4.679-10.448,10.448v32.794c0,5.769,4.678,10.448,10.448,10.448 c5.77,0,10.448-4.679,10.448-10.448V44.359C147.056,38.589,142.378,33.911,136.608,33.911z"></path>
        <path d="M101.083,33.911c-5.77,0-10.448,4.679-10.448,10.448v32.794c0,5.769,4.678,10.448,10.448,10.448s10.448-4.679,10.448-10.448 V44.359C111.531,38.589,106.854,33.911,101.083,33.911z"></path>
        <path d="M243.181,33.911c-5.77,0-10.448,4.679-10.448,10.448v32.794c0,5.769,4.678,10.448,10.448,10.448 c5.77,0,10.448-4.679,10.448-10.448V44.359C253.629,38.589,248.952,33.911,243.181,33.911z"></path>
        <path d="M207.657,33.911c-5.77,0-10.448,4.679-10.448,10.448v32.794c0,5.769,4.678,10.448,10.448,10.448 c5.77,0,10.448-4.679,10.448-10.448V44.359C218.105,38.589,213.427,33.911,207.657,33.911z"></path>
        <g>
          <ellipse
            fill={hovered ? "#FCDDC9" : "var(--iconA)"}
            cx="268.484"
            cy="295.232"
            rx="25.023"
            ry="26.882"
          ></ellipse>
          <ellipse
            fill={hovered ? "#FCDDC9" : "var(--iconA)"}
            cx="75.78"
            cy="295.232"
            rx="25.023"
            ry="26.882"
          ></ellipse>
          <circle fill={hovered ? "#FCDDC9" : "var(--iconA)"} cx="172.132" cy="295.232" r="95.036"></circle>
        </g>
        <path
          fill={hovered ? "#1EB615" : "var(--iconB)"}
          d="M410.552,171.319V34.034c0-12.939,10.487-23.424,23.423-23.424l0,0 c12.935,0.001,23.423,10.485,23.42,23.419l0.002,137.29L410.552,171.319L410.552,171.319z"
        ></path>
        <path
          fill={hovered ? "#FFFFFF" : "var(--iconA)"}
          d="M433.975,325.269c4.268,0,8.443,0.327,12.493,0.97V171.319h-24.983v154.917 C425.532,325.594,429.705,325.269,433.975,325.269z"
        ></path>
        <path
          fill={hovered ? "#F1F2F2" : "var(--iconA)"}
          d="M366.399,398.469c-0.001-48.672,30.253-73.2,67.576-73.2c37.321,0,67.576,24.529,67.577,73.2 c-0.001,48.673-30.257,103.06-67.576,103.061C396.654,501.53,366.399,447.144,366.399,398.469z"
        ></path>
        <path d="M468.125,430.396c-0.564,0-1.135-0.046-1.709-0.141c-5.694-0.936-9.55-6.313-8.612-12.006 c0.742-4.507,1.118-8.983,1.118-13.305c0-21.54-9.326-34.4-24.946-34.4c-5.77,0-10.448-4.679-10.448-10.448 c0-5.769,4.678-10.448,10.448-10.448c22.818,0,45.842,17.098,45.842,55.296c0,5.456-0.469,11.074-1.396,16.698 C477.58,426.762,473.148,430.396,468.125,430.396z"></path>
        <path d="M489.143,336.225c-8.737-8.727-19.786-14.967-32.226-18.381V181.767h0.482c0.007,0,0.014,0,0.021,0 c5.771,0,10.448-4.679,10.448-10.448c0-0.224-0.007-0.446-0.021-0.667L467.844,34.03c0.001-9.047-3.522-17.553-9.919-23.95 c-6.397-6.396-14.902-9.918-23.949-9.919c-18.676,0-33.871,15.195-33.871,33.872v137.286c0,5.769,4.678,10.448,10.448,10.448h0.484 v136.075c-12.443,3.415-23.493,9.654-32.229,18.382c-14.953,14.938-22.856,36.461-22.856,62.244 c0,53.581,33.368,113.509,78.025,113.509l0,0c44.654-0.001,78.022-59.928,78.023-113.509 C511.999,372.686,504.096,351.162,489.143,336.225z M421,34.034c0-7.155,5.821-12.975,12.973-12.975c3.466,0,6.724,1.35,9.176,3.8 c2.45,2.45,3.799,5.707,3.798,9.171l0.002,126.842h-0.482h-24.983H421L421,34.034L421,34.034z M431.932,181.767h4.087v133.098 c-0.681-0.016-1.358-0.045-2.045-0.045c-0.685,0-1.361,0.029-2.043,0.045V181.767z M472.883,462.737 c-11.192,18.012-25.374,28.345-38.908,28.345c-13.535,0-27.718-10.331-38.91-28.344c-11.408-18.359-18.219-42.385-18.219-64.269 c0-20.117,5.784-36.529,16.727-47.461c10.014-10.003,23.985-15.292,40.4-15.292c35.237,0,57.128,24.046,57.129,62.752 C491.103,420.353,484.292,444.378,472.883,462.737z"></path>
        <path d="M195.948,448.768c-0.016,0-0.029,0-0.045,0l-0.244-0.001c-5.769-0.024-10.428-4.722-10.404-10.492 c0.024-5.755,4.697-10.404,10.447-10.404c0.016,0,0.029,0,0.045,0l0.244,0.001c5.77,0.024,10.428,4.72,10.404,10.492 C206.371,444.119,201.697,448.768,195.948,448.768z"></path>
        <path d="M298.285,105.335l0.022-66.409c0-21.452-17.452-38.904-38.904-38.904H84.887c-21.452,0-38.904,17.452-38.904,38.904v66.407 C43.058,107.493,0,140.287,0,196.446v263.292c0,28.805,23.435,52.24,52.24,52.24h239.784c28.805,0,52.24-23.435,52.24-52.24V196.446 C344.264,140.307,301.237,107.517,298.285,105.335z M66.878,38.926c0-9.93,8.078-18.008,18.008-18.008h174.515 c9.93,0,18.008,8.078,18.008,18.005l-0.021,64.328H66.878V38.926z M292.024,491.082H52.24c-17.283,0-31.344-14.061-31.344-31.344 V196.446c0-39.056,26.711-65.116,35.049-72.298h0.484h231.404h0.509c8.369,7.143,35.025,32.988,35.025,72.298v231.425h-93.62 c-5.77,0-10.448,4.679-10.448,10.448s4.678,10.448,10.448,10.448h93.621v10.97C323.368,477.021,309.307,491.082,292.024,491.082z"></path>
        <path d="M270.821,257.987c-15.084-39.841-53.63-68.244-98.689-68.245c-45.059,0.001-83.605,28.405-98.688,68.245 c-18.474,1.269-33.138,17.482-33.138,37.241s14.664,35.971,33.138,37.24c15.084,39.842,53.628,68.245,98.688,68.246 c45.062-0.001,83.607-28.405,98.691-68.246c18.474-1.271,33.137-17.482,33.137-37.24 C303.959,275.47,289.295,259.257,270.821,257.987z M61.201,295.228c0-5.602,2.503-10.554,6.311-13.521 c-0.568,4.428-0.864,8.941-0.864,13.521c0,4.58,0.295,9.093,0.864,13.52C63.704,305.782,61.201,300.831,61.201,295.228z M172.131,379.818c-46.642-0.001-84.588-37.947-84.588-84.59c0-32.619,18.57-60.966,45.684-75.074 c-0.786,3.131-1.208,6.405-1.208,9.775c0,18.874,15.357,34.231,34.232,34.231c0.001,0,0.002,0,0.003,0 c7.64,0,14.808-2.495,20.185-7.026c5.99-5.047,9.289-12.141,9.289-19.974c0-11.731-6.322-20.719-16.5-23.458 c-5.57-1.5-11.305,1.801-12.804,7.373c-1.5,5.572,1.801,11.305,7.373,12.805c0.855,0.231,1.034,1.911,1.034,3.281 c0,1.634-0.608,2.94-1.859,3.994c-1.59,1.34-4.039,2.108-6.72,2.108h-0.001c-7.354,0-13.337-5.982-13.337-13.335 c0-10.632,8.65-19.282,19.282-19.282c0.033,0,0.066-0.005,0.099-0.005c46.566,0.091,84.424,37.999,84.425,84.586 C256.721,341.87,218.775,379.817,172.131,379.818z M276.753,308.748c0.568-4.428,0.864-8.94,0.864-13.52 c0-4.58-0.295-9.093-0.864-13.52c3.806,2.967,6.31,7.919,6.31,13.52C283.063,300.83,280.559,305.779,276.753,308.748z"></path>
        <path d="M203.284,265.507c-8.719,0-16.872,3.889-22.368,10.67c-3.634,4.482-2.945,11.061,1.537,14.695 c1.936,1.568,4.262,2.331,6.573,2.331c3.041,0,6.058-1.322,8.122-3.869c1.511-1.863,3.747-2.932,6.136-2.932 s4.626,1.068,6.136,2.932c3.634,4.483,10.213,5.17,14.695,1.538c4.482-3.634,5.171-10.213,1.538-14.695 C220.158,269.394,212.004,265.507,203.284,265.507z"></path>
        <path d="M147.115,289.334c2.065,2.548,5.08,3.869,8.122,3.869c2.311,0,4.638-0.763,6.574-2.331 c4.482-3.635,5.171-10.213,1.537-14.695c-5.496-6.781-13.649-10.67-22.369-10.67c-8.72,0-16.873,3.889-22.369,10.67 c-3.634,4.482-2.945,11.062,1.538,14.695c4.482,3.632,11.061,2.945,14.696-1.538c1.51-1.863,3.747-2.932,6.136-2.932 C143.369,286.403,145.604,287.471,147.115,289.334z"></path>
        <path d="M196.859,312.259c-5.77,0-10.448,4.679-10.448,10.448c0,7.873-6.405,14.278-14.278,14.278 c-7.875,0-14.28-6.406-14.28-14.278c0-5.769-4.678-10.448-10.448-10.448s-10.448,4.679-10.448,10.448 c0,19.396,15.781,35.175,35.177,35.175c19.395,0,35.175-15.78,35.175-35.175C207.307,316.936,202.629,312.259,196.859,312.259z"></path>
      </g>
    </svg>
  );
};

export default memo(BabyPharm);
