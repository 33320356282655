export const checkoutDictionaryDisclaimer = {
  p1: {
    translate(favoriteStore: string, otherStores: string[], priceRange: string) {
      return {
        hebrew: `בקנייה זו בחרת לערוך השוואה ב- ${otherStores.join(" , ")} וב- ${favoriteStore} כחנות מועדפת.
                טווח המחירים בקנייה זו הינו ${priceRange} ש"ח. `,
        english: `In this shopping, you choose to compare between -${otherStores.join(
          " , "
        )} and in ${favoriteStore} as your favorite store.
                The price range in this shopping is ${priceRange} shekels.`,
        russian: `В этом шопинге вы выбрали сравнить между -${otherStores.join(
          " , "
        )} и ${favoriteStore} как ваш магазин по предпочтению.
                Диапазон цен в этом шопинге составляет ${priceRange} шекелей.`,
        arabic: `في هذا التسوق، اخترت أن تقوم بالمقارنة بين -${otherStores.join(
          " , "
        )} وفي ${favoriteStore} كمتجرك المفضل.
                نطاق الأسعار في هذا التسوق هو ${priceRange} شيكل.`,
      };
    },
  },
  p2: {
    translate(favoriteStore: string, favoritePrice: number) {
      return {
        hebrew: `עלות העגלה ב${favoriteStore} - ${favoritePrice} ש"ח.
        אנו נגבה עמלת חסכון של 10% מההפרש בין עלות העגלה ב- ${favoriteStore} לכל עגלה שתבחרו בה הסכום נמוך משל ${favoriteStore}.`,
        english: `The total price in your favorite store is ${favoritePrice} shekels. We will charge a savings fee of 10% of the price difference between the cart's cost at ${favoriteStore} and any cart you choose with a lower amount than ${favoriteStore}.`,
        russian: `Общая стоимость в вашем любимом магазине составляет ${favoritePrice} шекелей. Мы взимаем комиссию за сбережения в размере 10% от разницы в цене между корзиной в магазине ${favoriteStore} и корзиной с более низкой суммой, чем в ${favoriteStore}.`,
        arabic: `السعر الإجمالي في متجرك المفضل هو ${favoritePrice} شيكل. سنقوم بتحصيل رسوم توفير بنسبة 10% من فارق السعر بين سلة التسوق في ${favoriteStore} وأي سلة أخرى تختارها بمبلغ أقل من ${favoriteStore}.`,
      };
    },
  },
  p3: {
    translate(favoriteStore: string, otherStores: string[]) {
      return {
        hebrew: `לדוגמא: הסכום ב${favoriteStore} הינו 500 שח,
        וב- ${otherStores[0]}- 400 שח. 
        וב- ${otherStores[1] ? otherStores[1] : "חנות כלשהי"} - 600 שח.`,

        english: `For example: The amount at ${favoriteStore} is 500 shekels,
        and at ${otherStores[0]} - 400 shekels.
        and at ${otherStores[1] ? otherStores[1] : "any store"} - 600 shekels.`,
        russian: `Например: Сумма в магазине ${favoriteStore} составляет 500 шекелей,
        а в магазине ${otherStores[0]} - 400 шекелей.
        а в магазине ${otherStores[1] ? otherStores[1] : "любом магазине"} - 600 шекелей.`,
        arabic: `على سبيل المثال: المبلغ في متجر ${favoriteStore} هو 500 شيكل,
        وفي متجر ${otherStores[0]} - 400 شيكل.
        وفي متجر ${otherStores[1] ? otherStores[1] : "أي متجر"} - 600 شيكل.`,
      };
    },
  },
  p4: {
    translate(favoriteStore: string, otherStores: string[]) {
      return {
        hebrew: `
        אם בחרתם ב -${favoriteStore} או ב-${otherStores[0]} - לא נגבה שום עמלה
       `,
        english: `If you choose ${favoriteStore} or ${otherStores[0]}, no fee will be charged.`,
        russian: `Если вы выберете ${favoriteStore} или ${otherStores[0]}, комиссия не будет взиматься.`,
        arabic: `إذا اخترت ${favoriteStore} أو ${otherStores[0]}، فلن يتم تحصيل أي رسوم.`,
      };
    },
  },
  p5: {
    translate(otherStores: string[]) {
      return {
        hebrew: `
        אבל אם בחרתם ב ${
          otherStores[1] ? otherStores[1] : "חנות כלשהי"
        }- עזרנו לכם לחסוך כ-100 שח, מסכום זה נגבה עמלה של 10% כלומר 10 שקלים בלבד ואתם חוסכים 90 ש"ח.`,

        english: `But if you choose ${
          otherStores[1] ? otherStores[1] : "any store"
        } - we help you save approximately 100 shekels. From this amount, a fee of 10% is deducted, which means only 10 shekels, and you save 90 shekels.`,
        russian: `Но если вы выберете ${
          otherStores[1] ? otherStores[1] : "любой магазин"
        } - мы поможем вам сэкономить примерно 100 шекелей. С этой суммы будет вычтена комиссия в размере 10%, что означает только 10 шекелей, и вы экономите 90 шекелей.`,
        arabic: `لكن إذا اخترت ${
          otherStores[1] ? otherStores[1] : "أي متجر"
        } - نساعدك في توفير نحو 100 شيكل. سيتم خصم رسوم بنسبة 10% من هذا المبلغ، مما يعني 10 شيكل فقط، وتوفير 90 شيكل.`,
      };
    },
  },
};
