import "./cover.css";
import { Button, Typography } from "../../../components/UI";
import Logo from "../../../components/Logo/Logo";
import coverImg from "../../../assets/images/cover-v7.jpg";
import AnimatedComponent from "../../../components/AnimatedComponent/AnimatedComponent";

interface ICoverProps {
  isUser: boolean;
}

const Cover = ({ isUser }: ICoverProps) => {
  const size = window.innerWidth < 576 ? 50 : 70;
  return (
    <AnimatedComponent asContainer animationClass="animation-fade-in-translateY animation-fade-in staggered-animation">
      <div id="hp-cover-wrapper">
        <div id="hp-cover-right-static">
          <Logo fontSize={size} animate />
          <Typography.Title
            level={1}
            strong
            size={70}
            color="var(--primary-color)"
            style={{ marginRight: size }}
            className="animation-fade-in-translateX animation-fade-in staggered-animation-2"
          >
            תשוו, תקנו , תוזילו!
          </Typography.Title>

          <Typography.Title
            level={3}
            className="primary-contrast-color animation-fade-in-translateX animation-fade-in staggered-animation-2"
            style={{ marginRight: size }}
            size={30}
            strong
            // useAnimationOnScroll=""
          >
            השוואת מחירים אונליין
          </Typography.Title>
          <div className="hp-cover-btn" style={{ marginRight: size }}>
            <Button backgroundColor="var(--primary-contrast-color)" color="var(--primary-color)">
              הצטרפו אלינו
            </Button>
          </div>
        </div>
        <div id="hp-cover-left-carousel">
          <img width={"100%"} height={"100%"} src={coverImg} alt="cover" />
        </div>
      </div>
    </AnimatedComponent>
  );
};

export default Cover;
