import { FC, useState } from "react";
import "./ProductCard.css";
import { ProductDataType } from "../../types/types";

import ProductCardGeneric from "./ProductCardLayoutVersions/ProductCardGeneric";
import { ProductCardBodyV2, ProductCardFooterV2 } from "./ProductCardLayoutVersions/ProductCardV2";
import { ProductCardBodyV1, ProductCardFooterV1 } from "./ProductCardLayoutVersions/ProductCardV1";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";

type Props = {
  productData: ProductDataType;
  item_code: string;
  version?: 1 | 2;
  cssClass?: string;
};

const ProductCard: FC<Props> = ({ productData, item_code, version, cssClass }) => {
  const {
    user: { language },
    onlineStores: { favoriteStore, otherStores },
  } = useCustomContext();

  const stores = version === 2 ? [favoriteStore] : [favoriteStore, ...otherStores];
  const [isOpen, setIsOpen] = useState(false);

  if (version === 2) {
    // TODO: if its legal - better use the name from front
    const { item_name, item_price, unit_qty, img_url } = productData[favoriteStore];
    return (
      <ProductCardGeneric
        cssClass={cssClass}
        productData={productData}
        ProductCardBodyVersion={
          <ProductCardBodyV2 itemName={item_name} imgUrl={img_url} onClick={() => setIsOpen(true)} />
        }
        selectedStore={favoriteStore}
        item_code={item_code}
        stores={stores}
        ProductCardFooterVersion={<ProductCardFooterV2 itemPrice={item_price} unitQty={unit_qty} />}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    );
  } else {
    return (
      <>
        {
          <ProductCardGeneric
            productData={productData}
            ProductCardBodyVersion={
              <ProductCardBodyV1
                stores={stores}
                language={language}
                onClick={() => setIsOpen(true)}
                productData={productData}
              />
            }
            selectedStore={favoriteStore}
            item_code={item_code}
            stores={stores}
            ProductCardFooterVersion={<ProductCardFooterV1 stores={stores} productData={productData} />}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        }
      </>
    );
  }
};

export default ProductCard;
