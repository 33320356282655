import { FC, useState, useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import "./AlertMessage.css";
import { ErrorIcon, InfoIcon, SuccessIcon } from "../Icons";
import WarningIcon from "../Icons/MessagesIcons/WarningIcon";

type Props = {
  message: string | undefined | null;
  delay?: number | null;
  type?: "warning" | "error" | "success" | "info";
  position?: "center" | "top" | "bottom" | "top-right" | "bottom-right" | "top-left" | "bottom-left";
  cssClass?: string;
};

const AlertMessage: FC<Props> = ({ message, position = "top-right", delay = 3, type, cssClass }) => {
  const [isOpen, setIsOpen] = useState(!!message);
  let timerID = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (message) {
      setIsOpen(true);
      if (delay) {
        timerID.current = setTimeout(() => {
          setIsOpen(false);
        }, delay * 1000);
      }
    } else {
      setIsOpen(false);
    }

    return () => {
      if (timerID.current) {
        clearTimeout(timerID.current);
      }
    };
  }, [message, delay]);

  if (!isOpen) return null;

  const handleClick = () => {
    if (timerID.current) {
      clearTimeout(timerID.current);
    }
    setIsOpen(false);
  };

  const icon = {
    warning: <WarningIcon onClick={handleClick} size="1.5em" clickable />,
    error: <ErrorIcon onClick={handleClick} size="1.5em" clickable />,
    success: <SuccessIcon onClick={handleClick} size="1.5em" clickable />,
    info: <InfoIcon size="1.5em" onClick={handleClick} clickable />,
  };

  return createPortal(
    <div className={`warning-modal ${position} ${cssClass}`} onClick={() => setIsOpen(false)}>
      <div className="warning-modal-content">
        <div className="warning-modal-message">
          {message}
          {icon[type || "warning"]}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default AlertMessage;
