import CartItem from "../../../components/Cart/CartItem/CartItem";
import { Tooltip } from "../../../components/UI";
import { InfoIcon } from "../../../components/UI/Icons";
import { Cart, StoreEn } from "../../../types/types";

export const drawCartItems = (cart: Cart, selectedStore: StoreEn) => {
  const itemsKeys = Object.keys(cart).reverse();
  return itemsKeys.map((item_code: string) => {
    const data = cart[item_code];
    return (
      <CartItem
        productProp={data.product}
        amount={data.amount}
        item_code={item_code}
        key={item_code}
        selectedStore={selectedStore}
        featureName="comparison_cart"
      />
    );
  });
};

export const DrawFooterBox = ({
  toolTipTitle,
  description,
  price,
}: {
  toolTipTitle?: string;
  description: string;
  price?: string;
}) => {
  return (
    <div className="cart-comparison_cart_footer_box">
      <span>
        {toolTipTitle ? (
          <Tooltip title={toolTipTitle}>
            <InfoIcon />
          </Tooltip>
        ) : (
          ""
        )}
      </span>

      <span>{description}</span>
      {price && (
        <div>
          <span>{price}</span>
          <span style={{ fontSize: 12 }}> ש"ח </span>
        </div>
      )}
    </div>
  );
};
