import { logoutUser } from "../../../components/Navbar/SiteNav/SiteNavOptions/UserAuthSelect/UserAuthSelect";
import { SiteContextType } from "../../../hooks/useCustomContext/useCustomContext";
import { checkTokenExpIsValid, parseJwt } from "../../../utils/utils";
import { redirect } from "react-router-dom";

const sessionExpired = {
  hebrew: "התוקף לפעולה זו פג , אנא נסה שנית.",
  english: "Your session is expired. Please try again.",
  arabic: "انتهت جلستك. يرجى المحاولة مرة أخرى.",
  russian: "Ваша сессия истекла. Пожалуйста, попробуйте снова.",
};

export const ProtectedResetPassword = async (req: any, contextValues: SiteContextType) => {
  const { user, setUser } = contextValues;
  const { token } = req.params;

  if (user.isUser) {
    await logoutUser(setUser);
  }

  const decoded = parseJwt(token);
  const isValid = checkTokenExpIsValid(decoded);

  if (!isValid) {
    alert(sessionExpired[user.language]);
    return redirect("/");
  }

  return { token, language: user.language };
};
