import { useState, useEffect, Dispatch, SetStateAction } from "react";
import { CartStorage, OnlineStoresType } from "../types/types";

export enum localStorageKeys {
  cart = "cart",
  userOnlineStores = "userOnlineStores",
  city = "city",
}

type InitialValueTypes = {
  [localStorageKeys.cart]: CartStorage[];
  [localStorageKeys.userOnlineStores]: OnlineStoresType;
  [localStorageKeys.city]: string;
};

const useLocalStorage = <K extends keyof InitialValueTypes>(
  key: K,
  initialValue: InitialValueTypes[K]
): [InitialValueTypes[K], Dispatch<SetStateAction<InitialValueTypes[K]>>] => {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);

    return storedValue ? JSON.parse(storedValue) : initialValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};

export default useLocalStorage;
