import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

const Cleaning: FC<IconCustom> = ({ onClick, size = iconSizeDefault, hovered, cssClass }) => {
  return (
    <svg
      onClick={onClick}
      height={size}
      width={size}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
      fill={hovered ? "#000000" : "var(--iconC)"}
      className={cssClass}
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        <path
          fill={hovered ? "#AF7AAE" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
          d="M467.478,445.217H155.826l-22.261-256h356.174L467.478,445.217z"
        ></path>
        <path
          fill={hovered ? "#8E5B8E" : "var(--iconB)"}
          d="M489.739,166.957v22.261l-1.936,22.261H135.501l-1.936-22.261v-22.261H489.739z"
        ></path>
        <path
          fill={hovered ? "#D199D1" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
          d="M512,144.696v22.261c0,12.295-9.966,22.261-22.261,22.261H133.565 c-12.295,0-22.261-9.966-22.261-22.261v-22.261c0-12.295,9.966-22.261,22.261-22.261h356.174 C502.034,122.435,512,132.401,512,144.696z"
        ></path>
        <path
          fill={hovered ? "#A7E5CB" : "var(--iconB)"}
          d="M478.609,434.087L478.609,434.087c0,18.441-14.949,33.391-33.391,33.391h-68.69 c1.231,3.482,1.908,7.227,1.908,11.13l0,0c0,18.441-14.949,33.391-33.391,33.391H33.391C14.949,512,0,497.05,0,478.609l0,0 c0-18.441,14.949-33.391,33.391-33.391h68.69c-1.231-3.482-1.908-7.227-1.908-11.13l0,0c0-18.441,14.949-33.391,33.391-33.391 h311.652C463.659,400.696,478.609,415.646,478.609,434.087z M387.37,155.719c4.885,0.475,9.464-0.659,13.325-2.895v46.99 c0,12.071,9.386,22.351,21.449,22.78c12.664,0.451,23.072-9.682,23.072-22.246v-77.913c0-6.147-4.983-11.13-11.13-11.13h-22.261 h-11.13h-22.261c-6.147,0-11.13,4.983-11.13,11.13v10.624C367.304,144.598,375.886,154.602,387.37,155.719z"
        ></path>
        <path
          fill={hovered ? "#D7F3FF" : "var(--iconB)"}
          d="M178.087,434.087c0,6.147-4.983,11.13-11.13,11.13h-33.391c-6.147,0-11.13-4.983-11.13-11.13 c0-6.147,4.983-11.13,11.13-11.13h33.391C173.104,422.957,178.087,427.94,178.087,434.087z M122.435,467.478H33.391 c-6.147,0-11.13,4.983-11.13,11.13s4.983,11.13,11.13,11.13h89.043c6.147,0,11.13-4.983,11.13-11.13 S128.582,467.478,122.435,467.478z M422.957,133.565c-6.147,0-11.13,4.983-11.13,11.13v55.652c0,6.147,4.983,11.13,11.13,11.13 c6.147,0,11.13-4.983,11.13-11.13v-55.652C434.087,138.548,429.104,133.565,422.957,133.565z"
        ></path>
        <path
          fill={hovered ? "#A89B80" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
          d="M356.174,378.435h66.783c49.178,0,89.043,39.866,89.043,89.043v33.391 c0,6.147-4.983,11.13-11.13,11.13H289.391c-49.178,0-89.043-39.866-89.043-89.043v-77.913c0-12.295,9.966-22.261,22.261-22.261 h89.043c12.295,0,22.261,9.966,22.261,22.261v11.13C333.913,368.469,343.879,378.435,356.174,378.435z"
        ></path>
        <path
          fill={hovered ? "#C1B291" : "var(--iconB)"}
          d="M512,467.478V512H333.913c-49.178,0-89.043-39.866-89.043-89.043v-77.913 c0-12.295,9.966-22.261,22.261-22.261h44.522c12.295,0,22.261,9.966,22.261,22.261v11.13c0,12.295,9.966,22.261,22.261,22.261 h44.522h22.261C472.134,378.435,512,418.301,512,467.478z"
        ></path>
        <path
          fill={hovered ? "#A89B80" : "var(--iconB)"}
          d="M512,467.478V512H378.435c-49.178,0-89.043-39.866-89.043-89.043v-44.522 c0,49.178,39.866,89.043,89.043,89.043H512z"
        ></path>
        <path
          fill={hovered ? "#DBCBA1" : "var(--iconA)"}
          d="M500.058,422.957H378.435c-49.178,0-89.043-39.866-89.043-89.043v-11.13h22.261 c12.295,0,22.261,9.966,22.261,22.261v11.13c0,12.295,9.966,22.261,22.261,22.261h66.783 C455.909,378.435,484.66,396.349,500.058,422.957z"
        ></path>
        <path
          fill={hovered ? "#91F18B" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
          d="M289.391,22.261v244.87v22.261H244.87V267.13V22.261C244.87,9.966,254.836,0,267.13,0 C279.425,0,289.391,9.966,289.391,22.261z"
        ></path>
        <path
          fill={hovered ? "#6BAF6E" : "var(--iconB)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={10}
          d="M267.13,22.261v267.13H244.87V22.261C244.87,9.966,254.836,0,267.13,0 c4.059,0,7.854,1.104,11.13,3.002C271.614,6.853,267.13,14.027,267.13,22.261z"
        ></path>
        <path
          fill={hovered ? "#FFC248" : "var(--iconB)"}
          d="M345.043,311.652L345.043,311.652c0,12.295-9.966,22.261-22.261,22.261H211.478 c-12.295,0-22.261-9.966-22.261-22.261l0,0c0-12.295,9.966-22.261,22.261-22.261h22.261v-11.13c0-6.147,4.983-11.13,11.13-11.13 h44.522c6.147,0,11.13,4.983,11.13,11.13v11.13h22.261C335.077,289.391,345.043,299.357,345.043,311.652z"
        ></path>
        <path
          fill={hovered ? "#FFFF63" : "var(--iconB)"}
          d="M345.043,311.652H189.217l0,0c0-12.295,9.966-22.261,22.261-22.261h111.304 C335.077,289.391,345.043,299.357,345.043,311.652L345.043,311.652z"
        ></path>
      </g>
    </svg>
  );
};

export default memo(Cleaning);
