import { FC, memo } from "react";
import { IconCustom } from "../types";
import { iconSizeDefault } from "../../../../utils/constants";

const MeatChickenFish: FC<IconCustom> = ({ onClick, cssClass, size = iconSizeDefault, hovered }) => {
  return (
    <svg
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 128 128"
      aria-hidden="true"
      role="img"
      preserveAspectRatio="xMidYMid meet"
      fill={hovered ? "#000000" : "var(--iconC)"}
      transform="rotate(90)"
      className={cssClass}
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          d="M33.16 39.69s-5.83-8.61-9.8-10.08c-3.98-1.47-8.11-.51-9.94-2.9c-1.85-2.42-2.81-6.55-2.22-9.2c.59-2.65 2.1-4.66 3.42-4.95s4.82 1.56 4.82 1.56s-.15-4.49.29-6.11c.44-1.62 2.29-3.31 3.24-3.9c3.08-1.92 4.71-.44 4.71-.44s-1.18 10.31 2.65 14.87s19 20.32 16.05 20.47c-2.94.14-13.22.68-13.22.68z"
          fill={hovered ? "#ddbca8" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={2}
        ></path>
        <path
          d="M33.44 32.93s-6.51-6.11-8.56-7.18c-2.05-1.07-6.31-.52-7.8-1.3c-2.01-1.05-4.19-3.3-4.37-6.95s.84-5.44 4.45-5.61c2.94-.14 4.72.98 5.52.18c.8-.8.09-6.15.53-7.04c.45-.89 1.69-2.58 4.99-2.05s4.72 3.58 4.9 4.37c.74 3.21.2 9.24 1.01 10.67c1.28 2.25 9.94 11.87 9.94 11.87s-10.53 2.86-10.61 3.04z"
          fill={hovered ? "#ffebca" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={2}
        ></path>
        <path
          d="M33.18 30.72C19.12 40.64 14.37 54.39 14.87 64.6c.43 8.78 3.55 13.51 8.66 17.74s8.3 5.41 10.42 8.27s5.09 14.69 11.69 20.91s27.68 18.76 52.24-3.26c25.28-22.67 17.5-45.41 8.16-53.88c-9.02-8.18-16.86-8.74-20.88-13.63c-4.61-5.6-2.86-7.87-10.34-13.99c-8.82-7.22-24.33-8.24-41.64 3.96z"
          fill={hovered ? "#cf701e" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={2}
        ></path>
        <path
          d="M61.2 63.54c-14.82 11.9-25.02 36.47-7.22 47.95c13.33 8.59 32.35 2.11 43.18-7.92c8.47-7.84 19.16-28.63 9-40.97c-9.04-10.98-29.14-11.76-44.96.94z"
          fill={hovered ? "#fbd5b2" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={2}
        ></path>
        <path
          d="M58.77 32.62c-.95-.95-2.46-.87-3.69.58s-1.4 3.29-.36 4.12c1.52 1.23 3.11-.31 3.91-1.37c.86-1.16 1.22-2.25.14-3.33z"
          fill={hovered ? "#ab5931" : "var(--iconA)"}
        ></path>
        <path
          d="M31.43 44.41c-1.88-.29-2.46 1.52-2.53 3.04c-.07 1.52-.29 3.26 1.74 3.4c2.03.14 2.46-1.95 2.46-3.33c-.01-1.38.19-2.83-1.67-3.11z"
          fill={hovered ? "#ab5931" : "var(--iconC)"}
        ></path>
        <path
          d="M32 62.06c-1.81-.08-2.39 1.45-2.31 3.69s.8 3.62 2.03 3.54c1.23-.07 1.9-1.45 2.03-3.33c.14-2.17-.01-3.83-1.75-3.9z"
          fill={hovered ? "#ab5931" : "var(--iconA)"}
        ></path>
        <path
          d="M38.44 69.29c-1.09-.78-2.68-.22-3.26 2.03c-.62 2.4.32 3.16 1.09 3.47c1.23.51 2.17-.8 2.6-2.24c.44-1.45.59-2.54-.43-3.26z"
          fill={hovered ? "#ab5931" : "var(--iconC)"}
        ></path>
        <path
          d="M81 60.52c-1.19-.81-9.92 1.03-18.3 8.99S51.54 84.09 52.37 86.36s6.51 2.17 8.79 2.17s8.15-.05 10.03-.1c3.72-.1 3.72 3.51-.1 4.03c-3.09.42-11.58.72-14.99 1.96c-3 1.09-5.68 1.96-5.89 4.34c-.21 2.38 3.51 8.27 9.72 10.44s13.54 1.55 14.88.52c1.34-1.03 2.27-8.37 2.48-11.78c.21-3.41.1-8.27 2.69-8.27s1.86 6.1 1.96 8.58c.1 2.48-.1 6.82 1.24 7.86c1.34 1.03 6.69-1.63 11.37-6.31c4.24-4.24 7.65-8.79 6.41-10.34c-1.24-1.55-11.47-1.76-13.95-1.65c-2.48.1-2.89-4.13.62-4.24c3.51-.1 11.99.62 14.68-.83c2.69-1.45 5.99-8.06 1.45-15.5s-9.82-7.65-12.09-7.44c-2.27.21-3.31 6.2-4.13 9.1c-.83 2.89-1.65 8.27-1.96 9.51c-.31 1.24-3 2.27-3.2-.41c-.21-2.69.21-7.44.31-10.13s.27-6-1.69-7.35z"
          fill={hovered ? "#ff605e" : "var(--iconA)"}
          stroke={hovered ? "#000000" : "var(--iconC)"}
          strokeWidth={2}
        ></path>
        <path
          d="M86.89 91.53s-8.06-6.93-8.68-6.1c-.62.83-1.86 2.27-2.07 4.55c-.21 2.27.55 2.98.55 2.98s7.51 9.94 9.16 11.8c1.65 1.86 3.99 5.3 4.2 6.95c.21 1.65-.6 7.16.14 8.87c.93 2.17 2.77 3.61 5.56 4.14c1.41.27 6.49-.07 7.84-1.42s1.93-3.84 1.93-5.08c0-1.24-.58-2.14.76-2.76c1.34-.62 5.32-.14 7.18-1.17c1.86-1.03 3.31-3.25 3.21-5.84c-.1-2.58-3.74-4.33-4.05-4.33S86.89 91.53 86.89 91.53z"
          fill={hovered ? "#dfbbaa" : "var(--iconB)"}
        ></path>
        <path
          d="M87.92 81.85c-.45-.31-.71-.42-1.05-.45c-1.48-.12-3.4.01-5.58 1.38c-2.29 1.43-3.87 3.69-3.87 3.69s12.67 15.6 13.81 17.26c1.14 1.65 3.2 4.34 3.2 5.68s-.78 6.84-.31 8.06c.72 1.86 3.41 4.44 5.89 4.44c3.52 0 4.89-1.92 5.41-3.27s.49-5.42 1.31-7.48c1.03-2.58 9.39-.36 9.91-2.01c.52-1.65 1.27-6.12-2.36-8.22c-2.69-1.55-9.41-.72-9.41-.72S89.24 82.74 87.92 81.85z"
          fill={hovered ? "#ffebca" : "var(--iconA)"}
        ></path>
      </g>
    </svg>
  );
};

export default memo(MeatChickenFish);
