import { useState } from "react";
import CategoriesMap from "../../../../assets/Data/categories";
import { Cart, Language, StoreEn } from "../../../../types/types";
import CartItem from "../../CartItem/CartItem";
import { PlusOrMinusIcon } from "../../../UI/Icons";
import "./SortingCartByCategories.css";
import { Typography } from "../../../UI";

export const SortCartByCategories = ({ cart, store, language }: { cart: Cart; store: StoreEn; language: Language }) => {
  const [visibleCategories, setVisibleCategories] = useState<Record<string, boolean>>({});
  const itemsKeys = Object.keys(cart);
  const categories: string[] = [];

  itemsKeys.forEach((item) => {
    const category = cart[item].product[store].category;
    categories.push(category);
  });

  const uniqueSet = new Set(categories);
  const uniqueCategories = Array.from(uniqueSet);

  const toggleCategoryVisibility = (cat: string) => {
    setVisibleCategories((prevVisibleCategories: any) => ({
      ...prevVisibleCategories,
      [cat]: prevVisibleCategories[cat] === undefined ? false : !prevVisibleCategories[cat],
    }));
  };

  return (
    <>
      {uniqueCategories.map((cat) => {
        const items = itemsKeys.filter((item) => cart[item].product[store].category === cat);
        const isVisible = visibleCategories[cat] !== false; // Visible if true or undefined

        return (
          <div key={cat}>
            <div className="sorting-cart-categories-header-wrapper">
              <div className="sorting-cart-title">
                <Typography.Title level={5}>{CategoriesMap[cat].category[language]} </Typography.Title>
                <div>({items.length})</div>
              </div>
              <PlusOrMinusIcon
                sign={isVisible ? "minus" : "plus"}
                size="10px"
                clickable
                color="var(--primary-color)"
                onClick={() => toggleCategoryVisibility(cat)}
              />
            </div>
            <div
              style={{
                maxHeight: isVisible ? "1000px" : "0",
                overflow: "auto",
                transition: "max-height 0.5s ease-in-out",
              }}
            >
              {isVisible && drawCartItems(items, cart, store)}
            </div>
          </div>
        );
      })}
    </>
  );
};

export const drawCartItems = (itemsKeys: string[], cart: Cart, store: StoreEn) => {
  return itemsKeys.map((item_code: string) => {
    const data = cart[item_code];
    return (
      <CartItem
        productProp={data.product}
        amount={data.amount}
        item_code={item_code}
        key={item_code}
        selectedStore={store}
      />
    );
  });
};
