import { useEffect, useRef, useState } from "react";
import { dictAuth } from "../../Authentication/dictionary";
import { StoreEn } from "../../../types/types";
import { Card, SelectSearchUI, Typography } from "../../UI";
import { Option } from "../../UI/Select/SelectSearchUI/SelectSearchUI";
import Toggle from "../../UI/Toggle/Toggle";
import { dictStores } from "../../../dictionary/dictStores";
import storesLocation from "../../../assets/Data/storesLocations";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { DeleteIcon } from "../../UI/Icons";
import "./StorePreference.css";
import { storePreferenceDict } from "./dict";
import AlertMessage from "../../UI/AlertMessage/AlertMessage";
import CartModel from "../../../models/Cart/CartModel";

interface SelectedOption extends Option {
  value: StoreEn;
}

// type StoresSelect = { store: string; hasClub: boolean; favoriteStore: boolean };

const StoresPreference = () => {
  const {
    onlineStores,
    setOnlineStores,
    setCart,
    cart,
    user: { language, city },
  } = useCustomContext();

  const [selectedStores, setSelectedStores] = useState<SelectedOption[]>(
    [onlineStores.favoriteStore, ...onlineStores.otherStores].map((s) => ({
      value: s,
      label: dictStores[s][language],
    }))
  );

  const firstRender = useRef(true);

  const handleSelectMultiChange = (selectedOptions: Option[]) => {
    setSelectedStores(selectedOptions as SelectedOption[]);
  };

  const handleRemoveStore = (store: StoreEn) => {
    setSelectedStores((prev) => prev.filter((s) => s.value !== store));
  };

  const handleOnChangeHasClubCard = (store: StoreEn, hasClubId: boolean) => {
    if (hasClubId) {
      setOnlineStores((prev) => ({ ...prev, hasClubCard: [...prev.hasClubCard, store] }));
    } else {
      setOnlineStores((prev) => ({ ...prev, hasClubCard: prev.hasClubCard.filter((s) => s !== store) }));
    }
  };

  const handleOnChangeFavoriteStore = (favoriteStore: StoreEn, isSelected: boolean) => {
    isSelected && setOnlineStores((prev) => ({ ...prev, favoriteStore }));
  };

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
    } else {
      const otherStores = selectedStores.filter((s) => s.value !== onlineStores.favoriteStore).map((s) => s.value);
      setOnlineStores((prev) => ({ ...prev, otherStores: otherStores }));
      setCart(CartModel.cleanCart());
    }
  }, [selectedStores, onlineStores.favoriteStore, setOnlineStores, setCart]);

  const drawMyLocationStores = () => {
    const locationStores = storesLocation.find((s) => s.location === city)?.stores;
    return (
      <>
        <Typography.Text type="secondary">החנויות שעושות משלוחים לאזור שלי:</Typography.Text>
        {locationStores?.length ? (
          <div>
            {locationStores.map((s) => {
              return <b key={s}>{dictStores[s as StoreEn][language]},</b>;
            })}
          </div>
        ) : (
          <div>No Stores</div>
        )}
      </>
    );
  };

  return (
    <div className="flex-column flex-gap-20 flex-space-around">
      {Object.keys(cart).length && (
        <AlertMessage
          message={"שימו לב ! החלפת חנויות תרוקן את העגלה הנוכחית"}
          type="warning"
          position="top"
          delay={0}
        />
      )}
      <Card subtitle={storePreferenceDict.howItWorkTitle.hebrew}>{storePreferenceDict.howItWork.hebrew}</Card>

      <div className="responsive-flex-column flex-row flex-gap-20">
        <Card
          cssClass="profile-setting-card-aside"
          subtitle={" חנויות ומיקום"}
          footer="Those stores are just for reference , while at check out page we will compare your cart to local stores also"
        >
          <Typography.Text type="secondary">{dictAuth.otherStores[language]}</Typography.Text>
          <SelectSearchUI
            label={"בחירת חנויות"}
            onChange={handleSelectMultiChange}
            disableLabelInInput={false}
            values={selectedStores}
            options={Object.keys(dictStores).map((s) => ({ label: dictStores[s as StoreEn].hebrew, value: s }))}
            isMulti
            keepValuesOnRemove={[onlineStores.favoriteStore]}
          />

          {drawMyLocationStores()}
          <div className="space-height-xl" />
        </Card>
        <Card
          cssClass="profile-setting-card-aside"
          subtitle={dictAuth.otherStores[language]}
          footer="Those stores are just for reference , while at check out page we will compare your cart to local stores also"
        >
          <div className="stores-container-table">
            <div className="stores-row stores-header">
              <span></span>
              <span className="store-name-column">שם</span>
              <span>חנות ראשית</span>
              <span>כרטיס מועדון</span>
              <span></span>
            </div>
            {selectedStores.map((s, i) => {
              return (
                <div key={s.value} className="stores-row">
                  <span>{i + 1}.</span>
                  <span className="store-name-column">{dictStores[s.value as StoreEn].hebrew}</span>

                  <span>
                    <Toggle
                      checked={onlineStores.favoriteStore === s.value}
                      onChange={(checked) => handleOnChangeFavoriteStore(s.value, checked)}
                    />
                  </span>
                  <span>
                    <Toggle
                      checked={onlineStores.hasClubCard.includes(s.value)}
                      onChange={(checked) => handleOnChangeHasClubCard(s.value, checked)}
                    />
                  </span>

                  {onlineStores.favoriteStore !== s.value && (
                    <DeleteIcon
                      size="1.5em"
                      color={"var(--font-color-secondary)"}
                      clickable
                      onClick={() => handleRemoveStore(s.value)}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default StoresPreference;
