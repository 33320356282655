import { dictStores } from "../../../dictionary/dictStores";
import { StoreEn } from "../../../types/types";

export const cartComparisonExplain = {
  hebrew: {
    landingPage:
      "השוואת מחירים ברגעים אלו ממש, בואו נוודא שמצאנו את הפריטים המבוקשים גם בשאר העגלות. לנוחיותכם ניתן לבחור מצב תצוגה רצוי",
    normalTotal: 'סה"כ ללא חוסרים :',
    originalTotal: 'סה"כ :',

    normalTotalFavoriteTooltip: (favoriteStore: StoreEn, otherStore: StoreEn) => {
      return `סה"כ עלות עגלה ב${dictStores[favoriteStore].hebrew} ללא המוצרים שלא מצאנו ב${dictStores[otherStore].hebrew}`;
    },
  },
};
