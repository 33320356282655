import { FC } from "react";
import { IconCustom } from "../types";

const WarningIcon: FC<IconCustom> = ({ color = "#ffbb00", size = "2rem", onClick, clickable }) => {
  return (
    <svg
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 1024 1024"
      fill={color}
      className={clickable ? "clickable-icon" : ""}
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          fill={color}
          d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 192a58.432 58.432 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.432 58.432 0 0 0 512 256zm0 512a51.2 51.2 0 1 0 0-102.4 51.2 51.2 0 0 0 0 102.4z"
        ></path>
      </g>
    </svg>
  );
};

export default WarningIcon;
