import { useRef, useState, RefObject } from "react";
import { Button } from "../UI";
import "./Contact.css";
import serverAPI from "../../api/server";
import { parseCookies } from "../../utils/utils";
import { dictAuth } from "../Authentication/dictionary";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import Logo from "../Logo/Logo";

const dict = {
  english: {
    h1: "Contact Us",
    h2: "Help us to help you",
    btn: "Submit",
    wait: "We already got your message, Please await a while to contact us again.",
  },
  hebrew: {
    h1: "צור קשר",
    h2: "סייעו לנו להשתפר",
    btn: "שלח",
    wait: "קיבלנו כבר את הבקשה שלך , אנא המתן מספר דקות בכדי לשלוח שוב.",
  },
  russian: {
    h1: "Свяжитесь с нами",
    h2: "Помогите нам помочь вам",
    btn: "Отправить",
    wait: "Мы уже получили ваше сообщение. Пожалуйста, подождите немного, прежде чем связываться с нами снова.",
  },
  arabic: {
    h1: "اتصل بنا",
    h2: "ساعدنا في مساعدتك",
    btn: "إرسال",
    wait: "لقد استلمنا رسالتك بالفعل. الرجاء الانتظار قليلاً قبل الاتصال بنا مرة أخرى.",
  },
};

const Contact = () => {
  const form: RefObject<HTMLFormElement> = useRef(null);
  const [submited, setSubmited] = useState(false);
  const {
    user: { language },
  } = useCustomContext();

  const [message, setMessage] = useState("");

  const sendEmail = async (e: any) => {
    e.preventDefault();
    const cookie = parseCookies("contact");

    if (cookie) {
      setMessage(dict[language].wait);
      return;
    }

    setSubmited(true);
    const formData = new FormData(form.current!);

    const name = formData.get("name");
    const email = formData.get("email");
    const message = formData.get("message");

    try {
      //send req to server
      await serverAPI("users/contact", {
        fullName: name as string,
        email: email as string,
        message: message as string,
      });
    } catch (error: any) {
      console.log(error);
      setMessage(error.message);
    } finally {
      e.target.reset();
      setSubmited(false);
    }
  };

  return (
    <section id="contact" className="gradient-bg">
      <div className="contact-form-class error-content">
        <div className="error-logo">
          <Logo fontSize={"5rem"} />
        </div>
        <div className="titles">
          <h2>{dict[language].h1}</h2>
          <h5>{dict[language].h2}</h5>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input
            className="contact-input"
            type="text"
            name="name"
            required
            placeholder={dictAuth.fullName[language]}
            // value={loggedInEmail === "Guest" ? undefined : loggedInName}
            // disabled={loggedInEmail !== "Guest"}
          />
          <input
            className="contact-input"
            type="email"
            name="email"
            required
            placeholder={dictAuth.email[language]}
            // value={loggedInEmail === "Guest" ? undefined : loggedInEmail}
            // disabled={loggedInEmail !== "Guest"}
          />
          <textarea
            name="message"
            rows={7}
            style={{ width: "100%", resize: "none" }}
            maxLength={350}
            required
          ></textarea>
          <div className="titles">
            <Button loading={submited}>{dict[language].btn}</Button>
          </div>
        </form>
        <div>{message}</div>
      </div>
    </section>
  );
};

export default Contact;
