import React, { CSSProperties, Children, cloneElement, useCallback, useState } from "react";
import "./Carousel.css";

// ***********************************************************************************************************************
// ? The longer version of Carousel.
// ? shorter code is on the Carousel.tsx
// ***********************************************************************************************************************

interface CarouselProps {
  children: React.ReactNode;
  slideToShow?: number;
  onSliding?: (slideNum: number) => void;
  rightArrow?: boolean;
  leftArrow?: boolean;
  edgeWidth?: number;
  gap?: number;
  clickableSlider?: boolean;
  infinite?: boolean;
  arrowsOnHover?: boolean;
  arrowsCss?: string;
  containerStyle?: CSSProperties;
}

const CarouselFull: React.FC<CarouselProps> = ({
  children,
  slideToShow = 1,
  edgeWidth = 20,
  onSliding,
  rightArrow = true,
  leftArrow = true,
  gap = 0,
  clickableSlider = false,
  infinite = false,
  arrowsOnHover = false,
  arrowsCss = "",
  containerStyle,
}) => {
  const [state, setState] = useState({
    position: Children.count(children) - 1,
    // position: 0,
    direction: Children.count(children) === 2 ? "prev" : "next",
    sliding: false,
  });

  function getOrder(itemIndex: number) {
    const numItems = Children.count(children);

    if (numItems === 2) return itemIndex;

    if (itemIndex < state.position) return numItems - Math.abs(itemIndex - state.position);
    return itemIndex - state.position;
  }

  const doSliding = (direction: "prev" | "next", position: number) => {
    onSliding && onSliding((position + 1) % Children.count(children));
    setState({
      sliding: true,
      direction,
      position,
    });

    setTimeout(() => {
      setState((prev) => ({ ...prev, sliding: false }));
    }, 5);
  };

  const nextSlide = () => {
    const { position } = state;

    const numItems = Children.count(children);

    // prevent infinite
    if (preventInfinite("next")) return;
    // if (!infinite && position === numItems - 2) return;

    if (numItems === 2 && position === 1) return;

    doSliding("next", position === numItems - 1 ? 0 : position + 1);
  };

  const prevSlide = () => {
    const { position } = state;

    const numItems = Children.count(children);

    // prevent infinite
    if (preventInfinite("prev")) return;

    if (numItems === 2 && position === 0) return;

    doSliding("prev", position === 0 ? numItems - 1 : position - 1);
  };

  const childrenWithProps = Children.map(children, (child, index) =>
    cloneElement(child as React.ReactElement<any>, {
      key: index,
      numslides: Children.count(children) || 1,
    })
  );

  const transformContainer = () => {
    // * The slides width is determend by the carousel
    if (slideToShow === 3) {
      if (!state.sliding) return `translateX(-${100 - edgeWidth + gap}%)`;
      if (state.direction === "prev") return `translateX(-200%)`;
      return "translateX(0%)";
    } else {
      if (!state.sliding) return `translateX(-${100 / slideToShow}%)`;
      if (state.direction === "prev") return `translateX(-${200 / slideToShow}%)`;
      return "translateX(0%)";
    }
  };

  const calculateFlex = useCallback(
    (index: number, length: number) => {
      if (slideToShow === 3) {
        if (index === state.position + 1 || (state.position === length - 1 && index === 0))
          return `1 0 ${100 - edgeWidth * 2}%`;

        return `1 0 100%`;
      }
      return `1 0 ${100 / slideToShow}%`;
    },
    [state, slideToShow, edgeWidth]
  );

  const handleOnClick = useCallback(
    (index: number, length: number) => {
      if (index === 0) {
        if (state.position === 0) {
          prevSlide();
        } else {
          nextSlide();
        }
      } else if (state.position === length - 1) {
        if (index === state.position) {
          prevSlide();
        } else {
          nextSlide();
        }
      } else {
        if (index > state.position) {
          nextSlide();
        } else {
          prevSlide();
        }
      }
    },
    // eslint-disable-next-line
    [state]
  );

  const preventInfinite = (direction: "prev" | "next") => {
    if (infinite) return false;
    const numItems = Children.count(children);
    // Prevent Next
    if (direction === "next" && state.position === numItems - 2) return true;
    // Prevent Prev
    if (direction === "prev" && state.position === numItems - 1) return true;

    return false;
  };

  return (
    <div className="carousel_container" style={containerStyle}>
      <div
        className="carousel_content_wrapper"
        style={{
          transition: state.sliding ? "none" : "transform 0.6s ease",
          transform: transformContainer(),
        }}
      >
        {childrenWithProps?.map((child, index, arr) => (
          <div
            onClick={() => clickableSlider && slideToShow === 3 && handleOnClick(index, arr.length)}
            className={
              slideToShow !== 3
                ? ""
                : state.position + 1 === index || (state.position === arr.length - 1 && index === 0)
                ? "carousel_current_active"
                : "carousel_current_none_active"
            }
            key={index}
            style={{
              order: getOrder(index),
              flex: calculateFlex(index, arr.length),
              marginRight: `${gap}%`,
            }}
          >
            {child}
          </div>
        ))}
      </div>

      {leftArrow && (
        <button
          className={`carousel-btn carousel-prev ${arrowsCss} ${
            preventInfinite("prev") ? "carousel-btn_disabled" : ""
          } ${arrowsOnHover ? "carousel-btn_not_visible" : ""}`}
          onClick={prevSlide}
        >
          ⮜
        </button>
      )}
      {rightArrow && (
        <button
          className={`carousel-btn carousel-next ${arrowsCss} ${
            preventInfinite("next") ? "carousel-btn_disabled" : ""
          } ${arrowsOnHover ? "carousel-btn_not_visible" : ""}`}
          onClick={nextSlide}
        >
          ⮞
        </button>
      )}
    </div>
  );
};

export default CarouselFull;

// const arrowsType = {
//   arrowThick: {
//     right: <div className="arrow_carousel">🢂</div>,
//     left: <div className="arrow_carousel">🢀</div>,
//   },
//   triangleCss: {
//     right: <div className="triangle-right"></div>,
//     left: <div className="triangle-left"></div>,
//   },
//   arrow: {
//     right: <div className="arrow_carousel">🠊</div>,
//     left: <div className="arrow_carousel">🠈</div>,
//   },
//   triangle: {
//     right: <div className="arrow_carousel">⮞</div>,
//     left: <div className="arrow_carousel">⮜</div>,
//   },
// };
