import { SiteContextType } from "../../../hooks/useCustomContext/useCustomContext";
import { checkTokenExpIsValid, parseJwt } from "../../../utils/utils";
import { redirect } from "react-router-dom";

export const ProtectedRoute = async (req: any, contextValues: SiteContextType) => {
  const { user, cart, siteVersion } = contextValues;

  if (!user.isUser) {
    alert(`Only members can proceed .`);
    return redirect("/");
  }

  const token = req.token;
  const decoded = parseJwt(token);
  const isValid = checkTokenExpIsValid(decoded);

  if (!isValid) {
    alert(`Only members can proceed.\nYour token is expired`);
    return redirect("/");
  }

  return { cart, user, siteVersion };
};
