import { Drawer } from "../../components/UI";
import "./Hompage.css";
import Cover from "./Cover/Cover";
import Cart from "../../components/Cart/Cart";
import CartHeader from "../../components/Cart/CartHeader/CartHeader";
import HowItsWork from "./HowItsWork/HowItsWork";
import CategoriesPricesChartsCarousel from "./CategoriesPricesChartsCarousel/CategoriesPricesChartsCarousel";
import Tutorial from "./Tutorial/Tutorial";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";

const Homepage = () => {
  const { user, isCartOpen, setIsCartOpen } = useCustomContext();

  return (
    <div id="hp-v3-container">
      <div className="space-height-l"></div>
      <div className="space-height-l"></div>
      <Cover isUser={user.isUser} />
      <div className="space-height-l"></div>
      <div className="space-height-xxl"></div>
      <CategoriesPricesChartsCarousel title={"השוואת קטגוריות"} showIcons={true} language={user.language} />
      <div className="space-height-xxl"></div>
      <div className="space-height-xxl"></div>
      <div className="space-height-xxl"></div>
      <HowItsWork />
      <div className="space-height-xxl"></div>
      <div className="space-height-xxl"></div>
      <div className="space-height-xl"></div>
      <Tutorial />
      <div className="space-height-xxl"></div>
      <div className="space-height-xxl"></div>

      <Drawer customHeader={<CartHeader />} isOpen={isCartOpen} placement={"left"} onClose={() => setIsCartOpen(false)}>
        <div style={{ height: "calc(100vh - var(--nav-height) - 100px)" }}>
          <Cart showHeader={false} />
        </div>
      </Drawer>
    </div>
  );
};

export default Homepage;
