import { FC, memo } from "react";
import { iconSizeDefault } from "../../../../utils/constants";
import { IconCustom } from "../types";

const CerealBreakfast: FC<IconCustom> = ({ onClick, size = iconSizeDefault, hovered }) => {
  return (
    <svg
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 1024 1024"
      fill={hovered ? "#000000" : "var(--iconC)"}
    >
      <g strokeWidth="1"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path
          d="M829.013333 450.986667A63.786667 63.786667 0 0 0 768 405.333333c-0.362667 0-0.704 0.106667-1.066667 0.106667A74.453333 74.453333 0 0 0 693.333333 341.333333c-22.997333 0-43.306667 10.624-57.002666 26.965334A74.474667 74.474667 0 0 0 608 362.666667c-7.232 0-14.101333 1.365333-20.714667 3.285333C573.013333 338.752 544.832 320 512 320c-31.957333 0-59.498667 17.792-74.112 43.797333A64.746667 64.746667 0 0 0 426.666667 362.666667c-5.184 0-10.176 0.789333-14.997334 1.962666a63.530667 63.530667 0 0 0-84.992-12.202666A73.813333 73.813333 0 0 0 288 341.333333a74.666667 74.666667 0 0 0-74.666667 74.666667c0 3.989333 0.576 7.808 1.173334 11.626667A74.432 74.432 0 0 0 149.333333 501.333333a74.666667 74.666667 0 0 0 74.666667 74.666667c3.648 0 7.146667-0.576 10.666667-1.066667V576h554.666666v-3.925333c6.698667 2.389333 13.802667 3.925333 21.333334 3.925333a64 64 0 0 0 64-64 63.786667 63.786667 0 0 0-45.653334-61.013333z"
          fill={hovered ? "#FFD600" : "var(--iconA)"}
        ></path>
        <path
          d="M106.666667 512h810.666666s-1.429333 341.333333-405.333333 341.333333S106.666667 512 106.666667 512z"
          fill={hovered ? "#5D4037" : "var(--iconA)"}
          strokeWidth={35}
          stroke={hovered ? "#000000" : "var(--iconC)"}
        ></path>
        <path d="M362.666667 832h298.666666v64H362.666667z" fill={hovered ? "#3E2723" : "var(--iconA)"}></path>
        <path
          d="M885.418667 149.12l-22.784-16.362667a25.344 25.344 0 0 0-35.434667 5.888l-166.826667 246.058667c11.093333 11.157333 18.858667 25.621333 21.226667 41.962667h26.666667l183.018666-242.218667a25.386667 25.386667 0 0 0-5.866666-35.328z"
          fill={hovered ? "#2196F3" : "var(--iconA)"}
          strokeWidth={25}
          stroke={hovered ? "#000000" : "var(--iconC)"}
        ></path>
        <path
          d="M320 426.666667a21.333333 21.333333 0 1 1-42.666667 0 21.333333 21.333333 0 0 1 42.666667 0z m128-42.666667a21.333333 21.333333 0 1 0 0 42.666667 21.333333 21.333333 0 0 0 0-42.666667z m-64 64a21.333333 21.333333 0 1 0 0 42.666667 21.333333 21.333333 0 0 0 0-42.666667z m234.666667-21.333333a21.333333 21.333333 0 1 0 0 42.666666 21.333333 21.333333 0 0 0 0-42.666666z m64 42.666666a21.333333 21.333333 0 1 0 0 42.666667 21.333333 21.333333 0 0 0 0-42.666667z"
          fill={hovered ? "#CBAFFAB00598" : "var(--iconA)"}
          strokeWidth={35}
          stroke={hovered ? "#000000" : "var(--iconC)"}
        ></path>
      </g>
    </svg>
  );
};

export default memo(CerealBreakfast);
