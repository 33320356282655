import { FC, useState, Dispatch, SetStateAction } from "react";
import { Divider } from "../../../UI";
import { Cart, Language, ProductDataType, StoreEn } from "../../../../types/types";
import CartItem from "../CartItem";
import SelectedItem from "../../../SelectedItem/SelectedItem";
import { comparisonIndicators } from "../../../../pages/CartComparisonPage/CartComparisonLegend/CartComparisonLegend";

interface IProps {
  amount: number;
  favoriteItemName: string;
  setCart: Dispatch<SetStateAction<Cart>>;
  itemCode: string;
  store: StoreEn;
  product: ProductDataType;
  setProduct: Dispatch<SetStateAction<ProductDataType>>;
  language: Language;
}

const NotFoundItem: FC<IProps> = ({
  store,
  language,
  amount,
  favoriteItemName,
  setCart,
  itemCode,
  product,
  setProduct,
}) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const DrawInitState = () => {
    return CartItem.DrawProduct({
      boldString: "לא מצאנו התאמה",
      imgNode: <div className="cart-item_not_found_in_cart_img"></div>,
      weakNode: <span>{favoriteItemName}</span>,
      onClick: () => setIsOpenModal(true),
      store: store,
    });
  };

  return (
    <div id="cart-item-card">
      <div className="cart-product-wrapper">
        {DrawInitState()}
        {/* {CartItem.DrawItemCartActions({ amount: amount, calculatedPrice: "???", color: "rgba(0,0,0,0)" })} */}
        {comparisonIndicators.notFound.icon}
      </div>
      <Divider margin="0" />
      <SelectedItem
        stores={[store]}
        selectedProductProp={product}
        isOpen={isOpenModal}
        language={language}
        setIsOpen={setIsOpenModal}
        selectedStore={store}
        featureType="comparison_cart"
        setSelectedProductProp={setProduct}
      />
    </div>
  );
};

export default NotFoundItem;
