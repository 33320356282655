import { FC, CSSProperties } from "react";
import "./Divider.css";

type Props = {
  alignment?: "right" | "center" | "left";
  text?: string;
  fontSize?: string;
  isTitle?: boolean;
  margin?: string;
  isLine?: boolean;
  color?: string;
  width?: string;
  style?: CSSProperties;
};

const Divider: FC<Props> = ({ alignment, text, fontSize, isTitle, margin, isLine = true, color, width, style }) => {
  const styleB = { borderColor: isLine ? "#dad9d9" : "transparent" };

  return (
    <div className={`divider `} style={style ? { ...style } : { margin, width }}>
      <hr style={styleB} className={` ${alignment === "right" ? "divider-line-small" : "divider-line"}`} />
      {text && (
        <span
          className="divider-text"
          style={{
            fontSize: isTitle && !fontSize ? "1.5rem" : fontSize,
            fontWeight: isTitle ? "bold" : undefined,
            color,
          }}
        >
          {text}
        </span>
      )}
      <hr style={styleB} className={`${alignment === "left" ? "divider-line-small" : "divider-line"}`} />
    </div>
  );
};

export default Divider;
