import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import router from "./Routs/Routs";
import { RouterProvider } from "react-router-dom";
import { SiteContext, SiteProvider } from "./hooks/useCustomContext/useCustomContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  // <React.StrictMode>

  <SiteProvider>
    <SiteContext.Consumer>{(siteContext) => <RouterProvider router={router(siteContext)} />}</SiteContext.Consumer>
    {/* <RouterProvider router={router} /> */}
  </SiteProvider>

  // </React.StrictMode>
);
