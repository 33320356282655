import React, { useEffect, useRef } from "react";
import "./progress.css";

interface ProgressBarProps {
  value: number; // Value in percentage (0-100)
  barStyle?: React.CSSProperties;
  barColor?: string;
  backgroundColor?: string;
  height?: string;
  width?: string | number;
  stopOnComplete?: boolean;
  hideOnComplete?: boolean;
  onCompleted?: () => void;
  valueToFixed?: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  value,
  valueToFixed = 0,
  barStyle,
  barColor = "#007bff", // Default blue color
  backgroundColor = "var(--bg-secondary-color)",
  width,
  height,
  stopOnComplete,
  hideOnComplete,
  onCompleted,
}) => {
  const completedRef = useRef(false);

  useEffect(() => {
    if (!completedRef.current && value === 100) {
      completedRef.current = true;
      onCompleted && onCompleted();
    }
  }, [value, onCompleted]);

  if (hideOnComplete && value === 100) return null;

  return (
    <div className="progress-bar-container" style={{ backgroundColor, width, height }}>
      <div
        className="progress-bar"
        style={{
          width: `${completedRef.current && stopOnComplete ? 100 : value}%`,
          backgroundColor: barColor,
          ...barStyle,
        }}
      >
        {value > 0 && (
          <div className={`value-indicator `} style={{ lineHeight: height }}>{`${value.toFixed(valueToFixed)}%`}</div>
        )}
      </div>
    </div>
  );
};

export default ProgressBar;
