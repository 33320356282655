import { FC } from "react";
import { IconCustom } from "../types";

const FillCartIcon: FC<IconCustom> = ({ cssClass, onClick, size, hovered, color = "#5edf07" }) => {
  return (
    <svg
      className={cssClass}
      onClick={onClick}
      width={size}
      height={size}
      viewBox="0 0 100 80"
      xmlns="http://www.w3.org/2000/svg"
      fill={color}
    >
      <rect x="0" y="0" width="100" height="15" />

      <rect x="1" y="20" width="20" height="5" stroke={color} />
      <rect x="1" y="25" width="20" height="45" stroke={color} strokeWidth={1} fill="transparent" />
      <line x1="1" y1="30" x2="21" y2="30" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="35" x2="21" y2="35" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="40" x2="21" y2="40" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="45" x2="21" y2="45" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="50" x2="21" y2="50" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="55" x2="21" y2="55" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="60" x2="21" y2="60" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="65" x2="21" y2="65" stroke={color} strokeWidth={0.5} />
      <line x1="1" y1="70" x2="21" y2="70" stroke={color} strokeWidth={0.5} />
      <rect x="1" y="70" width="20" height="5" stroke={color} />

      <rect x="25" y="20" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="45" y="20" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="65" y="20" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="85" y="20" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />

      <rect x="25" y="40" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="45" y="40" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="65" y="40" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="85" y="40" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />

      <rect x="25" y="60" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="45" y="60" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="65" y="60" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
      <rect x="85" y="60" width="15" height="15" stroke={color} strokeWidth={1} fill="transparent" />
    </svg>
  );
};

export default FillCartIcon;
