import { ReactNode } from "react";
import "./InfoBox.css";
import { Language } from "../../../types/types";

interface IinfoBoxProps {
  content: string | ReactNode;
  type: "info" | "gray" | "warning" | "success" | "error";
  language: Language;
}

const InfoBox = ({ content, type = "info", language = "hebrew" }: IinfoBoxProps) => {
  return (
    <div className={`info-box info-box-${type} info-box-${language}`}>
      <p>{content}</p>
    </div>
  );
};

export default InfoBox;
