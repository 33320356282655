import BabyPharm from "./BabyPharm";
import Bakery from "./Bakery";
import CerealBreakfast from "./CerealBreakfast";
import Cleaning from "./Cleaning";
import Deli from "./Deli";
import DiaryAndEggs from "./DiaryAndEggs";
import DietHealth from "./DietHealth";
import FreshProduceIcon from "./FreshProduceIcon";
import Frozen from "./Frozen";
import Grocery from "./Grocery";
import House from "./House";
import MeatChickenFish from "./MeatChicknFish";
import Sweets from "./Sweets";
import Wine from "./Wine";

// https://www.svgrepo.com/

//todo Implements dynamic mapping due to departments can be changed
const DepartmentsIcons = {
  "Fresh Produce": FreshProduceIcon,
  "Dairy  & Eggs": DiaryAndEggs,
  Bakery: Bakery,
  "Meat, Chicken & Fish": MeatChickenFish,
  "Cereal & Breakfast": CerealBreakfast,
  Grocery: Grocery,
  "Deli & Salads": Deli,
  Frozen: Frozen,
  "Beverages & Wine": Wine,
  "Health & Specialty Diets": DietHealth,
  "Sweets & Snacks": Sweets,
  Cleaning: Cleaning,
  "Beauty & Baby": BabyPharm,
  "Houseware & Leisure": House,
};

export default DepartmentsIcons;
