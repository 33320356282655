import { useState, FC, memo, useCallback } from "react";
import { Language } from "../../../types/types";
import "./Departments.css";
import { Link } from "react-router-dom";
import DepartmentsIcons from "../../UI/Icons/DepartmentsIcons/DepartmentsIcons";
import CategoriesMap from "../../../assets/Data/categories";
import { IconCustom } from "../../UI/Icons/types";
import { directionByLanguage } from "../../../utils/utils";

type Props = {
  language: Language;
};

const DepartmentsNav: FC<Props> = ({ language }) => {
  const [hoveredIcon, setHoveredIcon] = useState("");
  const [selectedDep, setSelectedDep] = useState("");
  const [ddPosition, setddPosition] = useState({ width: 0, left: 0 });

  const handleMouseEnter = (e: any, departmentKey: string) => {
    const { left, width } = e.target.getBoundingClientRect();
    setddPosition({ width, left });
    setHoveredIcon(departmentKey);
  };

  const handleMouseLeave = () => {
    setHoveredIcon("");
  };

  const positionOffset = useCallback(
    (idx: number, categoryLength: number) => {
      const width = window.innerWidth;
      if (width < 500) return 100 - ddPosition.left;

      let offset = 0;

      if (idx === categoryLength - 1) offset = ddPosition.width / 2;
      else if (idx === 0) offset = -ddPosition.width / 2;

      if (directionByLanguage(language) === "ltr") return -offset;

      return offset;
    },
    [ddPosition, language]
  );

  const drawDepartments = useCallback(() => {
    const departmentsKeys = Object.keys(CategoriesMap);
    return departmentsKeys.map((d, idx) => {
      const Icon: FC<IconCustom> = DepartmentsIcons[d as keyof typeof DepartmentsIcons];
      return (
        <ul
          key={d}
          className={"nav-department-wrapper"}
          onMouseEnter={(e) => handleMouseEnter(e, d)}
          onMouseLeave={handleMouseLeave}
        >
          <li className="responsive-marginRL-10">
            {Icon ? <Icon hovered={hoveredIcon === d || selectedDep === d} /> : ""}
          </li>
          <li className={`responsive-hidden-lg ${selectedDep === d ? "primary-contrast-color" : ""}`}>
            {CategoriesMap[d].category[language]}
          </li>

          <ul
            className={`dd-categories-list`}
            style={{
              left: `calc( ${ddPosition.left + positionOffset(idx, departmentsKeys.length)}px )`,
              transform: `translateX(-25%)`,
            }}
          >
            {CategoriesMap[d].subCategories.map((cat: any) => {
              return (
                <Link key={cat.id} to={`/products/${cat.key}`}>
                  <li onClick={() => setSelectedDep(CategoriesMap[d].category.english)}>{cat[language]}</li>
                </Link>
              );
            })}
          </ul>
        </ul>
      );
    });
    //123 eslint-disable-next-line
  }, [ddPosition, selectedDep, hoveredIcon, positionOffset, language]);

  // TODO: in settings lets user decide if he want the navigation bar responsive-hidden
  return <nav className={`department_nav_container scrollable container responsive-hidden`}>{drawDepartments()}</nav>;
};

export default memo(DepartmentsNav);
