import { useRef } from "react";

function useDebounce(callback: (...args: any[]) => void, delay: number = 500) {
  const timeoutRef = useRef<NodeJS.Timeout | number | null>(null);

  const debounce = (...args: any[]) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      callback(...args);
    }, delay);
  };

  return debounce;
}

export default useDebounce;
