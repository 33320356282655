export const dictAuth = {
  fullName: {
    hebrew: "שם מלא",
    arabic: "",
    russian: "",
    english: "Full Name",
  },
  email: {
    hebrew: "אימייל",
    arabic: "",
    russian: "",
    english: "Email",
  },
  city: {
    hebrew: "יישוב",
    arabic: "",
    russian: "",
    english: "City",
  },
  phoneNumber: {
    hebrew: "פלאפון",
    arabic: "",
    russian: "",
    english: "Phone Number",
  },
  password: {
    hebrew: "סיסמא",
    arabic: "",
    russian: "",
    english: "Password",
  },
  confirmPassword: {
    hebrew: "אישור סיסמא",
    arabic: "",
    russian: "",
    english: "Confirm Password",
  },
  favoriteStore: {
    hebrew: "חנות מועדפת",
    arabic: "",
    russian: "",
    english: "Favorite Store",
  },
  otherStores: {
    hebrew: "חנויות בהן ארצה לערוך השוואה",
    arabic: "",
    russian: "",
    english: "Stores to compare with",
  },
  hasClubCard: {
    hebrew: "מחזיק בכרטיס אשראי מועדון?",
    arabic: "",
    russian: "",
    english: "Hold club card?",
  },
  login: { hebrew: "כניסה", arabic: "تسجيل الدخول", russian: "Вход", english: "Login" },
  register: { hebrew: "הרשמה", arabic: "", russian: "", english: "Register" },
  alreadyMember: { hebrew: "יש לי חשבון", arabic: "", russian: "", english: "Already have account" },
  forgetPassword: { hebrew: "שכחתי סיסמא", arabic: "", russian: "", english: "Forgot my password" },

  registerMeetingMessage: {
    hebrew: "ברוכים הבאים! נשמח להכיר :)",
    arabic: "",
    russian: "",
    english: "Welcome! Nice to meet you.",
  },
  loginMeetingMessage: {
    hebrew: "ברוכים השבים!",
    arabic: "مرحبًا مجددًا!",
    russian: "С возвращением!",
    english: "Welcome Back!.",
  },

  forgetPasswordMeetingMessage: {
    hebrew: "איפוס סיסמא",
    arabic: "إعادة تعيين كلمة المرور",
    russian: "Сброс пароля",
    english: "Password Reset.",
  },

  resetPasswordSubmitBtn: {
    hebrew: "עדכן סיסמא חדשה",
    arabic: "تحديث كلمة مرور جديدة",
    russian: "Обновить новый пароль",
    english: "Update New Password.",
  },
  resetPasswordErrorMessage: {
    hebrew: "המשתמש לא זוהה במערכת. יש לחזור על כל התהליך מהתחלה.",
    english: "The user is not identified in the system. Please start the process over from the beginning.",
    arabic: "المستخدم غير معرف في النظام. يرجى بدء العملية من البداية.",
    russian: "Пользователь не идентифицирован в системе. Пожалуйста, начните процесс заново с самого начала.",
  },

  passMeterWeak: {
    hebrew: "הסיסמה חייבת לכלול מינימום 6 תווים של אותיות קטנות וגדולות באנגלית ומספרים.",
    english:
      "The password must contain both lowercase and uppercase letters, as well as numbers, to enhance account security.",
    arabic: "يجب أن تحتوي كلمة المرور على أحرف صغيرة وأحرف كبيرة، وكذلك أرقام، لزيادة أمان الحساب.",
    russian:
      "Пароль должен включать как строчные, так и заглавные буквы, а также цифры, для усиления безопасности аккаунта.",
  },
  passwordNotMatch: {
    hebrew: "הסיסמאות לא תואמות",
    english: "Password does not match.",
    arabic: "كلمة المرور غير متطابقة.",
    russian: "Пароли не совпадают.",
  },
  registerPhoneInvalid: {
    hebrew: "מספר הטלפון אינו תקף",
    english: "Phone number is not valid.",
    arabic: "رقم الهاتف غير صالح.",
    russian: "Номер телефона недействителен.",
  },

  notAMember: {
    hebrew: "אין לי חשבון.",
    english: "Not a registered member.",
    arabic: "ليس عضوًا مسجلًا.",
    russian: "Не является зарегистрированным пользователем.",
  },
  forgotPassword: {
    hebrew: "שכחת סיסמה",
    english: "Forgot Password.",
    arabic: "نسيت كلمة المرور.",
    russian: "Забыли пароль.",
  },
  alreadyAMember: {
    hebrew: "כבר יש לי חשבון",
    english: "I have an account.",
    arabic: "لدي حساب بالفعل.",
    russian: "У меня уже есть аккаунт.",
  },
  sendResetPasswordEmail: {
    hebrew: "שלח מייל לאיפוס הסיסמה",
    english: "Send Reset Password Email.",
    arabic: "إرسال بريد إلكتروني لإعادة تعيين كلمة المرور.",
    russian: "Отправить письмо для сброса пароля.",
  },
};
