import { useState, FC, ReactNode, useEffect } from "react";
import "./Tab.css";

export type TabItems = {
  label: string | ReactNode;
  children: string | ReactNode;
  visible?: boolean;
}[];

type Props = {
  items: TabItems;
  currentTab?: number;
  footer?: ReactNode;
  title?: ReactNode;
  disableTab?: number | number[];
  height?: string;
};

const Tabs: FC<Props> = ({ items, currentTab, footer, title, disableTab, height }) => {
  const [activeTab, setActiveTab] = useState(currentTab || 0);

  useEffect(() => {
    if (currentTab) setActiveTab(currentTab);
  }, [currentTab]);

  const handleTabClick = (tabNumber: number) => {
    if (typeof disableTab === "number" && disableTab === tabNumber) {
      return null;
    } else if (typeof disableTab === "object" && disableTab.includes(tabNumber)) {
      return null;
    } else {
      setActiveTab(tabNumber);
    }
  };

  const renderTabs = () => {
    return items
      .filter((tab) => (tab.visible !== undefined ? tab.visible : tab))
      .map((tab, i) => {
        return (
          <div key={i} className={`tab ${activeTab === i ? "active" : ""}`} onClick={() => handleTabClick(i)}>
            {tab.label}
          </div>
        );
      });
  };

  return (
    <div className="dynamic-tabs" style={{ height }}>
      {title && title}
      <div className="tab-container">{renderTabs()}</div>
      <div className="tab-content"> {items[activeTab].children}</div>
      {footer && footer}
    </div>
  );
};

export default Tabs;
