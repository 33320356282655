import { FC, ReactNode, Children, isValidElement, cloneElement, CSSProperties } from "react";
import "./Description.css";

type PropsDescriptionItem = {
  label: string;
  content: string | number | JSX.Element;
  style?: CSSProperties;

  rowSize?: "full" | "complete";
  styleContent?: CSSProperties;
  styleLabel?: CSSProperties;
};

export const DescriptionItem: FC<PropsDescriptionItem> = ({
  label,
  content,
  style,

  rowSize,
  styleContent,
  styleLabel,
}) => {
  return (
    <div
      className={`description-item ${rowSize === "full" ? "full-row" : rowSize === "complete" ? "complete-row" : ""}`}
      style={style || undefined}
    >
      <div style={styleLabel || undefined} className="label">
        {label}
      </div>

      <div style={styleContent || undefined} className="content">
        {content}
      </div>
    </div>
  );
};

type PropsDescription = {
  children: ReactNode | ReactNode[];
  title?: string | ReactNode;
  widthItem?: string;
  style?: CSSProperties;
  itemStyle?: CSSProperties;
};

const Description: FC<PropsDescription> = ({ children, style, itemStyle, title }) => {
  const clonedChildren = Children.map(children, (child) => {
    if (isValidElement(child)) {
      return cloneElement(child, { style: itemStyle } as PropsDescriptionItem);
    }
    return child;
  });

  return (
    <div className="description-wrapper">
      {title && typeof title === "string" ? <h2 className="title">{title}</h2> : title}
      <div className="description" style={style || undefined}>
        {clonedChildren}
      </div>
    </div>
  );
};

export default Description;
