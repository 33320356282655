import { Dispatch, FC, useCallback } from "react";
import InputUI from "../../../UI/Input/Input";
import { dictAuth } from "../../dictionary";
import { EmailIcon, PasswordIcon, PhoneIcon, UserIcon } from "../../../UI/Icons";
import { Language } from "../../../../types/types";
import { ActionAuth, AuthenticationFormState } from "../../AuthReducer/AuthReducer";
import PasswordStrengthMeter, { calculateStrength } from "../PasswordStrengthMeter/PasswordStrengthMeter";
import { isValidIsraelPhoneNumber } from "../../../../utils/utils";
import { passwordStrengthValue } from "../../../../utils/constants";

type Props = {
  language: Language;
  dispatch: Dispatch<ActionAuth>;
  formState: AuthenticationFormState;
  source?: "auth" | "privacySettings";
};

const PersonalData: FC<Props> = ({ source = "auth", language, dispatch, formState }) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const inputInfoText = useCallback(
    (type: "phone" | "password" | "password2") => {
      const passwordStrength = calculateStrength(formState.password);

      if (type === "phone" && formState.phoneNumber && !isValidIsraelPhoneNumber(formState.phoneNumber))
        return dictAuth.registerPhoneInvalid[language];
      else if (type === "password") {
        if (formState.password.length < 2 || passwordStrength >= passwordStrengthValue)
          return <PasswordStrengthMeter password={formState.password} />;
        else {
          return (
            <div>
              <PasswordStrengthMeter password={formState.password} />
              <div>{dictAuth.passMeterWeak[language]}</div>
            </div>
          );
        }
      } else if (
        type === "password2" &&
        formState.confirmPassword.length > 2 &&
        formState.password !== formState.confirmPassword
      ) {
        return dictAuth.passwordNotMatch[language];
      }

      return "";
    },
    [formState, language]
  );

  return (
    <div className="register-container">
      <div className="form-group">
        <InputUI
          id="email"
          htmlFor="email"
          name="SET_EMAIL"
          required
          type="email"
          language={language}
          label={dictAuth.email[language]}
          value={formState.email}
          onChange={handleInputChange}
          icon={<EmailIcon size={"50%"} color="var(--primary-color)" />}
        />
      </div>
      <div className="form-group">
        <InputUI
          id="fullName"
          htmlFor="fullName"
          name="SET_FULL_NAME"
          required
          language={language}
          label={dictAuth.fullName[language]}
          value={formState.fullName}
          onChange={handleInputChange}
          icon={<UserIcon size={"50%"} color="var(--primary-color)" />}
        />
      </div>

      <div className="form-group">
        <InputUI
          id="phoneNumber"
          htmlFor="phoneNumber"
          name="SET_PHONE"
          type="number"
          language={language}
          label={dictAuth.phoneNumber[language]}
          value={formState.phoneNumber}
          onChange={handleInputChange}
          icon={<PhoneIcon size={"50%"} color="var(--primary-color)" />}
          textMessage={inputInfoText("phone")}
        />
      </div>

      {source === "auth" && (
        <div className="form-group">
          <InputUI
            id="password"
            name="SET_PASSWORD"
            htmlFor="password"
            required
            type="password"
            language={language}
            label={dictAuth.password[language]}
            value={formState.password}
            onChange={handleInputChange}
            icon={<PasswordIcon size={"55%"} color="var(--primary-color)" />}
            textMessage={inputInfoText("password")}
          />
        </div>
      )}
      {source === "auth" && (
        <div className="form-group">
          <InputUI
            id="passwordConfirm"
            name="SET_CONFIRM_PASSWORD"
            // htmlFor="password"
            required
            type="password"
            language={language}
            label={dictAuth.confirmPassword[language]}
            value={formState.confirmPassword}
            onChange={handleInputChange}
            icon={<PasswordIcon size={"55%"} color="var(--primary-color)" />}
            textMessage={inputInfoText("password2")}
          />
        </div>
      )}
    </div>
  );
};

export default PersonalData;
