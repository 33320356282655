import { useRouteError, isRouteErrorResponse, useLocation, useNavigate } from "react-router-dom";
import "./ErrorPage.css";
import NavBar from "../Navbar/NavBar";
import { Button } from "../UI";
import { ErrorIcon } from "../UI/Icons";
import { useCallback, useEffect, useState } from "react";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import Logo from "../Logo/Logo";
import { resolveDirClass } from "../../utils/utils";

const knownErrorsStatusDict = {
  "0": {
    type: "unknown",
    message: {
      hebrew: "אימייל נמצא כבר בשימוש.",
      english: "Sorry, an unexpected error has occurred",
      arabic: "عذرًا، حدث خطأ غير متوقع",
      russian: "Извините, произошла неожиданная ошибка",
    },
  },
  "1": {
    type: "registration-email exist",
    message: {
      hebrew: "אימייל נמצא כבר בשימוש.",
      english: "Email already exist",
      arabic: "البريد الإلكتروني موجود بالفعل",
      russian: "Адрес электронной почты уже существует",
    },
  },
  "2": {
    type: "registration-verification expired",
    message: {
      hebrew: "פג תוקפו של לינק זה , אנא נסו שנית.",
      english: "Link verification expired, Please try again.",
      arabic: "انتهت صلاحية التحقق من الرابط، يرجى المحاولة مرة أخرى.",
      russian: "Срок действия ссылки истек, повторите попытку.",
    },
  },
};

const dictGeneral = {
  description: {
    hebrew: "אנו מצטערים , הדף שחיפשת אינו קיים. אם לדעתך קיימת בעיה , נשמח לדעת עליה .",
    english: "Sorry, the page you're looking for doesn't exist. if you think something is broken, report a problem.",
    russian:
      "Извините, страница, которую вы ищете, не существует. Если вы считаете, что что-то не работает, сообщите о проблеме.",
    arabic: "عذرًا، الصفحة التي تبحث عنها غير موجودة. إذا كنت تعتقد أن هناك مشكلة، الرجاء الإبلاغ عنها.",
  },
  btnReport: {
    hebrew: "יצירת קשר",
    english: "Report Problem",
    russian: "Сообщить о проблеме",
    arabic: "الإبلاغ عن مشكلة",
  },
  btnHP: {
    hebrew: "חזרה לדף הבית",
    english: "Return Home",
    russian: "Вернуться на главную",
    arabic: "العودة إلى الصفحة الرئيسية",
  },
};

const ErrorPage: React.FC = () => {
  const {
    user: { language },
  } = useCustomContext();

  const location = useLocation();
  const navigate = useNavigate();
  const error = useRouteError();

  const [errorData, setErrorData] = useState({ status: "", message: "", knownError: "" });

  const errorHandler = useCallback(() => {
    const queryParams = new URLSearchParams(location.search);
    const knownErrorMessage = queryParams.get("message");
    let knownErrorStatus = queryParams.get("status");
    let errorMessage: string;

    if (knownErrorMessage) errorMessage = knownErrorMessage;
    else if (isRouteErrorResponse(error)) {
      // error is type `ErrorResponse`
      knownErrorStatus = error.status.toString();
      errorMessage = error.data || error.statusText;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    } else if (typeof error === "string") {
      errorMessage = error;
    } else {
      errorMessage = "0";
    }

    setErrorData({
      status: knownErrorStatus ?? "???",
      message: errorMessage,
      knownError: knownErrorMessage ?? "Unknown Error",
    });
    console.error(error);
  }, [error, location]);

  useEffect(() => {
    errorHandler();
  }, [errorHandler]);

  return (
    <>
      <NavBar language={language} />
      <div id="error-page" className={`gradient-bg `} style={{ direction: resolveDirClass(language) }}>
        <div className="error-content">
          <div className="error-logo">
            <Logo fontSize={"5rem"} animate />
          </div>
          <div className="error-status gradient-bg">{errorData.status || "???"}</div>
          {Object.keys(knownErrorsStatusDict).includes(errorData.message) ? (
            // @ts-ignore
            <div className="error-message">{knownErrorsStatusDict[errorMessage].message[language]}</div>
          ) : (
            <div className="error-message">{dictGeneral.description[language]}</div>
          )}
          <div className="error-message_original">
            <ErrorIcon size="1.4rem" />
            {errorData.message}
          </div>
          <div className="buttons-ep">
            <Button onClick={() => navigate("/")}>{dictGeneral.btnHP[language]}</Button>
            <Button onClick={() => navigate("/contact")} variant="secondary">
              {dictGeneral.btnReport[language]}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
